import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var OportunidadeService = /** @class */ (function () {
    function OportunidadeService() {
        this.selectedImovelSource = new BehaviorSubject(null);
        this.selectedImovel$ = this.selectedImovelSource.asObservable();
        this.analiseConfirmadaSource = new BehaviorSubject(null);
        this.analiseConfirmada$ = this.analiseConfirmadaSource.asObservable();
        this.intencaoCompraIdSource = new BehaviorSubject(null);
        this.intencaoCompraId$ = this.intencaoCompraIdSource.asObservable();
    }
    OportunidadeService.prototype.selectImovel = function (imovel) {
        this.selectedImovelSource.next(imovel);
    };
    OportunidadeService.prototype.setIntencaoCompra = function (intencaoCompraId) {
        this.intencaoCompraIdSource.next(intencaoCompraId);
    };
    OportunidadeService.prototype.changeAnaliseConfirmada = function (positivoOuNegativo) {
        this.analiseConfirmadaSource.next(positivoOuNegativo);
    };
    OportunidadeService.ngInjectableDef = i0.defineInjectable({ factory: function OportunidadeService_Factory() { return new OportunidadeService(); }, token: OportunidadeService, providedIn: "root" });
    return OportunidadeService;
}());
export { OportunidadeService };
