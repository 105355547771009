import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TipoPessoa } from 'app/views/_models';
import { Restangular } from 'ngx-restangular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import {
  Bairro,
  Contrato,
  Estado,
  Imovel,
  Oportunidade,
  Municipio,
  Response,
  Status,
  Arquivo,
} from '../../_models';

@Component({
  selector: 'app-visual-cliente',
  templateUrl: './visualizar-cliente.component.html',
  styleUrls: ['./visualizar-cliente.component.scss'],
})
export class VisualizarClienteComponent implements OnInit {
  clienteId: number;
  tipodocumento: [];
  tiposPessoa: TipoPessoa[] = [];
  contratos: Contrato[] = [];
  cliente;
  contrato;
  oportunidades;
  contratoSelecionado: Contrato;
  detalhesAssinatura: any;
  loading: boolean = true;
  status: string = '1';
  pesquisa: string = '';
  modalRef: BsModalRef;

  //localidade
  endereco: any = [];
  enderecoCompleto: any = [];

  //Documentos
  documentosbase64: any;
  numeroAdcDocumento: number;

  selectedFile?: File;
  fileName: string = 'Escolha o arquivo';
  enviandoContrato = false;
  enviandoParaAssinatura = [];

  constructor(
    private restangular: Restangular,
    private notifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private confimation: ConfirmationService,
    private modalService: BsModalService
  ) {}

  editarContrato(id: number) {
    this.router.navigate(['/update-contrato', id]);
  }

  novoContrato() {
    this.router.navigate(['/create-contrato']);
  }

  ngOnInit() {
    this.clienteId = this.route.snapshot.params['id'];
    this.getCliente();
  }

  getCliente() {
    this.restangular
      .one('cliente', this.clienteId)
      .get()
      .subscribe(
        (response) => {
          this.cliente = response.data;
          this.getContrato();
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os dados do cliente'
          );
        }
      );
  }

  getContrato() {
    this.restangular
      .one(`contrato/ListarPorCliente/${this.cliente.clienteId}`)
      .get()
      .subscribe(
        (response) => {
          if (response.data.length > 0) {
            this.contrato = response.data[0];
            this.getIntencaoCompra();
          }
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os dados do contrato'
          );
        }
      );
  }
  getIntencaoCompra() {
    this.restangular
      .one(`intencaoCompra/contrato/${this.contrato.contratoId}/detalhado`)
      .get()
      .subscribe(
        (response) => {
          this.oportunidades = response.data;
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os dados da inteção de compra'
          );
        }
      );
  }
  definirStatus(vigencia: boolean, cancelado: boolean) {
    if (cancelado) {
      return { descricao: 'Cancelado', classe: 'badge badge-danger' };
    } else if (!vigencia) {
      return { descricao: 'Inativo', classe: 'badge badge-danger' };
    } else {
      return { descricao: 'Ativo', classe: 'badge badge-success' };
    }
  }

  visualizarContrato(id: number) {
    this.restangular
      .one('contrato/gerar/', id)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .subscribe((fileBlob: Blob) => {
        const file = new Blob([fileBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
    } else {
      this.fileName = 'Escolha o arquivo';
    }
  }
  cancelarContrato(id: number) {
    this.confimation
      .create('Atenção', 'Deseja realmente cancelar esse contrato?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restangular
            .one('contrato/cancelar/', id)
            .customPUT()
            .subscribe(
              () => {
                this.notifierService.notify(
                  'success',
                  'Contrato cancelado com sucesso'
                );
                this.getContrato();
              },
              () => {
                this.notifierService.notify(
                  'error',
                  'Erro ao cancelar contrato'
                );
              }
            );
        }
      });
  }

  onUpload() {
    if (this.selectedFile) {
      this.enviandoContrato = true;

      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        const arquivo = {
          ArquivoId: null,
          Nome: this.selectedFile.name,
          Tipo: this.selectedFile.type,
          Tamanho: this.selectedFile.size,
          Base64: base64,
          Url: null,
        };
        this.restangular
          .all('contrato/uploadContratoAssinado')
          .post({
            arquivo,
            contratoId: this.contratoSelecionado.contratoId,
          })
          .subscribe(
            (response) => {
              this.notifierService.notify(
                'success',
                'upload realizado com sucesso'
              );
              this.getContrato();
              this.modalRef.hide();
              this.enviandoContrato = false;
            },
            (error) => {
              this.notifierService.notify(
                'error',
                'erro ao fazer upload do arquivo'
              );
              this.enviandoContrato = false;
            }
          );
      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      console.error('No file selected');
    }
  }

  verStatusAssinatura(template) {
    this.restangular
      .one(`contrato/${this.contrato.contratoId}`)
      .get()
      .subscribe((res: Response<any>) => {
        this.detalhesAssinatura = res.data;
        this.modalRef = this.modalService.show(template, { class: 'modal-md' });
      });
  }

  downloadContrato(arquivo: any) {
    this.restangular
      .one('arquivo', arquivo.arquivoId)
      .one('download')
      .get()
      .subscribe(
        (res) => {
          window.open(res.data.urlArquivo, '_blank');
        },
        (err) => {
          console.error('Erro ao baixar o arquivo', err);
        }
      );
  }

  getStatusClass() {
    switch (this.detalhesAssinatura.statusAssinatura) {
      case 0:
        return 'text-warning'; // Aguardando
      case 1:
        return 'text-success'; // Finalizado
      case 2:
        return 'text-danger'; // Cancelado
      default:
        return '';
    }
  }

  verificarEnvio(contratoId) {
    return this.enviandoParaAssinatura.some((x) => x == contratoId);
  }

  enviarAssinatura(contratoId: number) {
    this.confimation
      .create(
        'Atenção',
        'Deseja realmente enviar esse contrato para assinatura?'
      )
      .subscribe((ans) => {
        if (ans.resolved) {
          this.enviandoParaAssinatura.push(contratoId);
          this.restangular
            .all('contrato/enviarAssinatura')
            .post({ contratoId })
            .subscribe(
              (res) => {
                this.notifierService.notify(
                  'success',
                  'Enviado para assinatura com sucesso!'
                );
                this.enviandoParaAssinatura.splice(
                  this.enviandoParaAssinatura.indexOf(contratoId),
                  1
                );
                this.getContrato();
              },
              (err) => {
                this.enviandoParaAssinatura.splice(
                  this.enviandoParaAssinatura.indexOf(contratoId),
                  1
                );
                this.notifierService.notify(
                  'error',
                  'Erro ao enviar para assinatura'
                );
              }
            );
        }
      });
  }
  openModal(template, contrato: Contrato) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.contratoSelecionado = contrato;
  }
}
