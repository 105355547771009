<div class="card animated fadeIn">
  <div class="card-header">
    <p class="card-title d-inline-block">
      <i class="fas fa-check-double"></i> &nbsp; Checklist - Análise Jurídica
    </p>
    <button class="btn btn-success float-right" (click)="novoChecklistCampo()">
      <i class="fa fa-plus-circle"></i> Novo Campo
    </button>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table
        class="table table-striped"
        [mfData]="checklistCampos"
        [mfRowsOnPage]="10"
        #mfTable="mfDataTable"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="checkListCampoId">Id</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="label">Nome</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="tipo">Tipo</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="ordem">Ordem</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td class="text-center text-muted" colspan="7">
              <h5><i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...</h5>
            </td>
          </tr>
          <tr *ngIf="!loading && checklistCampos.length === 0">
            <td class="text-center text-muted" colspan="7">
              <h5>
                <i class="fa fa-spin fa-spinner"></i>&nbsp;Nenhum registro
                encontrado.
              </h5>
            </td>
          </tr>
          <tr *ngFor="let checklistCampo of mfTable.data">
            <td>{{ checklistCampo.checklistCampoId }}</td>
            <td>{{ checklistCampo.label }}</td>
            <td>{{ checklistCampo.tipo }}</td>
            <td>{{ checklistCampo.ordem }}</td>
            <td class="text-center">
              <a
                (click)="editarChecklistCampo(checklistCampo.checklistCampoId)"
                tooltip="Editar"
                class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                (click)="excluirChecklistCampo(checklistCampo.checklistCampoId)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
