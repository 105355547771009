<div *ngIf="contato">
  <div class="card animated fadeIn">
    <div class="card-header"><i class="fa fa-user"></i> Dados do Contato</div>
    <div class="card-block">
      <div class="row">
        <div class="col-3">
          <label><b>Nome</b></label>
          <p>{{ contato.nome }}</p>
        </div>
        <div class="col-3">
          <label><b>E-mail</b></label>
          <p>{{ contato.email }}</p>
        </div>
        <div class="col-3">
          <label><b>Celular</b></label>
          <p>{{ contato.telefone }}</p>
        </div>
        <div class="col-3">
          <label><b>Data do Cadastro</b></label>
          <p>
            {{
              contato.dataCadastro
                | amUtc
                | amLocal
                | amDateFormat : "DD/MM/yyyy"
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <label><b>Temperatura</b></label>
          &nbsp;
          <p [innerHTML]="obterIconeLead(contato.temperatura)"></p>
        </div>
        <div class="col-3">
          <label><b>Preferência ao Contactar</b></label>
          <p>{{ contato.comoContactar }}</p>
        </div>
        <div class="col-3">
          <label><b>Origem do Contato</b></label>
          <p>{{ contato.origem.descricao }}</p>
        </div>
      </div>
      <div class="row" *ngIf="contato.endereco">
        <div class="col-12">
          <label><b>Endereço</b></label>
          <p>{{ contato.endereco.enderecoCompleto }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fas fa-funnel-dollar"></i> Faixa de Valor
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-6 text-center">
          <label><b>Valor Inicial</b></label>
          <p>
            {{
              contato.valorInicial | currency : "BRL" : "symbol" : "1.2-2" || 0
            }}
          </p>
        </div>
        <div class="col-6 text-center">
          <label><b>Valor Final</b></label>
          <p>
            {{
              contato.valorFinal | currency : "BRL" : "symbol" : "1.2-2" || 0
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fas fa-map-marker-alt"></i> Localidades de Interesse
    </div>
    <div class="card-block">
      <table class="table" *ngIf="contato.localidadesInteresse.length > 0">
        <thead>
          <tr>
            <th>Estado</th>
            <th>Cidade</th>
            <th>Bairro</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let item of contato.localidadesInteresse; let i = index"
          >
            <tr>
              <td>
                {{ item.bairro.nome }}
              </td>
              <td>
                {{ item.bairro.municipio.nome }}
              </td>
              <td>
                {{ item.bairro.municipio.estado.nome }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <h5
        *ngIf="contato.localidadesInteresse.length == 0"
        class="text-center text-danger mt-3 mb-3"
      >
        <i class="fa fa-exclamation-triangle"></i> Nenhuma localidade
        cadastrada!
      </h5>
    </div>
    <div class="card-footer">
      <button type="reset" class="btn btn-danger" [routerLink]="['/contato']">
        <i class="fa fa-undo"></i> Voltar
      </button>
    </div>
  </div>
</div>
