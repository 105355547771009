import { BrowserModule } from '@angular/platform-browser';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LocationStrategy,
  HashLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import localePt from '@angular/common/locales/pt';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { LOCALE_ID } from '@angular/core';
import { EmailEditorModule } from 'angular-email-editor';

registerLocaleData(localePt);
// Import containers
import { FullLayoutComponent } from './containers';

const APP_CONTAINERS = [FullLayoutComponent];

// Import components
import {
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
} from './components';

// Import views
import {
  LoginComponent,
  AccessDeniedComponent,
  UsuariosComponent,
  UpdateUsuariosComponent,
  CreateUsuariosComponent,
  HomeComponent,
  PerfilComponent,
  ImovelComponent,
  CreateImovelComponent,
  UpdateImovelComponent,
  CampoComponent,
  InteresseComponent,
  PregaoComponent,
  ModalidadeComponent,
  InstituicaoComponent,
  ClienteComponent,
  CreateClienteComponent,
  EditClienteComponent,
  TipoComponent,
  CorretorComponent,
  CreateCorretorComponent,
  UpdateCorretorComponent,
  ContratoComponent,
  CreateContratoComponent,
  UpdateContratoComponent,
  ContatoComponent,
  UpdateContatoComponent,
  AnaliseLeilaoComponent,
  EditAnaliseLeilaoComponent,
  BairroComponent,
  CreateBairroComponent,
  EditBairroComponent,
  OportunidadeComponent,
  OportunidadeAnaliseJuridicaComponent,
  OportunidadeEventosComponent,
  OportunidadeImovelComponent,
  ChecklistCampoComponent,
  CreateCheckListCampoComponent,
  UpdateChecklistCampoComponent,
  StatusIntencaoCompraComponent,
  CreateStatusIntencaoCompraComponent,
  UpdateStatusIntencaoCompraComponent,
  StatusIntencaoCompraGrupoComponent,
  CreateContatoComponent,
} from './views';

const APP_COMPONENTS = [
  APP_SIDEBAR_NAV,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  LoginComponent,
  HomeComponent,
  AccessDeniedComponent,
  UsuariosComponent,
  UpdateUsuariosComponent,
  CreateUsuariosComponent,
  PerfilComponent,
  ImovelComponent,
  CreateImovelComponent,
  UpdateImovelComponent,
  CampoComponent,
  InteresseComponent,
  PregaoComponent,
  ModalidadeComponent,
  InstituicaoComponent,
  ClienteComponent,
  CreateClienteComponent,
  EditClienteComponent,
  TipoComponent,
  CorretorComponent,
  CreateCorretorComponent,
  UpdateCorretorComponent,
  ContratoComponent,
  CreateContratoComponent,
  UpdateContratoComponent,
  ContatoComponent,
  UpdateContatoComponent,
  AnaliseLeilaoComponent,
  EditAnaliseLeilaoComponent,
  BairroComponent,
  CreateBairroComponent,
  EditBairroComponent,
  OportunidadeComponent,
  OportunidadeAnaliseJuridicaComponent,
  OportunidadeEventosComponent,
  OportunidadeImovelComponent,
  ChecklistCampoComponent,
  CreateCheckListCampoComponent,
  UpdateChecklistCampoComponent,
  StatusIntencaoCompraComponent,
  CreateStatusIntencaoCompraComponent,
  UpdateStatusIntencaoCompraComponent,
  StatusIntencaoCompraGrupoComponent,
  CreateContatoComponent,
  DocumentoComponent,
];

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
} from './directives';

import { DataFilterPipe } from './directives/data-filter.pipe';
import { DateValidPipe } from './directives/datevalid.pipe';
import { GroupByPipe2 } from './directives/groupBy2.pipe';
import { SumByPipe } from './directives/sumBy.pipe';
import { RandomColorDirective } from './directives/random-color-directive';
import { NotLink } from './directives/not-link-directive';
import { DateMaskDirective } from './directives/datemask.directive';
import { FormatCpfCnpjPipe } from './directives/format-cpfcnpj.pipe';
import { FormatPhonePipe } from './directives/format-phone.pipe';
import { KeyEnterDirective } from './directives/keyenter.directive';
import { AppFocusDirective } from './directives/appFocus.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { CurrencyFormatPipe } from './directives/currency-format.pipe';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
  DataFilterPipe,
  DateValidPipe,
  GroupByPipe2,
  RandomColorDirective,
  NotLink,
  FormatCpfCnpjPipe,
  FormatPhonePipe,
  SumByPipe,
  GroupByPipe2,
  KeyEnterDirective,
  AppFocusDirective,
  DisableControlDirective,
  CurrencyFormatPipe,
];

// Import routing module
import { AppRoutingModule } from './app.routing';
import { Router } from '@angular/router';

// Import 3rd party components
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChartsModule } from 'ng2-charts';
import { CustomFormsModule } from 'ng2-validation';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { Restangular, RestangularModule } from 'ngx-restangular';
import { TextMaskModule } from 'angular2-text-mask';
import { NgPipesModule } from 'ngx-pipes';
import {
  JasperoConfirmationsModule,
  ConfirmationService,
} from '@jaspero/ng-confirmations';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MomentModule } from 'ngx-moment';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { LoadingModule } from 'ngx-loading';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap/alert';

import { environment } from '../environments/environment';
import { AuthGuard, AnonyGuard } from './_guards/index';
import { AuthenticationService } from './_services/index';
import { PdfService } from './_services/pdf.service';
import { DataTableModule } from 'angular2-datatable';
import { SourceGuard } from './_guards/source.guard';
import { AuthorizationService } from './_services/authorization.service';
import { SaveCancelButtonsComponent } from './components/form/save-cancel-buttons/save-cancel-buttons.component';
import { BackButtonComponent } from './components/form/back-button/back-button.component';
import { SelectInsertComponent } from './components/form/select-insert/select-insert.component';
import { LimitToPipe } from './directives/limitTo.pipe';
import jwt_decode from 'jwt-decode';
import { OrigemComponent } from './views/origem/origem.component';
import { DocumentoComponent } from './views/documento/documento.component';
import { VisualizarClienteComponent } from './views/cliente/visualizar-cliente/visualizar-cliente.component';
import { VisualizarContatoComponent } from './views/contato/visualizar-contato/visualizar-contato.component';
import { TemplateComponent } from './views/template/template.component';
import { CreateTemplateComponent } from './views/template/create-template/create-template.component';
import { UpdateTemplateComponent } from './views/template/update-template/update-template.component';
import { CampanhaComponent } from './views/campanha/campanha.component';
import { CreateCampanhaComponent } from './views/campanha/create-campanha/create-campanha.component';
import { UpdateCampanhaComponent } from './views/campanha/update-campanha/update-campanha.component';
import { LogsComponent } from './views/campanha/logs/logs.component';
import { RelatoriosImovelComponent } from './views/relatorios-imovel/relatorios-imovel.component';

export function RestangularConfigFactory(
  RestangularProvider,
  NotifierService: NotifierService
) {
  RestangularProvider.setBaseUrl(environment.api);

  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const decodedToken: any = jwt_decode(currentUser.token);
  if (Date.now() >= decodedToken.exp * 1000) {
    localStorage.removeItem('currentUser');
    window.location.href = '/#/login';
    return false;
  }

  if (currentUser && currentUser.token) {
    RestangularProvider.setDefaultHeaders({
      Authorization: 'Bearer ' + currentUser.token,
      withCredentials: true,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    });
  }

  RestangularProvider.addErrorInterceptor(
    (response, subject, responseHandler) => {
      if (response.status === 401) {
        window.location.href = '/#/login';
        return false;
      }

      if (response.status === 403) {
        NotifierService.notify('error', 'Acesso Negado');
        return false;
      }

      return true;
    }
  );
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    EmailEditorModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10,
        },
      },
    }),
    RestangularModule.forRoot([NotifierService], RestangularConfigFactory),
    TextMaskModule,
    NgPipesModule,
    JasperoConfirmationsModule,
    NgxMyDatePickerModule.forRoot(),
    MomentModule,
    NguiAutoCompleteModule,
    BrowserAnimationsModule,
    LoadingModule,
    ModalModule.forRoot(),
    CurrencyMaskModule,
    BsDatepickerModule.forRoot(),
    SortableModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
    DataTableModule,
    CommonModule,
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    HttpClientModule,
    AlertModule.forRoot(),
    AngularEditorModule,
    PaginationModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    SaveCancelButtonsComponent,
    BackButtonComponent,
    SelectInsertComponent,
    LimitToPipe,
    OrigemComponent,
    DocumentoComponent,
    VisualizarClienteComponent,
    VisualizarContatoComponent,
    TemplateComponent,
    CreateTemplateComponent,
    UpdateTemplateComponent,
    CampanhaComponent,
    CreateCampanhaComponent,
    UpdateCampanhaComponent,
    LogsComponent,
    RelatoriosImovelComponent,
    DateMaskDirective,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    AuthGuard,
    AnonyGuard,
    SourceGuard,
    AuthenticationService,
    AuthorizationService,
    ConfirmationService,
    FormatPhonePipe,
    CurrencyFormatPipe,
    PdfService,
    GroupByPipe2,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
  exports: [LimitToPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
