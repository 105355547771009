import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Perfil } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  perfis: Perfil[] = [];
  backupPerfis: Perfil[] = [];
  loading = false;

  newPerfil: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getPerfil();
  }

  getPerfil() {
    this.loading = true;
    this.restAngular
      .one('perfil')
      .get()
      .subscribe(
        (response: Response<Perfil[]>) => {
          this.perfis = response.data;
          this.backupPerfis = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os perfis');
          this.loading = false;
        }
      );
  }

  delete(perfilId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o perfil?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('perfil', perfilId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Perfil excluído com sucesso');
                this.getPerfil();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o perfil'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Perfil): boolean {
    return (
      item.descricao !==
        this.backupPerfis.find((x) => x.perfilId == item.perfilId).descricao &&
      item.descricao !== ''
    );
  }

  saveNewPerfil() {
    this.restAngular
      .all('perfil')
      .post({ descricao: this.newPerfil })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'Perfil cadastrado com sucesso');
          this.getPerfil();
          this.newPerfil = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o perfil');
        }
      );
  }

  editItem(item: Perfil) {
    this.restAngular
      .one('perfil')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'Perfil editado com sucesso');
          this.getPerfil();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o perfil');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
