import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Campo } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-campo',
  templateUrl: './campo.component.html',
  styleUrls: ['./campo.component.scss'],
})
export class CampoComponent implements OnInit {
  campos: Campo[] = [];
  backupcampos: Campo[] = [];
  loading = false;

  newCampo: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getCampo();
  }

  getCampo() {
    this.loading = true;
    this.restAngular
      .one('campo')
      .get()
      .subscribe(
        (response: Response<Campo[]>) => {
          this.campos = response.data;
          this.backupcampos = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os campos');
          this.loading = false;
        }
      );
  }

  delete(campoId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o campo?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('campo', campoId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'campo excluído com sucesso');
                this.getCampo();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o campo'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Campo): boolean {
    return (
      item.descricao !==
        this.backupcampos.find((x) => x.campoId == item.campoId).descricao &&
      item.descricao !== ''
    );
  }

  saveNewCampo() {
    this.restAngular
      .all('campo')
      .post({ descricao: this.newCampo })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'campo cadastrado com sucesso');
          this.getCampo();
          this.newCampo = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o campo');
        }
      );
  }

  editItem(item: Campo) {
    this.restAngular
      .one('campo')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'campo editado com sucesso');
          this.getCampo();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o campo');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
