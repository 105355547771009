import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Restangular } from 'ngx-restangular';
import { Subscription } from 'rxjs';
import { Response } from '../_models';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
})
export class UsuariosComponent implements OnInit {
  usuarios: any;
  loading = true;
  modalRef: BsModalRef;
  sub: Subscription[] = [];

  constructor(
    private restAngular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getUsuario();
  }

  getUsuario() {
    this.restAngular
      .one('usuario')
      .get()
      .subscribe(
        (response) => {
          this.usuarios = response.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }

  edit(id) {
    this.router.navigate(['/update-usuarios', id], { relativeTo: this.route });
  }

  delete(usuarioId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o Usuário?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('usuario', usuarioId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify(
                  'success',
                  'Estabelecimento excluído com sucesso'
                );
                this.getUsuario();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o tipo'
                );
              }
            );
        }
      });
  }
}
