import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-Status',
  templateUrl: './status-intencao-compra.component.html',
  styleUrls: ['./status-intencao-compra.component.scss'],
})
export class StatusIntencaoCompraComponent implements OnInit {
  status: any[] = [];
  loading: boolean = true;

  constructor(
    private restAngular: Restangular,
    private norifier: NotifierService,
    private router: Router,
    private confimation: ConfirmationService
  ) {}

  ngOnInit() {
    this.carregarStatus();
  }

  editarStatus(id: number) {
    this.router.navigate(['/update-evento', id]);
  }

  novoStatus() {
    this.router.navigate(['/create-evento']);
  }

  excluirStatus(id: number) {
    this.confimation
      .create('Atenção', 'Deseja realmente excluir o evento?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('statusIntencaoCompra', id)
            .remove()
            .subscribe(
              () => {
                this.norifier.notify('success', 'Evento excluído com sucesso');
                this.carregarStatus();
              },
              () => {
                this.norifier.notify('error', 'Erro ao excluir evento');
              }
            );
        }
      });
  }

  carregarStatus() {
    this.loading = true;
    this.restAngular
      .one('statusIntencaoCompra')
      .get()
      .subscribe(
        (status) => {
          this.status = status.data;
          this.loading = false;
        },
        () => {
          this.norifier.notify('error', 'Erro ao carregar evento');
          this.loading = false;
        }
      );
  }
}
