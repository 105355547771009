<div class="card animated fadeIn">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <span>
        <i class="fas fa-shopping-cart" aria-hidden="true"></i>
        Oportunidade
      </span>
      <div class="intencao-contrato-detalhes" *ngIf="contrato">
        <span>
          <b>CONTRATO:</b>
          {{ contrato.contratoId }}
        </span>
        <span>
          <b>CLIENTE:</b>
          {{ contrato.cliente.nome }}
        </span>
        <span [ngClass]="contrato.status.classe">
          {{ contrato.status.descricao }}
        </span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <tabset>
      <tab>
        <ng-template tabHeading>
          <div class="font-weight-bold text-muted small">
            <i class="fas fa-building"></i>
            &nbsp; Imóvel
          </div>
        </ng-template>
        <app-oportunidade-imovel></app-oportunidade-imovel>
      </tab>
      <tab [disabled]="!intencaoCompraId">
        <ng-template tabHeading>
          <div class="font-weight-bold text-muted small">
            <i class="fas fa-gavel"></i>
            &nbsp; Análise Juridica
          </div>
        </ng-template>
        <app-oportunidade-analise-juridica
          *ngIf="selectedImovel"
        ></app-oportunidade-analise-juridica>
      </tab>
      <!-- <tab [disabled]="!intencaoCompraId">
        <ng-template tabHeading>
          <div class="font-weight-bold text-muted small">
            <i class="fas fa-gavel"></i>
            &nbsp; Análise Financeira
          </div>
        </ng-template>
        <div></div>
      </tab> -->
      <tab [disabled]="!intencaoCompraId">
        <ng-template tabHeading>
          <div class="font-weight-bold text-muted small">
            <i class="fas fa-circle"></i>
            &nbsp; Eventos
          </div>
        </ng-template>
        <app-oportunidade-eventos
          [intencaoId]="intencaoCompraId"
        ></app-oportunidade-eventos>
      </tab>
    </tabset>
  </div>

  <div class="card-footer">
    <button (click)="goBack()" class="btn btn-primary">
      <i class="fas fa-angle-left"></i>
      &nbsp; Voltar
    </button>
  </div>
</div>
