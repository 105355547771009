<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header header">
          <div *ngIf="campanhaInfo">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            &nbsp;Logs da Campanha -
            {{ campanhaInfo.titulo }}
          </div>
          <div class="d-flex mt-1">
            <!-- <button
              type="button"
              (click)="exportAsPDF()"
              class="btn btn-danger ml-3 btn-sm"
              title="Exportar para PDF"
            >
              <i class="fas fa-file-pdf"></i>&nbsp;PDF
            </button> -->
            <button
              *ngIf="!exportando"
              type="button"
              (click)="exportAsExcel()"
              class="btn btn-success btn-sm float-right"
              title="Exportar para Excel"
            >
              <i class="fas fa-file-excel"></i>&nbsp;Exportar
            </button>
            <button
              *ngIf="exportando"
              type="button"
              class="btn btn-success btn-sm"
              title="Exportando para Excel"
            >
              <i class="fa fa-spin fa-spinner"></i>&nbsp;Exportando...
            </button>
          </div>
        </div>
        <div class="card-block">
          <div class="row justify-content-center mb-5" *ngIf="resumo">
            <div class="col-2 text-center">
              <div class="text-muted">
                <i class="fa fa-paper-plane"></i> Emails Enviados
              </div>
              <div class="mt-2">
                <h5>
                  <strong>{{ resumo.total | number : "1.0" : "pt-BR" }}</strong>
                </h5>
              </div>
              <div class="progress mt-2" style="height: 4px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col-2 text-center">
              <div class="text-muted">
                <i class="fas fa-envelope"></i> Entregues
              </div>
              <div class="mt-2">
                <h5>
                  <strong
                    >{{ resumo.entregues | number : "1.0" : "pt-BR" }} ({{
                      resumo.percEntregues
                    }})</strong
                  >
                </h5>
              </div>
              <div class="progress mt-2" style="height: 4px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  [style]="getWidthProgressBar(resumo.percEntregues)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col-2 text-center">
              <div class="text-muted">
                <i class="fas fa-envelope-open"></i> Abertos
              </div>
              <div class="mt-2">
                <h5>
                  <strong
                    >{{ resumo.abertos | number : "1.0" : "pt-BR" }} ({{
                      resumo.percAbertos
                    }})</strong
                  >
                </h5>
              </div>
              <div class="progress mt-2" style="height: 4px">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  [style]="getWidthProgressBar(resumo.percAbertos)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col-2 text-center">
              <div class="text-muted">
                <i class="fas fa-mouse"></i> Clicados
              </div>
              <div class="mt-2">
                <h5>
                  <strong
                    >{{ resumo.clicados | number : "1.0" : "pt-BR" }} ({{
                      resumo.percClicados
                    }})</strong
                  >
                </h5>
              </div>
              <div class="progress mt-2" style="height: 4px">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  [style]="getWidthProgressBar(resumo.percClicados)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col-2 text-center">
              <div class="text-muted">
                <i class="fas fa-times-circle"></i> Com Erro
              </div>
              <div class="mt-2">
                <h5>
                  <strong
                    >{{ resumo.erros | number : "1.0" : "pt-BR" }} ({{
                      resumo.percErros
                    }})</strong
                  >
                </h5>
              </div>
              <div class="progress mt-2" style="height: 4px">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  [style]="getWidthProgressBar(resumo.percErros)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <!-- <div class="col">
              <div class="text-muted">Enviados</div>
              <div>
                <strong>{{ totalLogs | number : "1.0" }}</strong>
              </div>
            </div> -->
          </div>
          <form [formGroup]="filtro">
            <div class="row justify-content-end">
              <div class="input-group col-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Período</span
                  >
                </div>
                <input
                  class="form-control"
                  #drp="bsDaterangepicker"
                  bsDaterangepicker
                  placeholder="DD/MM/YYYY  - DD/MM/YYYY"
                  (bsValueChange)="onValueChange($event, 'data')"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                />
              </div>
              <div class="input-group col-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Destinatário</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  (keyup)="onSearch()"
                  placeholder="Email "
                  aria-describedby="basic-addon2"
                  formControlName="email"
                />
              </div>
              <div class="input-group col-2">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Aberto</span
                  >
                </div>
                <select
                  name="aberto"
                  class="form-control"
                  formControlName="aberto"
                  (change)="getLogs()"
                >
                  <option value="">TODOS</option>
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
              </div>
              <div class="input-group col-2">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Clicado</span
                  >
                </div>
                <select
                  name="clicado"
                  class="form-control"
                  formControlName="clicado"
                  (change)="getLogs()"
                >
                  <option value="">TODOS</option>
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
              </div>
              <div class="input-group col-2">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Com Erro</span
                  >
                </div>
                <select
                  name="erro"
                  class="form-control"
                  formControlName="comErro"
                  (change)="getLogs()"
                >
                  <option value="">TODOS</option>
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
              </div>
            </div>
          </form>
          <div class="table-responsive mt-4">
            <table
              class="table table-striped"
              [mfData]="tableLog"
              #mfLogs="mfDataTable"
              [mfRowsOnPage]="10"
            >
              <thead>
                <tr>
                  <th width="40%">Email</th>
                  <th class="text-center">Data Envio</th>
                  <th class="text-center">Entregue</th>
                  <th class="text-center">Aberto</th>
                  <th class="text-center">Clicado</th>
                  <th class="text-center">Erro</th>
                  <th class="text-center">Mensagem</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mfLogs.data">
                  <td>{{ item.destinatario }}</td>
                  <td class="text-center">
                    {{
                      item.dataEnvio
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/yyyy HH:mm"
                    }}
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-check-circle text-success"
                      *ngIf="item.entregue"
                    ></i>
                    <i
                      class="fa fa-times-circle text-danger"
                      *ngIf="item.entregue == false"
                    ></i>
                  </td>
                  <td class="text-center">
                    <span
                      tooltip="{{
                        item.dataAbertura
                          ? 'Aberto em: ' +
                            (item.dataAbertura
                              | amUtc
                              | amLocal
                              | amDateFormat : 'DD/MM/yyyy HH:mm')
                          : 'Não Aberto'
                      }} "
                    >
                      <i
                        class="fa fa-check-circle text-success"
                        *ngIf="item.aberto == true"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger"
                        *ngIf="item.aberto == false"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      tooltip="{{
                        item.dataClicado
                          ? 'Clicado em: ' +
                            (item.dataAbertura
                              | amUtc
                              | amLocal
                              | amDateFormat : 'DD/MM/yyyy HH:mm')
                          : 'Não Clicado'
                      }} "
                    >
                      <i
                        class="fa fa-check-circle text-success"
                        *ngIf="item.clicado == true"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger"
                        *ngIf="item.clicado == false"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span tooltip="{{ item.mensagemErro }}">
                      <i
                        class="fa fa-check-circle text-success"
                        *ngIf="item.erro == true"
                      ></i>
                      <i
                        class="fa fa-times-circle text-danger"
                        *ngIf="item.erro == false"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center" nowrap>
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="openModal(template, item.menssagem)"
                    >
                      <i class="fa fa-envelope"></i>&nbsp;
                    </button>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td class="text-center text-muted" colspan="7">
                    <h5>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <pagination
                      [totalItems]="totalLogs"
                      [(ngModel)]="page"
                      [itemsPerPage]="10"
                      (pageChanged)="loadLogs($event)"
                      [maxSize]="5"
                      nextText="Próximo"
                      previousText="Anterior"
                    >
                    </pagination>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <app-back-button [route]="'/campanha'"></app-back-button>
      </div>
    </div>
  </div>
</div>
<ng-template #template class="d-flex flex-column">
  <div class="modal-header justify-content-between col-12">
    <h4 class="modal-title pull-left">Mensagem</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <iframe id="iframe" frameborder="0" width="100%" height="800px"></iframe>
</ng-template>
