import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Model from '../../views/_models/model';
import { AuthenticationService } from '../../_services/index';
import { Router } from '@angular/router';
import { GroupByPipe } from 'ngx-pipes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  providers: [GroupByPipe],
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  //Header Variables
  user: Model.User;
  disabled = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getUser();
  }

  ngOnDestroy() {}

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
