<div class="card animated fadeIn">
  <div class="card-header">
    <div class="d-inline">
      <i class="fa fa-user" aria-hidden="true"></i>
      Clientes
    </div>
    <button
      class="btn btn-success float-right"
      [routerLink]="['/create-cliente']"
      routerLinkActive="router-link-active"
    >
      <i class="fa fa-plus-circle"></i>&nbsp;Novo Cliente
    </button>
  </div>
  <div class="card-body">
    <div class="row mb-2 justify-content-start text-muted">
      <div class="col-6">Encontrados {{ clientes.length }} Clientes</div>
      <div class="col-6 text-right">
        <button
          style="position: relative"
          class="btn btn-primary float-right mr-2"
          (click)="openModal(abrirFiltros)"
        >
          <i class="fas fa-filter"></i> Filtros
          <span
            *ngIf="filtrosAplicados > 0"
            class="badge badge-pill badge-danger numFiltros"
            >{{ filtrosAplicados }}</span
          >
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table"
        [mfData]="clientes"
        #mfCliente="mfDataTable"
        [mfRowsOnPage]="10"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="nome">Nome</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="email">Email</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="celular">Celular</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="telefone">Telefone</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="temContrato">Tem Contrato?</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="temOportunidade"
                >Tem Oportunidade?</mfDefaultSorter
              >
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="mfCliente.data.length == 0 && !loading">
            <td colspan="7" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="7" class="text-center">
              <i class="fa fa-spin fa-spinner"></i> Carregando...
            </td>
          </tr>
          <tr *ngFor="let cliente of mfCliente.data; let i = index">
            <td>
              <a
                (click)="visualizar(cliente.clienteId)"
                tooltip="Visualizar dados do Cliente"
                class="mr-3 pointer"
                >{{ cliente.nome | uppercase }}</a
              >
            </td>
            <td>{{ cliente.email }}</td>
            <td>
              <a
                [href]="'https://wa.me/' + formatPhoneNumber(cliente.celular)"
                target="_blank"
                tooltip="Iniciar conversa no whatsapp"
                *ngIf="cliente.celular"
                ><i class="fab fa-whatsapp text-success"></i>
                {{ cliente.celular | formatPhone }}
              </a>
            </td>
            <td>
              {{ cliente.telefone | formatPhone }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                [ngClass]="{
                  'badge-success': cliente.temContrato,
                  'badge-danger': !cliente.temContrato
                }"
                >{{ cliente.temContrato ? "SIM" : "NÃO" }}</span
              >
            </td>
            <td class="text-center">
              <span
                class="badge"
                [ngClass]="{
                  'badge-success': cliente.temOportunidade,
                  'badge-danger': !cliente.temOportunidade
                }"
                >{{ cliente.temOportunidade ? "SIM" : "NÃO" }}</span
              >
            </td>
            <td class="text-center" nowrap>
              <a
                (click)="edit(cliente.clienteId)"
                tooltip="Editar"
                class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                (click)="delete(cliente.clienteId)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<!-- MODAL FILTRO -->
<ng-template #abrirFiltros>
  <div class="modal-header bg-primary">
    <h5 class="modal-title" id="filtrosModalLabel">
      <i class="fas fa-filter"></i> Filtros
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid">
    <div class="modal-body" [formGroup]="filtro">
      <div class="mb-2 row">
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="periodo">Período</label>
          </div>
          <input
            class="form-control"
            #drp="bsDaterangepicker"
            bsDaterangepicker
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
            formControlName="data"
            id="periodo"
          />
        </div>
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="faixa">Faixa de Valor</label>
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="valorinicial"
            placeholder="Valor Inicial"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
          <input
            type="text"
            class="form-control"
            formControlName="valorfinal"
            placeholder="Valor Final"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
        </div>
      </div>
      <div class="mb-2 row">
        <div class="input-group col-4">
          <div class="input-group-prepend">
            <label class="input-group-text" for="nome">Nome</label>
          </div>
          <input
            formControlName="nome"
            type="text"
            class="form-control"
            placeholder="Nome"
            id="nome"
          />
        </div>
        <div class="input-group col-4">
          <div class="input-group-prepend">
            <label class="input-group-text" for="email">Email</label>
          </div>
          <input
            formControlName="email"
            type="text"
            class="form-control"
            placeholder="Email"
            id="email"
          />
        </div>
        <div class="input-group col-4">
          <div class="input-group-prepend">
            <label class="input-group-text" for="celular">Celular</label>
          </div>
          <input
            formControlName="celular"
            type="text"
            class="form-control"
            placeholder="Celular"
            id="celular"
            [textMask]="{ mask: mask }"
          />
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="estadoId">Estado</label>
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              formControlName="estados"
              (change)="onChangeEstado()"
              [items]="estados"
              id="estadoId"
              bindValue="estadoId"
              bindLabel="nome"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="municipioId"
                >Municipio</label
              >
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              formControlName="municipios"
              (change)="onChangeMunicipio()"
              [items]="municipios"
              id="municipioId"
              bindValue="municipioId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="bairroId">Bairro</label>
            </div>
            <ng-select
              style="flex: 1"
              [multiple]="true"
              formControlName="bairros"
              [items]="bairros"
              id="bairroId"
              bindValue="bairroId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="contrato">Tem Contrato?</label>
          </div>
          <select
            formControlName="temContrato"
            class="form-control"
            id="temContrato"
          >
            <option value=""></option>
            <option value="true">SIM</option>
            <option value="false">NÃO</option>
          </select>
        </div>
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="temOportunidade"
              >Tem Oportunidade?</label
            >
          </div>
          <select
            formControlName="temOportunidade"
            class="form-control"
            id="temOportunidade"
          >
            <option value=""></option>
            <option value="true">SIM</option>
            <option value="false">NÃO</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-success float-right"
      (click)="onChangeFilter()"
      (click)="modalRef?.hide()"
    >
      Filtrar
    </button>
    <div *ngIf="filtrosAplicados > 0">
      <button
        class="btn btn-link float-right mr-2"
        (click)="removerFiltros()"
        (click)="modalRef?.hide()"
      >
        Remover filtros
      </button>
    </div>
  </div>
</ng-template>
