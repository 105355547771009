<form [formGroup]="formulario" (ngSubmit)="onSubmit()" *ngIf="formulario">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div><i class="fa fa-building"></i>&nbsp;Dados do Imóvel</div>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="form-group col-4">
                <label for="titulo">Titulo</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('titulo')"
                  id="titulo"
                  placeholder="Titulo"
                  formControlName="titulo"
                />
              </div>
              <div class="form-group col-4">
                <label for="tipoId">Tipo </label>
                <select
                  [ngClass]="aplicaCssErro('tipoId')"
                  formControlName="tipoId"
                  class="form-control"
                >
                  <option *ngFor="let tipo of tipos" [value]="tipo.tipoId">
                    {{ tipo.descricao }}
                  </option>
                </select>
              </div>
              <div class="form-group col-4">
                <label for="descricao">Tipo do Pregão </label>
                <select
                  [ngClass]="aplicaCssErro('pregaoId')"
                  formControlName="pregaoId"
                  class="form-control"
                >
                  <option *ngFor="let tipo of pregaos" [value]="tipo.pregaoId">
                    {{ tipo.descricao }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label for="intituicao">Modalidade</label>
                <select
                  [ngClass]="aplicaCssErro('modalidadeId')"
                  formControlName="modalidadeId"
                  class="form-control"
                >
                  <option
                    *ngFor="let modalidade of modalidades"
                    [value]="modalidade.modalidadeId"
                  >
                    {{ modalidade.descricao }}
                  </option>
                </select>
              </div>
              <div
                class="form-group col-6"
                *ngIf="formulario.value.modalidadeId == 1"
              >
                <label for="numeroProcesso">Numero Processo</label>
                <input
                  type="text"
                  class="form-control"
                  id="numeroProcesso"
                  placeholder="Numero Processo"
                  formControlName="numeroProcesso"
                />
              </div>
              <div
                class="form-group col-6"
                *ngIf="formulario.value.modalidadeId == 2"
              >
                <label for="intituicao">Instituição</label>
                <ng-select
                  [multiple]="false"
                  formControlName="instituicaoId"
                  [items]="instituicoes"
                  bindValue="instituicaoId"
                  bindLabel="nome"
                ></ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label for="endereco">Endereço</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('endereco')"
                  id="endereco"
                  formControlName="endereco"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label for="linkOrigem">Link de Origem</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('linkOrigem')"
                  id="linkOrigem"
                  placeholder="Link Origem"
                  formControlName="linkOrigem"
                />
              </div>
              <div class="form-group col-12">
                <label for="googleMaps">Link Google Maps </label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('googleMaps')"
                  id="googleMaps"
                  placeholder="Google Maps Url"
                  formControlName="googleMaps"
                />
              </div>
            </div>
            <div class="row" [ngClass]="aplicaCssErro('descricao')">
              <div class="col-12">
                <label for="editor1">Descrição Detalhada </label>
                <angular-editor
                  id="editor1"
                  formControlName="descricao"
                  [config]="editorConfig"
                >
                </angular-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" formArrayName="pracas">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header new-campo">
            <div class="cabecalho d-flex align-items-center">
              <i class="fas fa-calendar"></i>&nbsp; Praças
            </div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="adicionarPraca()"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;Adicionar
            </button>
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.pracas.length > 0"
          >
            <table class="table">
              <thead>
                <tr>
                  <th width="35%">Praça</th>
                  <th width="30%">Data</th>
                  <th width="20%">Valor</th>
                  <th width="15%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of this.formulario.get('pracas').controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <td>
                    {{ i + 1 }}ª Praça
                    <input
                      type="hidden"
                      formControlName="numero"
                      [value]="i + 1"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      id="dataExecucao"
                      formControlName="data"
                      placeholder="DD/MM/AAAA hh:mm"
                      [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY HH:mm'
                      }"
                      bsDatepicker
                      (bsValueChange)="onValueChangePraca($event, 'data', i)"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      currencyMask
                      [options]="{
                        prefix: 'R$ ',
                        thousands: '.',
                        decimal: ','
                      }"
                      class="form-control"
                      id="valor"
                      placeholder="Valor"
                      formControlName="valor"
                    />
                  </td>
                  <td class="text-center">
                    <label class="btn btn-danger m-0" (click)="deletePraca(i)"
                      ><i class="fa fa-trash"></i> Remover</label
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header new-campo d-flex align-items-center">
            <div class="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              &nbsp;Campos
            </div>

            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="adicionarCampo()"
            >
              <i class="fa fa-plus-circle"></i>&nbsp; Novo Campo
            </button>
          </div>

          <div
            class="card-block"
            formArrayName="campos"
            *ngIf="this.formulario.value.campos.length > 0"
          >
            <table class="table">
              <thead>
                <tr>
                  <th width="25%">Campo</th>
                  <th width="60%">Valor</th>
                  <th width="15%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let campo of filterList('campos'); let i = index"
                  [formGroupName]="i"
                >
                  <td>
                    <select
                      class="form-control"
                      formControlName="campoId"
                      [ngClass]="aplicaCssErroLista('campos', 'campoId', i)"
                    >
                      <option
                        value="true"
                        *ngFor="let item of campos"
                        value="{{ item.campoId }}"
                      >
                        {{ item.descricao }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control text-uppercase"
                      id="valor"
                      formControlName="valor"
                    />
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="deleteCampo(i)"
                    >
                      <i class="fa fa-trash"></i> Remover
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div
            class="card-block"
            *ngIf="this.formulario.value.campos.length == 0"
          >
            <alert type="danger">
              <strong>Campo Obrigatório</strong> É necessário inserir no mínimo
              um campo
            </alert>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row" formArrayName="fotos">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header new-campo">
            <div class="cabecalho d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-card-image"
                viewBox="0 0 16 16"
              >
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path
                  d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"
                />
              </svg>
              &nbsp;Fotos
            </div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="alterarFoto(-1)"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;Nova Foto
            </button>
            <input
              type="file"
              multiple
              #inputFotos
              (change)="fileChangeEvent($event)"
            />
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.fotos.length > 0"
          >
            <div class="row">
              <div
                class="form-group col-3"
                *ngFor="let item of filterList('fotos'); let i = index"
                [formGroupName]="i"
              >
                <div
                  class="custom-file fileInputProfileWrap"
                  (click)="alterarFoto(i)"
                  title="Clique para alterar a foto"
                >
                  <div class="img-space">
                    <img
                      [src]="item.value.arquivo.url"
                      class="img-responsive"
                    />
                  </div>
                </div>
                <div class="foto-buttons">
                  <!-- <label (click)="alterarFoto(i)" class="labelArquivo"
                    ><i class="fa fa-edit"></i> Alterar Foto</label
                  > -->
                  <label
                    (click)="deleteFoto(i)"
                    class="btn btn-danger labelRemove"
                    ><i class="fa fa-trash"></i> Remover</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.fotos.length == 0"
          >
            <alert type="danger">
              <strong>Campo Obrigatório</strong> É necessário no mínimo uma foto
            </alert>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div><i class="fas fa-map-marker-alt"></i>&nbsp; Localidade</div>
            <div>
              <input
                type="checkbox"
                name="hasLocalidade"
                [formControl]="hasLocalidade"
              />
            </div>
          </div>
          <div class="card-block" *ngIf="hasLocalidade.value">
            <div class="row">
              <div class="form-group col-4">
                <label for="estadoId">Estado </label>
                <ng-select
                  [multiple]="false"
                  formControlName="estadoId"
                  (change)="onChangeEstado()"
                  [items]="estados"
                  bindValue="estadoId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
              <div class="form-group col-4">
                <label for="municipioId">Municipio </label>
                <ng-select
                  [multiple]="false"
                  formControlName="municipioId"
                  (change)="onChangeMunicipio()"
                  [items]="municipios"
                  bindValue="municipioId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
              <div class="form-group col-4">
                <label for="titulo">Bairro</label>
                <ng-select
                  [multiple]="false"
                  formControlName="bairroId"
                  [items]="bairros"
                  bindValue="bairroId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" formArrayName="anexos">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header new-campo d-flex align-items-center">
            <div class="cabecalho">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-file-earmark-text"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                />
                <path
                  d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"
                />
              </svg>
              &nbsp;Anexos
            </div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="alterarAnexo(-1)"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;Novo Anexo
            </button>
            <input
              type="file"
              #inputAnexos
              (change)="anexoChangeEvent($event.target.files)"
            />
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.anexos.length > 0"
          >
            <table class="table">
              <thead>
                <tr>
                  <th width="50%">Arquivo</th>
                  <th width="30%">Nome</th>
                  <th width="20%"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let item of filterList('anexos'); let i = index"
                >
                  <tr [formGroupName]="i">
                    <td>
                      <a [href]="item.value.arquivo.url" target="_blank">{{
                        item.value.arquivo.nome
                      }}</a>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control text-uppercase"
                        formControlName="nome"
                        [ngClass]="aplicaCssErro('anexos', 'nome', i)"
                      />
                    </td>
                    <td class="text-center">
                      <!-- <label (click)="alterarAnexo(i)" class="btn btn-black">Alterar</label> -->
                      <label class="btn btn-danger m-0" (click)="deleteAnexo(i)"
                        >Remover</label
                      >
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <!-- <button  class="btn btn-primary saveCancel" (click)="console()"><i class="fa fa-save"></i> Console</button> -->
            <button type="submit" class="btn btn-primary saveCancel">
              <i class="fa fa-save"></i> Salvar
            </button>
            <button
              type="reset"
              class="btn btn-danger"
              [routerLink]="['/imoveis']"
            >
              <i class="fa fa-undo"></i> Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
