import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Location } from '@angular/common';
import {
  Cliente,
  Contrato,
  Corretor,
  Response,
  TipoPessoa,
} from 'app/views/_models';
import * as moment from 'moment';

@Component({
  selector: 'app-update-contrato',
  templateUrl: './update-contrato.component.html',
  styleUrls: ['./update-contrato.component.scss'],
})
export class UpdateContratoComponent implements OnInit {
  formulario: FormGroup;
  id: number;
  clientes: Cliente[];
  corretores: Corretor[];
  loadingSubmit: boolean;
  visualizando = false;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.restAngular
      .one('cliente')
      .get()
      .subscribe((res) => {
        this.clientes = res.data;
      });

    this.restAngular
      .one('corretor')
      .get()
      .subscribe((res) => {
        this.corretores = res.data;
      });

    this.id = this.activatedRoute.snapshot.params.id;

    this.restAngular
      .one('contrato', this.id)
      .get()
      .subscribe((data: Response<Contrato>) => {
        const contrato = data.data;

        this.formulario = this.formBuilder.group({
          contratoId: [contrato.contratoId],
          clienteId: [contrato.clienteId, Validators.required],
          corretorId: [contrato.corretorId],
          dataAssinatura: [
            moment(contrato.dataAssinatura).toDate(),
            Validators.required,
          ],
          ativo: [contrato.ativo, Validators.required],
          porcentagem: [contrato.porcentagem, Validators.required],
          tipoContrato: [contrato.tipoContrato, Validators.required],
          tipoAssinatura: [contrato.tipoAssinatura],
        });
      });
  }

  onSubimit() {
    //this.loadingSubmit = true;

    const body = this.formulario.getRawValue();

    body.tipoAssinatura = Number(body.tipoAssinatura);
    body.tipoContrato = Number(body.tipoContrato);
    body.ativo = String(body.ativo) === 'true';

    this.restAngular
      .all('contrato')
      .customPUT(body)
      .subscribe(
        () => {
          this.loadingSubmit = false;

          this.notifier.notify('success', 'Contrato atualizado com sucesso');
          this.router.navigate(['/contratos']);
        },
        (res) => {
          this.loadingSubmit = false;

          this.notifier.notify(
            'error',
            'Erro ao atualizar contrato - ' + res.data.message
          );
        }
      );
  }

  goBack(): void {
    this.location.back();
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'is-invalid': this.verificaValidTouched(campo) };
  }

  visualizarContrato() {
    const clienteId = this.formulario.value.clienteId;
    const porcentagem = this.formulario.value.porcentagem;
    if (!clienteId || !porcentagem) {
      this.notifier.notify('error', 'cliente deve estar selecionado!');
      return;
    }
    this.visualizando = true;
    this.restAngular
      .one('contrato/gerarExemplo/', clienteId)
      .withHttpConfig({ responseType: 'blob' })
      .get({ porcentagem })
      .subscribe((fileBlob: Blob) => {
        const file = new Blob([fileBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.visualizando = false;
      });
  }
}
