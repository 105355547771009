import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { OportunidadeService } from '../oportunidade.service';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-oportunidade-analise-juridica',
  templateUrl: './oportunidade-analise-juridica.component.html',
  styleUrls: ['./oportunidade-analise-juridica.component.scss'],
})
export class OportunidadeAnaliseJuridicaComponent implements OnInit {
  campos: any[] = [];
  selectedImovelId: number | null = null;
  loading = false;
  parecerPositivo = null;
  imovel = null;
  intencaoCompraId;
  salvandoNegativo = false;
  salvandoPositivo = false;

  constructor(
    private restangular: Restangular,
    private notifier: NotifierService,
    private oportunidadeService: OportunidadeService,
    private confirmation: ConfirmationService
  ) {}

  ngOnInit() {
    this.oportunidadeService.selectedImovel$.subscribe((imovel) => {
      if (imovel) {
        this.imovel = imovel;
        this.selectedImovelId = imovel.imovelId;

        this.getCampos();

        if (
          this.imovel.parecerPositivo === true ||
          this.imovel.parecerPositivo === false
        ) {
          this.parecerPositivo = this.imovel.parecerPositivo;
          this.oportunidadeService.changeAnaliseConfirmada(
            this.imovel.parecerPositivo
          );
        } else {
          this.parecerPositivo = null;
          this.oportunidadeService.changeAnaliseConfirmada(null);
        }
        window.scrollTo(0, 0);
      }
    });
    this.oportunidadeService.intencaoCompraId$.subscribe((intencaoCompraId) => {
      this.intencaoCompraId = intencaoCompraId;
    });
  }

  getImovel() {
    this.restangular
      .one('imovel')
      .one(this.selectedImovelId.toString())
      .get()
      .subscribe(
        (res) => {
          this.oportunidadeService.selectImovel(res.data);
        },
        (err) => {
          this.notifier.notify('error', 'erro ao obter imovel selecionado');
        }
      );
  }

  getCampos() {
    this.loading = true;

    this.restangular
      .one('checklistCampo/imovel/' + this.selectedImovelId)
      .get()
      .subscribe(
        (res) => {
          this.campos = res.data;
        },
        (err) => {
          this.notifier.notify('error', 'erro ao consultar checklist');
        }
      );
  }

  onValorChange(campo: any) {
    if (campo.valor == '' || campo.valor == undefined || campo.valor == null)
      return;

    const body = {
      imovelId: this.selectedImovelId,
      valor: campo.valor,
      checklistCampoId: campo.checkListCampoId,
    };

    this.restangular
      .all('checklistCampoValor')
      .post(body)
      .subscribe(
        (response) => {
          //console.log('Campo atualizado com sucesso', response);
        },
        (error) => {
          console.error('Erro ao atualizar campo', error);
        }
      );
  }

  concluirParecer(hasPositivo: boolean) {
    this.confirmation
      .create(
        'ATENÇÃO?',
        'Deseja realmente salvar o parecer como ' +
          (hasPositivo ? 'POSITIVO?' : 'NEGATIVO?')
      )
      .subscribe((ans) => {
        if (ans.resolved) {
          const body = {
            imovelId: this.selectedImovelId,
            parecerPositivo: hasPositivo,
            intencaoCompraId: this.intencaoCompraId,
          };

          this.showHideLoading(hasPositivo);

          this.restangular
            .all('ChecklistCampo/SalvarParecer')
            .post(body)
            .subscribe(
              () => {
                this.notifier.notify(
                  'success',
                  'Parecer salvo como ' +
                    (hasPositivo ? 'POSITIVO' : 'NEGATIVO')
                );
                // this.oportunidadeService.changeAnaliseConfirmada(hasPositivo);
                // this.parecerPositivo = hasPositivo;
                this.showHideLoading(hasPositivo);
                this.getImovel();
              },
              () => {
                this.notifier.notify('error', 'Erro ao salvar parecer');
                this.showHideLoading(hasPositivo);
              }
            );
        }
      });
  }

  showHideLoading(positivo) {
    if (positivo) {
      this.salvandoPositivo = !this.salvandoPositivo;
    } else {
      this.salvandoNegativo = !this.salvandoNegativo;
    }
  }

  // gerarParecer() {
  //   this.restangular
  //     .one('imovel/gerarParecer', this.selectedImovelId)
  //     .withHttpConfig({ responseType: 'blob' })
  //     .get()
  //     .subscribe(
  //       (res) => {
  //         const file = new Blob([res], { type: 'application/pdf' });
  //         const fileURL = URL.createObjectURL(file);
  //         window.open(fileURL);
  //       },
  //       (err) => {
  //         this.notifier.notify('error', 'erro ao gerar parecer');
  //       }
  //     );
  // }
}
