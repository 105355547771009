/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../node_modules/angular-notifier/angular-notifier.ngfactory";
import * as i3 from "angular-notifier";
import * as i4 from "../../node_modules/@jaspero/ng-confirmations/ng-confirmations.ngfactory";
import * as i5 from "@jaspero/ng-confirmations";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "notifier-container", [["class", "notifier__container"]], null, null, null, i2.View_ɵd_0, i2.RenderType_ɵd)), i0.ɵdid(3, 245760, null, 0, i3.ɵd, [i0.ChangeDetectorRef, i3.ɵe, i3.NotifierService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "jaspero-confirmations", [], null, null, null, i4.View_ConfirmationsComponent_0, i4.RenderType_ConfirmationsComponent)), i0.ɵprd(8704, null, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, [i4.ConfirmationComponentNgFactory]], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵdid(6, 245760, null, 0, i5.ConfirmationsComponent, [i5.ConfirmationService, i0.ComponentFactoryResolver, i6.DomSanitizer], { defaultSettings: [0, "defaultSettings"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = _co.confirmationOptions; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i7.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("body", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
