<form [formGroup]="formulario" *ngIf="formulario" (submit)="onSubimit()">
  <div class="card animated fadeIn">
    <div class="card-header">
      <p class="card-title d-inline">
        <i class="fas fa-file-contract"></i> Informações do contrato
      </p>
      <div class="float-right">
        <button
          class="btn-success btn"
          type="button"
          tooltip="Visualizar PDF do contrato"
          (click)="visualizarContrato()"
          *ngIf="!visualizando"
        >
          <i class="fas fa-file-pdf"></i> Preview Contrato
        </button>
        <button class="btn-success btn float-right mr-3" *ngIf="visualizando">
          <i class="fas fa-spin fa-spinner"></i> Carregando...
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-6">
          <label for="cliente">Cliente</label>
          <select
            name="cliente"
            class="form-control"
            formControlName="clienteId"
          >
            <option *ngFor="let item of clientes" value="{{ item.clienteId }}">
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <label for="corretor">Corretor</label>
          <select
            name="corretor"
            class="form-control"
            formControlName="corretorId"
          >
            <option
              *ngFor="let item of corretores"
              value="{{ item.corretorId }}"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-group">
          <label for="dataAssinatura">Data Assinatura</label>
          <input
            type="text"
            class="form-control"
            id="dataAssinatura"
            placeholder="Data Assinatura"
            formControlName="dataAssinatura"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY'
            }"
            bsDatepicker
          />
        </div>
        <div class="col-6 form-group">
          <label for="status">Status</label>
          <select
            class="form-control"
            name="vigenciaContrato"
            id="status"
            formControlName="ativo"
          >
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-group">
          <label for="porcentagem">Porcentagem</label>
          <input
            type="text"
            currencyMask
            [options]="{
              prefix: '% ',
              thousands: '.',
              decimal: ',',
              precision: 0
            }"
            class="form-control"
            id="porcentagem"
            placeholder="Porcentagem"
            formControlName="porcentagem"
          />
        </div>
        <div class="col-6 form-group">
          <label for="tipoContrato">Tipo Contrato</label>
          <select
            name="tipoContrato"
            class="form-control"
            formControlName="tipoContrato"
          >
            <option [value]="1">Manual</option>
            <option [value]="2">Assinatura Digital</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div
          class="col-6 form-group"
          *ngIf="formulario.value.tipoContrato == 2"
        >
          <label for="tipoAssinatura">Tipo Assinatura</label>
          <select
            name="tipoAssinatura"
            class="form-control"
            formControlName="tipoAssinatura"
          >
            <option [value]="1">Certificado Digital</option>
            <option [value]="2">Digitalizada</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="submit"
        class="btn btn-primary mr-2"
        [disabled]="!formulario.valid || loadingSubmit"
      >
        <i class="fa fa-save"></i>
        {{ loadingSubmit ? "Gerando contrato..." : "Salvar" }}
      </button>
      <button type="reset" class="btn btn-danger" (click)="goBack()">
        <i class="fa fa-undo"></i> Cancelar
      </button>
    </div>
  </div>
</form>
