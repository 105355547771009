var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
var ClienteComponent = /** @class */ (function () {
    function ClienteComponent(restAngular, notifier, confirmation, router, fb, modalService, localeService) {
        this.restAngular = restAngular;
        this.notifier = notifier;
        this.confirmation = confirmation;
        this.router = router;
        this.fb = fb;
        this.modalService = modalService;
        this.localeService = localeService;
        this.clientes = [];
        this.loading = false;
        this.filtrando = false;
        this.filtrosAplicados = 0;
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        localeService.use('pt-br');
        this.mask = [
            '(',
            /[1-9]/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
    }
    ClienteComponent.prototype.ngOnInit = function () {
        this.defaultFormValue = {
            data: [[]],
            nome: '',
            email: '',
            celular: '',
            estados: [[]],
            municipios: [[]],
            bairros: [[]],
            valorinicial: '',
            valorfinal: '',
            temContrato: '',
            temOportunidade: '',
        };
        this.filtro = this.fb.group(this.defaultFormValue);
        this.getCliente();
    };
    ClienteComponent.prototype.contarFiltros = function (values) {
        this.filtrosAplicados = Object.values(values).filter(function (value) {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value;
        }).length;
    };
    ClienteComponent.prototype.getEstado = function () {
        var _this = this;
        this.restAngular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
        });
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        if (this.filtro.value.municipios === null ||
            this.filtro.value.municipios.length === 0) {
            this.filtro.get('bairros').disable();
            return;
        }
    };
    ClienteComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.filtro.get('municipios').disable();
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        this.restAngular
            .one('localidade/municipio')
            .get({ estados: this.filtro.value.estados })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.filtro.get('municipios').enable();
        });
    };
    ClienteComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipios = this.filtro.value.municipios;
        if (municipios === null || municipios.length === 0) {
            this.filtro.get('bairros').disable();
            return;
        }
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('estados').markAsTouched();
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        this.restAngular
            .one('localidade/bairro')
            .get({ municipios: municipios })
            .subscribe(function (res) {
            var bairros = res.data;
            _this.bairros = bairros;
            _this.filtro.get('bairros').enable();
        });
    };
    ClienteComponent.prototype.getCliente = function () {
        var _this = this;
        this.loading = true;
        var dateStart = '';
        var dateEnd = '';
        var datas = this.filtro.controls['data'].value;
        if (datas && datas.length > 1) {
            dateStart = moment(datas[0]).format('YYYY/MM/DD');
            dateEnd = moment(datas[1]).format('YYYY/MM/DD');
        }
        this.restAngular
            .one('cliente')
            .get(__assign({ dateStart: dateStart, dateEnd: dateEnd }, this.filtro.value))
            .subscribe(function (response) {
            _this.clientes = response.data;
            _this.loading = false;
        }, function (error) {
            _this.loading = false;
            _this.notifier.notify('error', 'Não foi possível carregar os clientes');
        });
    };
    ClienteComponent.prototype.edit = function (clienteId) {
        this.router.navigate(['edit-cliente', clienteId]);
    };
    ClienteComponent.prototype.visualizar = function (clienteId) {
        this.router.navigate(['visualizar-cliente', clienteId]);
    };
    ClienteComponent.prototype.delete = function (clienteId) {
        var _this = this;
        this.confirmation
            .create('Atenção', 'Deseja realmente excluir o cliente?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restAngular
                    .one('cliente', clienteId)
                    .remove()
                    .subscribe(function (response) {
                    _this.notifier.notify('success', 'Cliente excluído com sucesso');
                    _this.getCliente();
                }, function (error) {
                    if (error.data && error.data.Message) {
                        _this.notifier.notify('error', error.data.Message);
                    }
                    else {
                        _this.notifier.notify('error', 'Não foi possível excluir o cliente');
                    }
                });
            }
        });
    };
    ClienteComponent.prototype.obeterIconeLead = function (cliente) {
        var valorInicial = cliente.valorInicial, valorFinal = cliente.valorFinal, localidadesInteresse = cliente.localidadesInteresse;
        if (valorInicial > 0 && valorFinal > 0 && localidadesInteresse.length > 0) {
            return '<i class="fas fas fa-thermometer-full full fa-2x text-danger" title="Quente"></i>';
        }
        else if ((valorInicial > 0 && valorFinal > 0) ||
            localidadesInteresse.length > 0) {
            return '<i class="fas fa-thermometer-half half fa-2x text-warning" title="Médio"></i>';
        }
        else {
            return '<i class="fas fa-thermometer-empty empty fa-2x text-info" title="Frio"></i>';
        }
    };
    ClienteComponent.prototype.formatPhoneNumber = function (phone) {
        var phoneTratado = phone.replace(/\D/g, '');
        if (!phone.startsWith('5') && phoneTratado.length < 13)
            phoneTratado = '55' + phoneTratado;
        return phoneTratado;
    };
    ClienteComponent.prototype.openModal = function (template) {
        this.getEstado();
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    };
    ClienteComponent.prototype.onChangeFilter = function () {
        this.clientes = [];
        var celular = this.filtro.get('celular');
        celular.setValue(celular.value.replaceAll('_', ''));
        celular.setValue(celular.value.replaceAll('-', ''));
        this.getCliente();
        this.contarFiltros(this.filtro.value);
    };
    ClienteComponent.prototype.removerFiltros = function () {
        this.filtrosAplicados = 0;
        this.filtro = this.fb.group(this.defaultFormValue);
        this.municipios = [];
        this.bairros = [];
        this.estados = [];
        this.clientes = [];
        this.getCliente();
    };
    return ClienteComponent;
}());
export { ClienteComponent };
