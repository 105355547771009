<div class="card animated fadeIn">
  <div class="card-header">
    <div class="d-inline">
      <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
      Bairros
    </div>
    <button
      [routerLink]="['/create-bairro']"
      class="btn btn-success float-right"
      routerLinkActive="router-link-active"
    >
      <i class="fa fa-plus-circle"></i>
      &nbsp;Novo Bairro
    </button>
  </div>
  <div class="card-body">
    <div class="mb-2 filter">
      <div>
        <div
          class="input-group d-inline-flex align-items-center w-auto mr-3"
          style="min-width: 200px"
        >
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">
              Estado
            </span>
          </div>
          <select
            [(ngModel)]="estadoId"
            (ngModelChange)="onChangeFilterEstado()"
            class="form-control"
            placeholder="estado"
          >
            <option *ngFor="let item of estados" [value]="item.estadoId">
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div
          class="input-group d-inline-flex align-items-center w-auto mr-3"
          style="min-width: 200px"
        >
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">
              Municipio
            </span>
          </div>
          <select
            [(ngModel)]="municipioId"
            [disabled]="!estadoId"
            (ngModelChange)="onChangeFilterMunicipio()"
            class="form-control"
            placeholder="municipio"
          >
            <option *ngFor="let item of municipios" [value]="item.municipioId">
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="input-group d-inline-flex align-items-center w-auto mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">
              Bairro
            </span>
          </div>
          <input
            [(ngModel)]="pesquisa"
            (ngModelChange)="onInputChange($event)"
            type="text"
            class="form-control"
            placeholder="Bairro"
          />
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        #mfEndereco="mfDataTable"
        [mfData]="bairros"
        [mfRowsOnPage]="10"
        class="table"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="municipio.estado.nome"
                >Estado</mfDefaultSorter
              >
            </th>
            <th>
              <mfDefaultSorter by="municipio.nome">Municipio</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="zona">Zona</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="nome">Bairro</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="mfEndereco.data.length == 0 && !loading">
            <td colspan="6" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="6" class="text-center">Carregando...</td>
          </tr>
          <tr *ngFor="let endereco of mfEndereco.data; let i = index">
            <td>
              {{ endereco.municipio.estado.sigla }}
            </td>
            <td>
              {{ endereco.municipio.nome }}
            </td>
            <td>{{ endereco.zona }}</td>
            <td>{{ endereco.nome }}</td>

            <td class="text-center" nowrap>
              <a
                (click)="edit(endereco.bairroId)"
                tooltip="Editar"
                class="mr-3 pointer"
              >
                <i class="fa-lg far fa-edit"></i>
              </a>
              <a
                (click)="delete(endereco.bairroId)"
                tooltip="Excluir"
                class="mr-1 pointer"
              >
                <i class="fa-lg far fa-times-circle text-danger"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
