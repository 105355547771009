<div class="row animated fadeIn">
  <div class="col-lg-12">
    <div class="card" *ngIf="campanhas">
      <div class="card-header">
        <p class="d-inline">
          <i class="fas fa-envelope-square"></i> &nbsp;Campanhas
        </p>
        <button
          type="button"
          class="btn btn-sm btn-success float-right"
          [routerLink]="['/create-campanha']"
        >
          <i class="fa fa-plus-circle"></i>&nbsp; Nova Campanha
        </button>
      </div>
      <div class="card-block">
        <table
          class="table"
          [mfData]="camapanhas"
          #mfCampanha="mfDataTable"
          [mfRowsOnPage]="10"
        >
          <thead>
            <tr>
              <th width="5%" class="text-center" nowrap>Ativo</th>
              <th width="5%">
                <mfDefaultSorter by="Id"> ID </mfDefaultSorter>
              </th>
              <th width="20%">
                <mfDefaultSorter by="titulo">Titulo</mfDefaultSorter>
              </th>
              <th width="15%">
                <mfDefaultSorter by="meioNotificacao"
                  >Plataforma</mfDefaultSorter
                >
              </th>
              <th width="15%">
                <mfDefaultSorter by="tipoAgenda">Periodicidade</mfDefaultSorter>
              </th>
              <th width="10%">
                <mfDefaultSorter by="dataExecucao"
                  >Data da Execução</mfDefaultSorter
                >
              </th>
              <th width="10%">
                <mfDefaultSorter by="dataEncerramento"
                  >Data do Encerramento</mfDefaultSorter
                >
              </th>
              <th width="10%">
                <mfDefaultSorter by="ultimaExecucao"
                  >Última Execução</mfDefaultSorter
                >
              </th>
              <th width="10%" class="text-center" nowrap>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="campanhas.length == 0 && !loading">
              <td colspan="7" class="text-center text-muted">
                Nenhuma campanha encontrada!
                <i class="fas fa-exclamation-triangle"></i>
              </td>
            </tr>
            <tr *ngIf="loading">
              <td colspan="7" class="text-center">
                <i class="fa fa-spin fa-spinner"></i> Carregando...
              </td>
            </tr>
            <tr *ngFor="let campanha of campanhas">
              <td class="text-center">
                <label
                  class="switch switch-text switch-pill switch-primary m-0"
                >
                  <input
                    type="checkbox"
                    id="blockchain"
                    class="switch-input"
                    [(ngModel)]="campanha.ativo"
                    (change)="atualizarStatus(campanha)"
                  />
                  <span
                    class="switch-label"
                    data-on="Sim"
                    data-off="Não"
                  ></span>
                  <span class="switch-handle"></span>
                </label>
              </td>
              <td>
                {{ campanha.campanhaId }}
              </td>
              <td>
                {{ campanha.titulo }}
              </td>
              <td>{{ campanha.meiosNotificacao }}</td>
              <td [ngSwitch]="campanha.tipoAgenda">
                <span *ngSwitchCase="1">Apenas uma vez</span>
                <span *ngSwitchCase="2">Diário</span>
                <span *ngSwitchCase="3">Semanal</span>
                <span *ngSwitchCase="4">Mensal</span>
              </td>
              <td>
                {{
                  campanha.dataExecucao
                    | amUtc
                    | amLocal
                    | amDateFormat : "DD/MM/yyyy HH:mm"
                }}
              </td>
              <td>
                {{
                  campanha.dataEncerramento
                    ? (campanha.dataEncerramento
                      | amUtc
                      | amLocal
                      | amDateFormat : "DD/MM/yyyy HH:mm")
                    : "-"
                }}
              </td>
              <td>
                {{
                  campanha.ultimaExecucao
                    ? (campanha.ultimaExecucao | amUtc | amLocal | amTimeAgo)
                    : "-"
                }}
              </td>

              <td class="text-center" nowrap>
                <a
                  (click)="edit(campanha.campanhaId)"
                  tooltip="Editar"
                  class="mr-3 pointer"
                  ><i class="fa-lg far fa-edit"></i
                ></a>
                <a
                  (click)="verLogs(campanha.campanhaId)"
                  tooltip="Visualizar os logs"
                  class="mr-3 pointer"
                >
                  <i class="far fa-envelope-open fa-lg"></i
                ></a>
                <a
                  (click)="delete(campanha.campanhaId)"
                  tooltip="Excluir"
                  class="mr-1 pointer"
                  ><i class="fa-lg far fa-times-circle text-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
