import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Status } from 'app/views/_models';
import { Restangular } from 'ngx-restangular';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { OportunidadeService } from '../oportunidade.service';

@Component({
  selector: 'app-oportunidade-eventos',
  templateUrl: './oportunidade-eventos.component.html',
  styleUrls: ['./oportunidade-eventos.component.scss'],
})
export class OportunidadeEventosComponent implements OnInit {
  @ViewChild('inputAnexos') inputAnexos: ElementRef;

  statusIntencaoCompra: Status[] = [];
  status;

  //anexos
  anexosbase64: any;
  anexosnome: any;
  anexostamanho: any;
  anexostipo: any;
  numeroAdcAnexo: number;
  arrayAnexos = [];
  fileToUpload: File | null = null;
  logs: any[] = [];

  formulario: FormGroup;
  salvando = false;

  @Input('intencaoId') intencaoId;

  constructor(
    private restAngular: Restangular,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private oportunidadeService: OportunidadeService
  ) {}

  ngOnInit() {
    this.restAngular
      .one('IntencaoCompra/status')
      .get()
      .subscribe((res) => (this.statusIntencaoCompra = res.data));

    this.carregaLogs();

    this.formulario = this.formBuilder.group({
      intencaoId: [this.intencaoId],
      observacao: [''],
      status: [null, Validators.required],
      anexos: this.formBuilder.array([]),
      dataLimite: [null]
    });

    this.oportunidadeService.intencaoCompraId$.subscribe((intencaoCompraId) => {
      this.intencaoId = intencaoCompraId;
    });
  }

  carregaLogs() {
    this.restAngular
      .one('movimentacao/intencao', this.intencaoId)
      .get()
      .subscribe((res) => {
        this.logs = res.data;
      });
  }

  alterarAnexo(i) {
    this.numeroAdcAnexo = i;
    this.inputAnexos.nativeElement.click();
  }

  anexoChangeEvent(anexoInput: FileList) {
    this.fileToUpload = anexoInput.item(0);
    this.fileToUpload.name;
    this.fileToUpload.size;
    this.fileToUpload.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.anexosbase64 = reader.result;
      const arquivo = this.formBuilder.group({
        arquivoId: 0,
        nome: [this.fileToUpload.name, Validators.required],
        base64: this.anexosbase64,
        tipo: this.fileToUpload.type,
        tamanho: this.fileToUpload.size,
        dataCadastro: moment().utc().toISOString(),
      });

      this.atualizarAnexo(arquivo, this.numeroAdcAnexo);
    };
  }

  atualizarAnexo(obj, i) {
    const anexos = this.formulario.get('anexos') as FormArray;

    if (i < 0) {
      anexos.push(
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
          nome: [obj.nome, Validators.required],
        })
      );
    } else {
      const valor = anexos.value[i];
      anexos.removeAt(i);
      anexos.insert(
        i,
        this.formBuilder.group({
          arquivo: obj,
          acao: 'A',
          nome: [obj.nome, Validators.required],
        })
      );
    }
  }

  filterList(campo: string) {
    const campos = this.formulario.get(campo) as FormArray;
    return campos.controls.filter(
      (x) => (x as FormGroup).controls['acao'].value !== 'D'
    );
  }

  deleteAnexo(indexAnexo: number) {
    const anexos = this.formulario.controls['anexos'] as FormArray;
    const anexo = anexos.at(indexAnexo) as FormGroup;
    if (anexo.controls['acao'].value !== 'I') {
      anexo.controls['acao'].setValue('D');
    } else {
      anexos.removeAt(indexAnexo);
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  submit() {
    this.salvando = true;

    const body = this.formulario.value;

    body.statusId = body.status.id; 

    this.restAngular
      .all('movimentacao')
      .post(body)
      .subscribe(
        (res) => {
          this.notifier.notify('success', 'Evento cadastrado com sucesso');
          this.carregaLogs();
          this.salvando = false;
        },
        (err) => {
          this.notifier.notify('error', 'Erro ao cadastrar Evento');
          this.salvando = false;
        }
      );
  }
}
