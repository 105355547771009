import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Imovel, Response } from '../_models';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-relatorios-imovel',
  templateUrl: './relatorios-imovel.component.html',
  styleUrls: ['./relatorios-imovel.component.scss'],
})
export class RelatoriosImovelComponent implements OnInit {
  imoveis: Imovel[];
  loading: boolean = false;
  exportandoPdf: boolean = false;
  titulo: string = '';
  filtro: FormGroup;
  editarItemForm: FormGroup;
  modalRef: BsModalRef;
  filtrando: boolean = false;
  filtrosAplicados: number = 0;
  imovelEditando;
  imovelAlterado = [];
  imovelRemovido = [];

  public mask: Array<string | RegExp>;

  bsValue = new Date();
  bsRangeValue: Date[];
  bsValue2 = new Date();
  bsRangeValue2: Date[];
  maxDate = new Date();
  defaultFormValue;
  tipoImovel;
  tipoPregao;
  modalidade;
  origens;

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  constructor(
    private restangular: Restangular,
    private notifier: NotifierService,
    private confirmation: ConfirmationService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use('pt-br');
  }

  ngOnInit() {
    this.defaultFormValue = {
      estado: [''],
      municipio: [[]],
      bairro: [[]],
      zona: [''],
      titulo: [''],
      data: [],
      data2: [],
      imoveisActive: [true],
      origem: [[]],
      referencia: [''],
      valorInicial: '',
      valorFinal: '',
      pregao: [[]],
      tipo: [[]],
      modalidade: [[]],
      numProcesso: '',
    };
    this.filtro = this.fb.group(this.defaultFormValue);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.bsRangeValue2 = [this.bsValue2, this.maxDate];
    this.getAllImoveis();
    this.getTipoImovel();
    this.getTipoPregao();
    this.getModalidade();
  }

  openModal(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.getEstado();
    this.getOrigem();
  }

  openModalEdit(template, item) {
    this.imovelEditando = {
      ...item,
      endereco: { ...item.endereco },
      praca1: { ...item.praca1 },
      praca2: { ...item.praca2 },
    };
    if (this.imovelEditando) {
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }
  }

  getEstado() {
    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
      });
    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }
    if (
      this.filtro.value.municipio === null ||
      this.filtro.value.municipio.length === 0
    ) {
      this.filtro.get('bairro').disable();
      return;
    }
  }

  onChangeEstado() {
    this.filtro.get('municipio').disable();
    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estados: this.filtro.value.estado })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.filtro.get('municipio').enable();
      });
  }

  onChangeMunicipio() {
    const municipios = this.filtro.value.municipio;

    if (municipios === null || municipios.length === 0) {
      this.filtro.get('bairro').disable();
      return;
    }

    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('estado').markAsTouched();
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipios })
      .subscribe((res) => {
        const bairros = res.data;
        this.bairros = bairros;
        this.filtro.get('bairro').enable();
      });
  }

  groupByMunicipio = (item) => item.municipio.nome;
  groupValueFn = (_: string, children: any[]) => ({
    name: children[0].municipio.nome,
  });

  contarFiltros(values: any): void {
    this.filtrosAplicados = Object.values(values).filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    }).length;
  }

  onChangeFilter() {
    this.imoveis = [];
    this.getAllImoveis();
    this.contarFiltros(this.filtro.value);
  }

  getTipoImovel() {
    this.restangular
      .one('tipo')
      .get()
      .subscribe((response) => {
        this.tipoImovel = response.data;
      });
  }

  getTipoPregao() {
    this.restangular
      .one('pregao')
      .get()
      .subscribe((response) => {
        this.tipoPregao = response.data;
      });
  }

  getModalidade() {
    this.restangular
      .one('modalidade')
      .get()
      .subscribe((response) => {
        this.modalidade = response.data;
      });
  }

  getOrigem() {
    this.origens = [];
    this.restangular
      .one('imovel/origem')
      .get()
      .subscribe((response) => {
        this.origens = response.data.filter((x) => x != null);
      });
  }

  downloadRelatorio() {
    let dataInicialPrimeiraPraca = null;
    let dataFinalPrimeiraPraca = null;
    let dataInicialSegundaPraca = null;
    let dataFinalSegundaPraca = null;

    const datas = this.filtro.controls['data'].value;
    if (datas !== null) {
      dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
      dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
    }

    const datas2 = this.filtro.controls['data2'].value;
    if (datas2 !== null) {
      dataInicialSegundaPraca = moment(datas2[0]).toISOString();
      dataFinalSegundaPraca = moment(datas2[1]).toISOString();
    }
    const filtro = this.filtro.value;

    const url = 'imovel/relatorio/pdf';
    const extensao = '.pdf';
    this.exportandoPdf = true;

    this.restangular
      .all(url)
      .withHttpConfig({ responseType: 'blob' })
      .post({
        titulo: filtro.titulo,
        bairros: filtro.bairro,
        estado: filtro.estado == '' ? null : filtro.estado,
        municipios:
          !filtro.municipio || filtro.municipio == ''
            ? null
            : [filtro.municipio],
        zona: filtro.zona,
        dataInicialPrimeiraPraca,
        dataFinalPrimeiraPraca,
        dataInicialSegundaPraca,
        dataFinalSegundaPraca,
        origem: filtro.origem,
        id: filtro.referencia == '' ? null : filtro.referencia,
        ImoveisActive: filtro.imoveisActive,
        valorInicial:
          filtro.valorInicial == '' || filtro.valorInicial == 'NaN'
            ? null
            : filtro.valorInicial,
        valorFinal:
          filtro.valorFinal == '' || filtro.valorFinal == 'NaN'
            ? null
            : filtro.valorFinal,
        tipo: filtro.tipo,
        modalidade: filtro.modalidade,
        pregao: filtro.pregao,
        processo: filtro.numProcesso,
        ImoveisRemovidos: this.imovelRemovido,
        ImoveisAlterados: this.imovelAlterado,
      })
      .subscribe(
        (response) => {
          this.exportandoPdf = false;

          const blob = response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `RelatorioImoveis_${moment().unix()}${extensao}`;

          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          }, 100);
        },
        (err) => {
          this.exportandoPdf = false;
          this.notifier.notify('error', 'Erro ao gerar o relatorio de imóveis');
        }
      );
  }

  getAllImoveis() {
    let dataInicialPrimeiraPraca = '';
    let dataFinalPrimeiraPraca = '';
    let dataInicialSegundaPraca = '';
    let dataFinalSegundaPraca = '';

    const datas = this.filtro.controls['data'].value;
    if (datas !== null) {
      dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
      dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
    }

    const datas2 = this.filtro.controls['data2'].value;
    if (datas2 !== null) {
      dataInicialSegundaPraca = moment(datas2[0]).toISOString();
      dataFinalSegundaPraca = moment(datas2[1]).toISOString();
    }

    this.loading = true;
    const filtro = this.filtro.getRawValue();
    this.restangular
      .one('imovel/relatorio')
      .get({
        titulo: filtro.titulo,
        bairros: filtro.bairro || [],
        estado: filtro.estado || '',
        municipios: filtro.municipio || [],
        zona: filtro.zona,
        dataInicialPrimeiraPraca,
        dataFinalPrimeiraPraca,
        dataInicialSegundaPraca,
        dataFinalSegundaPraca,
        origem: filtro.origem,
        id: filtro.referencia,
        ImoveisActive: filtro.imoveisActive,
        valorInicial: Number.isNaN(filtro.valorInicial)
          ? ''
          : filtro.valorInicial,
        valorFinal: Number.isNaN(filtro.valorFinal) ? '' : filtro.valorFinal,
        tipo: filtro.tipo,
        modalidade: filtro.modalidade,
        pregao: filtro.pregao,
        processo: filtro.numProcesso,
      })
      .subscribe(
        (res) => {
          this.loading = false;
          this.imoveis = res.data;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  removerDaLista(Id) {
    const index = this.imoveis.findIndex((imovel: any) => imovel.id === Id);
    if (index !== -1) {
      this.imoveis.splice(index, 1);
      this.imovelRemovido.push(Id);
    }
  }

  salvarEdicao(objeto) {
    const existeEdicao = this.imovelAlterado.some(
      (imovel) => imovel.id === objeto.id
    );
    if (existeEdicao) {
      this.imovelAlterado = this.imovelAlterado.filter(
        (imovel) => imovel.id !== objeto.id
      );
    }
    this.imovelAlterado.push(objeto);

    const index = this.imoveis.findIndex(
      (imovel: any) => imovel.id === objeto.id
    );
    if (index !== -1) {
      this.imoveis[index] = objeto;
    }

    this.modalRef.hide();
  }

  removerFiltros() {
    this.filtrosAplicados = 0;
    this.filtro = this.fb.group(this.defaultFormValue);
    this.imoveis = [];
    this.getAllImoveis();
  }
}
