<div [formGroup]="formulario">
  <div class="card animated fadeIn">
    <div class="card-body">
      <form [formGroup]="formulario">
        <div class="row">
          <div class="col">
            <div class="form-group mt-3">
              <label for="status">Status</label>
              <select
                class="form-control"
                formControlName="status"
                name="status"
              >
                <option
                  *ngFor="let status of statusIntencaoCompra"
                  [ngValue]="status"
                >
                  {{ status.descricao }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col"
            *ngIf="
              formulario.value.status &&
              formulario.value.status.possuiDataLimite
            "
          >
            <div class="form-group mt-3">
              <label for="dataLimite">Data limite</label>
              <input
                type="date"
                formControlName="dataLimite"
                name="dataLimite"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for>Observação</label>
              <textarea
                class="form-control"
                formControlName="observacao"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- <div class="row" *ngIf="formulario.value.status && formulario.value.status.possuiDataLimite">
          <div class="col">
            <div class="form-group">
              <div class="label">Data limite</div>
              <input type="date" formControlName="dataLimite" class="form-control"/>
            </div>
          </div>
        </div> -->
        <div class="row" formArrayName="anexos">
          <div class="col">
            <div class="card">
              <div class="card-header new-campo d-flex align-items-center">
                <div class="cabecalho d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark-text"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"
                    />
                  </svg>
                  &nbsp;Anexos
                </div>
                <button
                  type="button"
                  class="btn btn-success btn-sm ml-auto"
                  (click)="alterarAnexo(-1)"
                >
                  <i class="fa fa-plus-circle"></i>&nbsp;Adicionar Anexo
                </button>
                <input
                  type="file"
                  #inputAnexos
                  class="d-none"
                  (change)="anexoChangeEvent($event.target.files)"
                />
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th width="60%">Arquivo</th>
                      <th width="20%">Tamanho</th>
                      <th width="20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let item of filterList('anexos'); let i = index"
                    >
                      <tr [formGroupName]="i">
                        <td>
                          <a [href]="item.value.arquivo.url" target="_blank">
                            {{ item.value.arquivo.nome }}
                          </a>
                        </td>
                        <td>
                          {{ formatBytes(item.value.arquivo.tamanho) }}
                        </td>
                        <td class="text-center">
                          <label
                            class="btn btn-danger m-0"
                            (click)="deleteAnexo(i)"
                          >
                            Remover
                          </label>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="submit()"
        *ngIf="!salvando"
      >
        <i class="fa fa-save"></i> Salvar
      </button>
      <button type="button" class="btn btn-primary" *ngIf="salvando" disabled>
        <i class="fa fa-spin fa-spinner"></i> Salvando...
      </button>
    </div>
  </div>

  <div class="card my-4">
    <div class="card-header bg-primary text-white">
      <i class="fas fa-clipboard-list"></i> Linha do Tempo
    </div>
    <div class="card-body">
      <div class="card" *ngFor="let log of logs">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-center">
            <span class="badge badge-info">{{ log.status?.descricao }}</span>
            <div>
              <span class="mr-3">
                <i class="fas fa-user"></i>
                {{ log.usuario?.nome | uppercase }}
              </span>
              <span class="note">
                <i class="fas fa-calendar-alt"></i>
                {{ log.dataCadastro | amCalendar }}
              </span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            {{ log.observacao }}
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex" style="gap: 10px">
            <span class="text-muted"
              ><i class="fas fa-paperclip"></i> Anexos:</span
            >
            <span
              *ngFor="let anexo of log.arquivos"
              style="line-height: 16px"
              class="badge badge-secondary"
            >
              <a [href]="anexo.arquivo.url" target="_blank">{{
                anexo.arquivo.nome
              }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
