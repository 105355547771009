<header class="app-header navbar">
  <button
    class="navbar-toggler d-lg-none"
    type="button"
    appMobileSidebarToggler
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"> </a>
  <button
    class="navbar-toggler d-md-down-none mr-auto"
    type="button"
    appSidebarToggler
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <ul class="nav navbar-nav ml-auto" style="padding-right: 20px">
    <li class="nav-item dropdown px-3 d-none d-md-block" dropdown>
      <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="toggleDropdown($event)"
      >
        <span class="img-user">{{
          user.username | shorten : 1 : "" | uppercase
        }}</span
        >&nbsp;
        <span class="hidden-md-down">{{ user.username }}</span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header text-center"><strong>USUÁRIO</strong></div>
        <!-- <a class="dropdown-item" href="#" [routerLink]="['/my-account']"><i class="fa fa-user"></i> Conta</a>
        <div class="divider"></div> -->
        <a class="dropdown-item" href="#" (click)="logout()"
          ><i class="icon-logout"></i> Sair</a
        >
      </div>
    </li>
  </ul>
</header>
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <main class="main" style="position: relative">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumbs></app-breadcrumbs>
    </ol>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.conainer-fluid -->
  </main>
</div>
