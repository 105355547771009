import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Modalidade } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-modalidade',
  templateUrl: './modalidade.component.html',
  styleUrls: ['./modalidade.component.scss'],
})
export class ModalidadeComponent implements OnInit {
  modalidades: Modalidade[] = [];
  backupmodalidades: Modalidade[] = [];
  loading = false;

  newModalidade: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getModalidade();
  }

  getModalidade() {
    this.loading = true;
    this.restAngular
      .one('modalidade')
      .get()
      .subscribe(
        (response: Response<Modalidade[]>) => {
          this.modalidades = response.data;
          this.backupmodalidades = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify(
            'error',
            'Não foi possível carregar os modalidades'
          );
          this.loading = false;
        }
      );
  }

  delete(modalidadeId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir a modalidade?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('modalidade', modalidadeId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify(
                  'success',
                  'modalidade excluído com sucesso'
                );
                this.getModalidade();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o modalidade'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Modalidade): boolean {
    return (
      item.descricao !==
        this.backupmodalidades.find((x) => x.modalidadeId == item.modalidadeId)
          .descricao && item.descricao !== ''
    );
  }

  saveNewModalidade() {
    this.restAngular
      .all('modalidade')
      .post({ descricao: this.newModalidade })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'modalidade cadastrado com sucesso');
          this.getModalidade();
          this.newModalidade = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o modalidade');
        }
      );
  }

  editItem(item: Modalidade) {
    this.restAngular
      .one('modalidade')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'modalidade editado com sucesso');
          this.getModalidade();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o modalidade');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
