<form [formGroup]="formulario" (ngSubmit)="onSubmit()" *ngIf="formulario">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between"
          >
            <div><i class="fa fa-building"></i>&nbsp;Dados do Imóvel</div>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="form-group col-4">
                <label for="titulo">Titulo</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('titulo')"
                  id="titulo"
                  placeholder="Titulo"
                  formControlName="titulo"
                />
              </div>
              <div class="form-group col-4">
                <label for="numeroProcesso">Numero Processo</label>
                <input
                  type="text"
                  class="form-control"
                  id="numeroProcesso"
                  placeholder="Numero Processo"
                  formControlName="numeroProcesso"
                />
              </div>
              <div class="form-group col-4">
                <label for="googleMaps">Link Google Maps </label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('linkMaps')"
                  id="googleMaps"
                  placeholder="Google Maps Url"
                  formControlName="linkMaps"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label for="modalidade">Modalidade</label>
                <select class="form-control"
                  name="modalidade"
                  id="modalidade"
                  formControlName="modalidadeId"
                >
                  <option [value]="item.modalidadeId" *ngFor="let item of modalidade" [ngClass]="aplicaCssErro('modalidadeId')"> {{ item.descricao }}</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label for="tipo">Tipo</label>
                <select class="form-control"
                  name="tipo"
                  id="tipo"
                  formControlName="tipoId"
                >
                  <option [value]="item.tipoId" *ngFor="let item of tipo" [ngClass]="aplicaCssErro('tipoId')"> {{ item.descricao }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label for="endereco">Endereço</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('endereco')"
                  id="endereco"
                  formControlName="endereco"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label for="dataPrimeiraPraca">Data 1ª Praca</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('dataPrimeiraPraca')"
                  id="dataPrimeiraPraca"
                  formControlName="dataPrimeiraPraca"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY HH:mm'
                  }"
                  bsDatepicker
                />
              </div>
              <div class="form-group col-6">
                <label for="valorPrimeiraPraca">Valor 1ª Praca</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('valorPrimeiraPraca')"
                  id="valorPrimeiraPraca"
                  formControlName="valorPrimeiraPraca"
                  currencyMask
                  [options]="{
                    prefix: 'R$ ',
                    thousands: '.',
                    decimal: ','
                  }"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label for="dataSegundaPraca">Data 2ª Praca</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('dataSegundaPraca')"
                  id="dataSegundaPraca"
                  formControlName="dataSegundaPraca"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY HH:mm'
                  }"
                  bsDatepicker
                />
              </div>
              <div class="form-group col-6">
                <label for="valorSegundaPraca">Valor 2ª Praca</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('valorSegundaPraca')"
                  id="valorSegundaPraca"
                  formControlName="valorSegundaPraca"
                  currencyMask
                  [options]="{
                    prefix: 'R$ ',
                    thousands: '.',
                    decimal: ','
                  }"
                />
              </div>
            </div>
            <!-- <div class="row">
              <div class="form-group col-6">
                <label for="edital">Edital </label>
                <input
                  #inputEdtial
                  type="file"
                  id="googleMaps"
                  (change)="editalChangeEvent($event)"
                />
                <p>
                  <a href="{{ formulario.value.edital }}" target="_blank"
                    >Edital</a
                  >
                  &nbsp;
                  <a (click)="removeEdital()" class="pointer"
                    ><i class="far fa-edit"></i
                  ></a>
                </p>
              </div>
            </div> -->
            <div class="row" [ngClass]="aplicaCssErro('descricao')">
              <div class="col-12">
                <label for="editor1">Descrição</label>
                <angular-editor
                  id="editor1"
                  formControlName="descricao"
                  [config]="editorConfig"
                >
                </angular-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" formArrayName="fotos">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header new-campo">
            <div class="cabecalho d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-card-image"
                viewBox="0 0 16 16"
              >
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path
                  d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"
                />
              </svg>
              &nbsp;Fotos
            </div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="alterarFoto(-1)"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;Nova Foto
            </button>
            <input
              type="file"
              multiple
              #inputFotos
              (change)="fileChangeEvent($event)"
            />
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.fotos.length > 0"
          >
            <div class="row">
              <div
                class="form-group col-3"
                *ngFor="let item of this.formulario.value.fotos; let i = index"
              >
                <div class="img-space">
                  <img [src]="item" class="img-responsive" />
                </div>
                <div class="foto-buttons">
                  <label
                    (click)="deleteFoto(i)"
                    class="btn btn-danger labelRemove"
                    ><i class="fa fa-trash"></i> Remover</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-block"
            *ngIf="this.formulario.value.fotos.length == 0"
          >
            <alert type="danger">
              <strong>Campo Obrigatório</strong> É necessário no mínimo uma foto
            </alert>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div><i class="fas fa-map-marker-alt"></i>&nbsp; Localidade</div>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="form-group col-4">
                <label for="estadoId">Estado </label>
                <ng-select
                  [multiple]="false"
                  formControlName="estadoId"
                  (change)="onChangeEstado()"
                  [items]="estados"
                  bindValue="estadoId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
              <div class="form-group col-4">
                <label for="municipioId">Municipio </label>
                <ng-select
                  [multiple]="false"
                  formControlName="municipioId"
                  (change)="onChangeMunicipio()"
                  [items]="municipios"
                  bindValue="municipioId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
              <div class="form-group col-4">
                <label for="titulo">Bairro</label>
                <ng-select
                  [multiple]="false"
                  formControlName="bairroId"
                  [items]="bairros"
                  bindValue="bairroId"
                  bindLabel="nome"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-primary mr-3"
              [disabled]="!formulario.valid"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              routerLink="/analise-imovel"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
