<div class="p-4 animated fadeIn">
  <div class="row mb-4">
    <div class="col-6">
      <small class="text-muted">Referência: {{ imovel?.imovelId }}</small>
      <h3>Análise Jurídica</h3>
      <div>
        <span class="badge badge-danger" *ngIf="parecerPositivo == false">
          <small>PARECER NEGATIVO</small>
        </span>
        <span class="badge badge-success" *ngIf="parecerPositivo == true">
          <small>PARECER POSITIVO</small>
        </span>
      </div>

      <!-- <div
      class="col-6 d-flex justify-content-end"
      *ngIf="parecerPositivo === null"
    >
      <button class="btn btn-primary">
        <i class="fas fa-angle-double-right"></i> Concluir como não realizada
      </button>
    </div> -->
    </div>
    <div class="col-6 text-right" *ngIf="imovel.parecerArquivo">
      <a
        class="btn btn-primary"
        [href]="imovel.parecerArquivo.url"
        target="_blank"
      >
        <i class="fas fa-print"></i> Imprimir Parecer
      </a>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let campo of campos" class="col-4">
      <div [ngSwitch]="campo.tipo">
        <div *ngSwitchCase="'BOOL'" class="form-group">
          <label>{{ campo.label }}:</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [name]="'bool-' + campo.checkListCampoId"
              [value]="'true'"
              [(ngModel)]="campo.valor"
              (blur)="onValorChange(campo)"
              [disabled]="parecerPositivo != null"
              id="bool-sim-{{ campo.checkListCampoId }}"
            />
            <label
              class="form-check-label"
              for="bool-sim-{{ campo.checkListCampoId }}"
            >
              Sim
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [name]="'bool-' + campo.checkListCampoId"
              [value]="'false'"
              [(ngModel)]="campo.valor"
              [disabled]="parecerPositivo != null"
              (blur)="onValorChange(campo)"
              id="bool-nao-{{ campo.checkListCampoId }}"
            />
            <label
              class="form-check-label"
              for="bool-nao-{{ campo.checkListCampoId }}"
            >
              Não
            </label>
          </div>
        </div>

        <div *ngSwitchCase="'TEXTO'" class="form-group">
          <label for="texto-{{ campo.checkListCampoId }}"
            >{{ campo.label }}:</label
          >
          <textarea
            type="text"
            class="form-control col"
            id="texto-{{ campo.checkListCampoId }}"
            [(ngModel)]="campo.valor"
            [disabled]="parecerPositivo != null"
            (blur)="onValorChange(campo)"
            rows="4"
          ></textarea>
        </div>

        <div *ngSwitchDefault>
          <p class="text-danger">
            Tipo de campo não suportado: {{ campo.tipo }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="border-top pt-3 d-flex justify-content-between"
    *ngIf="parecerPositivo == null"
  >
    <button
      class="btn btn-success mr-2"
      (click)="concluirParecer(true)"
      *ngIf="!salvandoPositivo"
    >
      <i class="far fa-check-circle"></i> Parecer Positivo
    </button>
    <button class="btn btn-success mr-2" *ngIf="salvandoPositivo" disabled>
      <i class="fa fa-spin fa-spinner"></i> Salvando...
    </button>

    <button
      class="btn btn-danger"
      (click)="concluirParecer(false)"
      *ngIf="!salvandoNegativo"
    >
      <i class="far fa-times-circle"></i> Parecer Negativo
    </button>
    <button class="btn btn-danger" *ngIf="salvandoNegativo" disabled>
      <i class="fa fa-spin fa-spinner"></i> Salvando...
    </button>
  </div>
</div>
