var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Bairro, Contrato, Estado, Imovel, Oportunidade, Municipio, Response, Status, } from '../_models';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
var ContratoComponent = /** @class */ (function () {
    function ContratoComponent(modalService, formBuilder, restAngular, notifier, router, confimation) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.restAngular = restAngular;
        this.notifier = notifier;
        this.router = router;
        this.confimation = confimation;
        this.contratos = [];
        this.loading = true;
        this.pesquisa = '';
        this.status = '1';
        this.intensoesCadastradas = [];
        this.intencoes = [];
        this.statusOportunidade = [];
        this.fileName = 'Escolha o arquivo';
        this.enviandoContrato = false;
        this.enviandoParaAssinatura = [];
        this.showDetails = function (item) {
            _this.router.navigate(['oportunidade', item.contratoId], {
                queryParams: {
                    imovelId: item.imovelId,
                    oportunidadeId: item.intencaoCompraId,
                },
            });
        };
    }
    ContratoComponent.prototype.onChangeFilter = function () {
        this.contratos = [];
        this.carregarContratos();
    };
    ContratoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onChangeFilter();
        this.formulario = this.formBuilder.group({
            pesquisa: [''],
            estadoId: [null],
            municipioId: [null],
            bairroId: [null],
        });
        this.formularioIntencao = this.formBuilder.group({
            statusId: [null],
            observacao: [''],
        });
        this.restAngular
            .one('localidade/estado')
            .get()
            .subscribe(function (res) { return (_this.estados = res.data); });
        this.restAngular
            .one('intencaoCompra/status')
            .get()
            .subscribe(function (res) { return (_this.statusOportunidade = res.data); });
        this.formulario.get('bairroId').disable();
        this.formulario.get('municipioId').disable();
    };
    ContratoComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.formulario.get('municipioId').disable();
        if (this.formulario.value.estadoId === null) {
            this.formulario.get('municipioId').disable();
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restAngular
            .one('localidade/municipio')
            .get({ estadoId: this.formulario.value.estadoId })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.formulario.get('municipioId').enable();
        });
    };
    ContratoComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipioId = this.formulario.value.municipioId;
        if (municipioId === null) {
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restAngular
            .one('localidade/bairro')
            .get({ municipioId: municipioId })
            .subscribe(function (bairros) {
            _this.bairros = bairros.data;
            _this.formulario.get('bairroId').enable();
        });
    };
    ContratoComponent.prototype.editarContrato = function (id) {
        this.router.navigate(['/update-contrato', id]);
    };
    ContratoComponent.prototype.novoContrato = function () {
        this.router.navigate(['/create-contrato']);
    };
    ContratoComponent.prototype.cancelarContrato = function (id) {
        var _this = this;
        this.confimation
            .create('Atenção', 'Deseja realmente cancelar esse contrato?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restAngular
                    .one('contrato/cancelar/', id)
                    .customPUT()
                    .subscribe(function () {
                    _this.notifier.notify('success', 'Contrato cancelado com sucesso');
                    _this.carregarContratos();
                }, function () {
                    _this.notifier.notify('error', 'Erro ao cancelar contrato');
                });
            }
        });
    };
    ContratoComponent.prototype.carregarContratos = function () {
        var _this = this;
        this.loading = true;
        if (this.status == undefined)
            this.status = '';
        this.restAngular
            .one('contrato')
            .get({ nome: this.pesquisa, status: this.status })
            .subscribe(function (response) {
            _this.contratos = response.data.map(function (contrato) {
                return __assign({}, contrato, { status: _this.definirStatus(contrato.statusVigencia, contrato.cancelado), showIntencoes: false });
            });
            _this.loading = false;
        }, function () {
            _this.notifier.notify('error', 'Erro ao carregar contratos');
            _this.loading = false;
        });
    };
    ContratoComponent.prototype.carregarImoveis = function () {
        var _this = this;
        var estadoId = this.formulario.value.estadoId
            ? this.formulario.value.estadoId
            : 0;
        var municipioId = this.formulario.value.municipioId
            ? this.formulario.value.municipioId
            : 0;
        var bairroId = this.formulario.value.bairroId
            ? this.formulario.value.bairroId
            : 0;
        var pesquisa = this.formulario.value.pesquisa
            ? this.formulario.value.pesquisa
            : '';
        this.restAngular
            .one('imovel/interesse')
            .get({ estadoId: estadoId, municipioId: municipioId, bairroId: bairroId, pesquisa: pesquisa })
            .subscribe(function (response) {
            _this.imoveis = response.data;
        }, function () {
            _this.notifier.notify('error', 'Erro ao carregar imóveis');
        });
    };
    ContratoComponent.prototype.openModal = function (template, contrato) {
        this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
        this.contratoSelecionado = contrato;
    };
    ContratoComponent.prototype.visualizarOportunidades = function (contrato) {
        var _this = this;
        contrato.showIntencoes = true;
        this.contratoSelecionado = contrato;
        this.restAngular
            .one("intencaoCompra/contrato/" + contrato.contratoId + "/detalhado")
            .get()
            .subscribe(function (res) {
            _this.intencoes = res.data;
            _this.intencoes.forEach(function (intencao) {
                intencao.showDetail = false;
            });
        });
    };
    ContratoComponent.prototype.verStatusAssinatura = function (template, contratoId) {
        var _this = this;
        this.restAngular
            .one("contrato/" + contratoId)
            .get()
            .subscribe(function (res) {
            _this.detalhesAssinatura = res.data;
            _this.modalRef = _this.modalService.show(template, { class: 'modal-xl' });
        });
    };
    ContratoComponent.prototype.salvarIntencao = function (intencao) {
        var _this = this;
        var body = {
            statusId: this.formularioIntencao.value.statusId,
            observacao: this.formularioIntencao.value.observacao,
            oportunidadeId: intencao.intencaoCompraId,
        };
        this.restAngular
            .all('intencaoCompra/atualizar')
            .post(body)
            .subscribe(function () {
            _this.notifier.notify('success', 'Intenção de compra atualizada com sucesso');
            _this.modalService.hide(1);
            _this.formularioIntencao.reset();
        }, function () {
            _this.notifier.notify('error', 'Erro ao atualizar oportunidade');
        });
    };
    ContratoComponent.prototype.hasOportunidade = function (imovelId) {
        return this.intensoesCadastradas.some(function (intencao) { return intencao.imovelId === imovelId; });
    };
    ContratoComponent.prototype.adicionarOportunidade = function (imovelId) {
        var _this = this;
        var body = {
            imovelId: imovelId,
            contratoId: this.contratoSelecionado.contratoId,
            usuarioId: 0,
        };
        this.restAngular
            .all('intencaoCompra')
            .post(body)
            .subscribe(function () {
            _this.notifier.notify('success', 'Intenção de compra adicionada com sucesso');
            _this.modalService.hide(1);
        }, function (e) {
            if (e.data.Message.includes('já foi adicionado')) {
                _this.notifier.notify('error', e.data.Message);
            }
            else {
                _this.notifier.notify('error', 'Erro ao adicionar oportunidade');
            }
        });
    };
    ContratoComponent.prototype.definirStatus = function (vigencia, cancelado) {
        if (cancelado) {
            return { descricao: 'Cancelado', classe: 'badge badge-danger' };
        }
        else if (!vigencia) {
            return { descricao: 'Inativo', classe: 'badge badge-danger' };
        }
        else {
            return { descricao: 'Ativo', classe: 'badge badge-success' };
        }
    };
    ContratoComponent.prototype.visualizarContrato = function (id) {
        this.restAngular
            .one('contrato/gerar/', id)
            .withHttpConfig({ responseType: 'blob' })
            .get()
            .subscribe(function (fileBlob) {
            var file = new Blob([fileBlob], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    };
    ContratoComponent.prototype.onFileSelected = function (event) {
        this.selectedFile = event.target.files[0];
        if (this.selectedFile) {
            this.fileName = this.selectedFile.name;
        }
        else {
            this.fileName = 'Escolha o arquivo';
        }
    };
    ContratoComponent.prototype.onUpload = function () {
        var _this = this;
        if (this.selectedFile) {
            this.enviandoContrato = true;
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                var base64 = reader_1.result;
                var arquivo = {
                    Nome: _this.selectedFile.name,
                    Tipo: _this.selectedFile.type,
                    Tamanho: _this.selectedFile.size,
                    Base64: base64,
                };
                _this.restAngular
                    .all('contrato/uploadContratoAssinado')
                    .post({
                    arquivo: arquivo,
                    contratoId: _this.contratoSelecionado.contratoId,
                })
                    .subscribe(function (response) {
                    _this.notifier.notify('success', 'upload realizado com sucesso');
                    _this.carregarContratos();
                    _this.modalRef.hide();
                    _this.enviandoContrato = false;
                }, function (error) {
                    _this.notifier.notify('error', 'erro ao fazer upload do arquivo');
                    _this.enviandoContrato = false;
                });
            };
            reader_1.readAsDataURL(this.selectedFile);
        }
        else {
            console.error('No file selected');
        }
    };
    ContratoComponent.prototype.downloadContrato = function (arquivo) {
        this.restAngular
            .one('arquivo', arquivo.arquivoId)
            .one('download')
            .get()
            .subscribe(function (res) {
            window.open(res.data.urlArquivo, '_blank');
        }, function (err) {
            console.error('Erro ao baixar o arquivo', err);
        });
    };
    ContratoComponent.prototype.getStatusClass = function () {
        switch (this.detalhesAssinatura.statusAssinatura) {
            case 0:
                return 'text-warning'; // Aguardando
            case 1:
                return 'text-success'; // Finalizado
            case 2:
                return 'text-danger'; // Cancelado
            default:
                return '';
        }
    };
    ContratoComponent.prototype.verificarEnvio = function (contratoId) {
        return this.enviandoParaAssinatura.some(function (x) { return x == contratoId; });
    };
    ContratoComponent.prototype.enviarAssinatura = function (contratoId) {
        var _this = this;
        this.confimation
            .create('Atenção', 'Deseja realmente enviar esse contrato para assinatura?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.enviandoParaAssinatura.push(contratoId);
                _this.restAngular
                    .all('contrato/enviarAssinatura')
                    .post({ contratoId: contratoId })
                    .subscribe(function (res) {
                    _this.notifier.notify('success', 'Enviado para assinatura com sucesso!');
                    _this.enviandoParaAssinatura.splice(_this.enviandoParaAssinatura.indexOf(contratoId), 1);
                    _this.carregarContratos();
                }, function (err) {
                    _this.enviandoParaAssinatura.splice(_this.enviandoParaAssinatura.indexOf(contratoId), 1);
                    _this.notifier.notify('error', 'Erro ao enviar para assinatura');
                });
            }
        });
    };
    return ContratoComponent;
}());
export { ContratoComponent };
