<div class="card animated fadeIn">
  <div class="card-header">
    <p class="card-title d-inline-block">
      <i class="fas fa-file-contract"></i>
      &nbsp; Contratos
    </p>
    <button (click)="novoContrato()" class="btn btn-success float-right">
      <i class="fa fa-plus-circle"></i>
      Novo contrato
    </button>
  </div>
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-6 text-muted">
        Encontrados {{ contratos.length }} Contratos
      </div>
      <div class="input-group col-4">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Cliente
          </span>
        </div>
        <input
          [(ngModel)]="pesquisa"
          (ngModelChange)="onChangeFilter()"
          type="text"
          class="form-control"
          placeholder="Nome do Cliente"
        />
      </div>
      <div class="input-group col-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Status
          </span>
        </div>
        <select
          [(ngModel)]="status"
          (change)="onChangeFilter()"
          class="form-control"
        >
          <option value="1">ATIVO</option>
          <option value="2">INATIVO</option>
          <option value>TODOS</option>
        </select>
      </div>
    </div>
    <div>
      <table
        #mfTable="mfDataTable"
        [mfData]="contratos"
        [mfRowsOnPage]="10"
        class="table table-striped w-100"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="contratoId">Numero</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="clinete.nome">Cliente</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="cliente.telefone">Telefone</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="dataAssinatura">Assinatura</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="ativo">Status</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="tipoContrato"
                >Tipo de Contrato</mfDefaultSorter
              >
            </th>
            <th>
              <mfDefaultSorter by="dataCadastro">Data Cadastro</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td class="text-center text-muted" colspan="8">
              <h5>
                <i class="fa fa-spin fa-spinner"></i>
                &nbsp;Carregando...
              </h5>
            </td>
          </tr>
          <tr *ngIf="!loading && contratos.length === 0">
            <td class="text-center text-muted" colspan="8">
              <h5>Nenhum registro encontrado.</h5>
            </td>
          </tr>
          <ng-container *ngFor="let contrato of mfTable.data">
            <tr>
              <td>
                {{ contrato.contratoId }}
              </td>
              <td>
                {{ contrato.cliente.nome }}
              </td>
              <td>
                {{ contrato.cliente.telefone }}
              </td>
              <td>
                {{
                  contrato.dataAssinatura
                    | amUtc
                    | amLocal
                    | amDateFormat : "DD/MM/yyyy"
                }}
              </td>
              <td>
                <span class="badge badge-success" *ngIf="contrato.ativo"
                  >ATIVO</span
                >
                <span class="badge badge-danger" *ngIf="!contrato.ativo"
                  >INATIVO</span
                >
              </td>
              <td>
                {{ contrato.tipoContrato == 1 ? "MANUAL" : "DIGITAL" }}
              </td>
              <td>
                {{
                  contrato.dataCadastro
                    | amUtc
                    | amLocal
                    | amDateFormat : "DD/MM/yyyy"
                }}
              </td>
              <td class="text-center" nowrap>
                <a
                  (click)="editarContrato(contrato.contratoId)"
                  tooltip="Editar"
                  class="mr-1 pointer"
                >
                  <i class="fa-lg far fa-edit"></i>
                </a>

                <a
                  *ngIf="
                    contrato.ativo &&
                    contrato.tipoContrato == 1 &&
                    contrato.arquivoAssinado == null &&
                    contrato.arquivoAssinado == undefined
                  "
                  (click)="visualizarContrato(contrato.contratoId)"
                  tooltip="Imprimir Contrato"
                  class="mr-1 pointer"
                >
                  <i class="fa-lg fas fa-print"></i>
                </a>

                <a
                  *ngIf="
                    contrato.ativo &&
                    contrato.tipoContrato == 1 &&
                    contrato.arquivoAssinado == null &&
                    contrato.arquivoAssinado == undefined
                  "
                  (click)="openModal(uploadContratoAssinado, contrato)"
                  tooltip="Upload documento assinado"
                  class="mr-1 pointer"
                >
                  <i class="fas fa-upload"></i>
                </a>

                <a
                  *ngIf="
                    contrato.ativo &&
                    contrato.tipoContrato == 1 &&
                    contrato.arquivoAssinado != undefined
                  "
                  (click)="downloadContrato(contrato.arquivoAssinado)"
                  tooltip="Download documento assinado"
                  class="mr-1 pointer"
                >
                  <i class="fas fa-download"></i>
                </a>

                <a
                  *ngIf="
                    contrato.ativo &&
                    contrato.tipoContrato == 2 &&
                    contrato.referenciaId
                  "
                  (click)="
                    verStatusAssinatura(statusAssinatura, contrato.contratoId)
                  "
                  tooltip="Ver status da assinatura"
                  class="mr-1 pointer"
                >
                  <i class="fas fa-signature"></i>
                </a>

                <a
                  *ngIf="
                    contrato.ativo &&
                    contrato.tipoContrato == 2 &&
                    !contrato.referenciaId &&
                    !verificarEnvio(contrato.contratoId)
                  "
                  (click)="enviarAssinatura(contrato.contratoId)"
                  tooltip="Enviar para assinatura"
                  class="mr-1 pointer"
                >
                  <i class="fas fa-file-upload"></i>
                </a>

                <a *ngIf="verificarEnvio(contrato.contratoId)">
                  <i class="fa fa-spin fa-spinner"></i>
                </a>

                <a
                  [routerLink]="['/oportunidade/' + contrato.contratoId]"
                  tooltip="Adicionar Oportunidade"
                  class="mr-1 pointer"
                  *ngIf="contrato.ativo"
                >
                  <i class="fa-lg fas fa-shopping-cart"></i>
                </a>
                <a
                  (click)="visualizarOportunidades(contrato)"
                  tooltip="Vizualizar Oportunidades"
                  class="mr-1 pointer"
                  *ngIf="contrato.ativo"
                >
                  <i class="fa-lg fas fa-eye"></i>
                </a>
                <a
                  (click)="cancelarContrato(contrato.contratoId)"
                  tooltip="Cancelar Contrato"
                  class="mr-1 pointer"
                  *ngIf="contrato.ativo"
                >
                  <i class="fa-lg far fa-times-circle"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="contrato.showIntencoes">
              <td colspan="8">
                <h5
                  *ngIf="intencoes == null || intencoes.length == 0"
                  class="text-center"
                >
                  Sem Imóveis adicionados!
                </h5>
                <div class="row">
                  <div class="col-12">
                    <table
                      class="table table-sm table-bordered w-100"
                      *ngIf="intencoes && intencoes.length >= 0"
                    >
                      <thead class="thead-light">
                        <tr>
                          <th width="5%">Referência</th>
                          <th width="25%">Título</th>
                          <th width="25%">Bairro</th>
                          <th width="15%">1ª Praça</th>
                          <th width="15%">2ª Praça</th>
                          <th width="10%">Status</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let intencao of intencoes" class="tr-light">
                          <td>{{ intencao.imovel.imovelId }}</td>
                          <td>
                            <a
                              [routerLink]="[
                                '/update-imovel/' + intencao.imovel.imovelId
                              ]"
                              title="Visualizar Imóvel"
                              target="_blank"
                            >
                              {{ intencao.imovel.titulo | uppercase }}</a
                            >
                          </td>
                          <td>
                            <span *ngIf="intencao.imovel.bairro">
                              {{ intencao.imovel.bairro?.nome }} -
                              {{ intencao.imovel.bairro?.municipio?.nome }} -
                              {{
                                intencao.imovel.bairro?.municipio?.estado?.sigla
                              }}
                            </span>
                          </td>
                          <td>
                            <span *ngIf="intencao.imovel.pracas[0]">
                              {{
                                intencao.imovel.pracas[0].praca.data
                                  | amUtc
                                  | amLocal
                                  | amDateFormat : "DD/MM/yyyy"
                              }}<br />
                              {{
                                intencao.imovel.pracas[0].praca.valor
                                  | currency : "BRL" : "symbol" : "1.2-2"
                              }}
                            </span>
                          </td>
                          <td>
                            <span *ngIf="intencao.imovel.pracas[1]">
                              {{
                                intencao.imovel.pracas[1].praca.data
                                  | amUtc
                                  | amLocal
                                  | amDateFormat : "DD/MM/yyyy"
                              }}<br />
                              {{
                                intencao.imovel.pracas[1].praca.valor
                                  | currency : "BRL" : "symbol" : "1.2-2"
                              }}
                            </span>
                          </td>
                          <td>{{ intencao.status.descricao }}</td>
                          <td class="text-center">
                            <a
                              (click)="showDetails(intencao)"
                              tooltip="Editar Oportunidade"
                              class="text-info pointer"
                            >
                              <i class="fas fa-edit"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="8">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- Modal visualizar intençoes -->
<ng-template #visualizarIntencao>
  <div class="modal-header">
    <h5 class="modal-title">
      Intenções de Compra - Contrato: {{ contratoSelecionado.contratoId }}
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 *ngIf="intencoes.length == 0 || intencoes == null" class="text-center">
      Sem Imóveis adicionados!
    </h5>
    <div *ngFor="let intencao of intencoes" class="card imovel">
      <div class="card-body">
        <a
          (click)="showDetails(intencao)"
          class="edit-intencao"
          tooltip="Editar Movimentação"
        >
          <i class="fas fa-edit"></i>
        </a>
        <div class="titulo-intencao">
          <h4>
            {{ intencao.status.descricao }}
          </h4>
        </div>
        <div class="row">
          <img
            class="card-img col-4"
            src="{{ intencao.imovel.fotos[0].arquivo.url }}"
            alt
          />
          <div class="col-8">
            <div class="titulo">
              <div>
                <h5 class="card-title mr-2">
                  {{ intencao.imovel.titulo }}
                </h5>
                <p class="card-text">
                  {{ intencao.imovel.bairro?.nome }} -
                  {{ intencao.imovel.bairro?.municipio?.nome }} -
                  {{ intencao.imovel.bairro?.municipio?.estado?.sigla }}
                </p>
              </div>
              <h5 class="card-title">
                {{ intencao.imovel.tipo.descricao }}
              </h5>
            </div>
            <p
              class="descricao mt-3"
              [innerHTML]="intencao.imovel.descricao"
            ></p>
            <div>
              <div *ngIf="intencao.imovel.pracas[0]">
                <h5 class="d-inline mr-2">
                  1ª Praça
                  {{
                    intencao.imovel.pracas[0].praca.data
                      | amUtc
                      | amLocal
                      | amDateFormat : "DD/MM"
                  }}
                </h5>
                <span>
                  {{
                    intencao.imovel.pracas[0].praca.valor
                      | currency : "BRL" : "symbol" : "1.2-2"
                  }}
                </span>
              </div>
              <div *ngIf="intencao.imovel.pracas[1]">
                <h5 class="d-inline mr-2">
                  2ª Praça
                  {{
                    intencao.imovel.pracas[1].praca.data
                      | amUtc
                      | amLocal
                      | amDateFormat : "DD/MM"
                  }}
                </h5>
                <span>
                  {{
                    intencao.imovel.pracas[1].praca.valor
                      | currency : "BRL" : "symbol" : "1.2-2"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Visualizar Status Documento Assinatura -->
<ng-template #statusAssinatura>
  <div class="modal-header">
    <h5 class="modal-title" id="statusModalLabel">Status Assinatura Digital</h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong> Data da Geração do Documento: </strong>
      {{
        detalhesAssinatura.dataCadastro
          | amUtc
          | amLocal
          | amDateFormat : "DD/MM/yyyy"
      }}
    </p>
    <p *ngIf="detalhesAssinatura.statusAssinatura == 1">
      <strong>Data da Assinatura:</strong>
      {{
        detalhesAssinatura.dataAssinatura
          | amUtc
          | amLocal
          | amDateFormat : "DD/MM/yyyy"
      }}
    </p>
    <p>
      <strong> Status da Assinatura digital: </strong>
      <span [ngClass]="getStatusClass()">
        {{
          detalhesAssinatura.statusAssinatura === 0
            ? "Aguardando"
            : detalhesAssinatura.statusAssinatura === 1
            ? "Finalizado"
            : "Cancelado"
        }}
      </span>
    </p>
    <p>
      <a
        *ngIf="detalhesAssinatura.statusAssinatura === 1"
        href="{{ detalhesAssinatura.arquivoAssinado.url }}"
        target="_blank"
        class="btn btn-primary"
      >
        <i class="fas fa-download"></i>
        Download do Contrato Assinado
      </a>
    </p>
  </div>
</ng-template>

<!-- Modal upload contrato assinado -->
<ng-template #uploadContratoAssinado>
  <div class="modal-header">
    <h5 class="modal-title" id="statusModalLabel">Upload contrato assinado</h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="contractFile">Selecione o arquivo do contrato</label>
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="contractFile"
            (change)="onFileSelected($event)"
          />
          <label class="custom-file-label" for="contractFile">{{
            fileName
          }}</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onUpload()"
      *ngIf="!enviandoContrato"
    >
      Enviar
    </button>
    <button type="button" class="btn btn-primary" *ngIf="enviandoContrato">
      <i class="fa fa-spin fa-spinner"></i> Enviando...
    </button>
  </div>
</ng-template>
