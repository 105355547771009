/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./back-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./back-button.component";
var styles_BackButtonComponent = [i0.styles];
var RenderType_BackButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackButtonComponent, data: {} });
export { RenderType_BackButtonComponent as RenderType_BackButtonComponent };
export function View_BackButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "reset"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Voltar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.route; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BackButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-button", [], null, null, null, View_BackButtonComponent_0, RenderType_BackButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.BackButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackButtonComponentNgFactory = i1.ɵccf("app-back-button", i3.BackButtonComponent, View_BackButtonComponent_Host_0, { route: "route" }, {}, []);
export { BackButtonComponentNgFactory as BackButtonComponentNgFactory };
