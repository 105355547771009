<div
  class="card animated fadeIn"
  *ngIf="campanhaForm"
  [formGroup]="campanhaForm"
>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fas fa-info-circle"></i>&nbsp; Informações da Campanha
          </div>
          <div class="card-block">
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-muted">
                  <i class="fa fa-info-circle"></i> Informe o titulo da campanha
                  e selecione por qual plataforma a campanha será enviada.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-8">
                <label for="titulo">Título</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="titulo"
                  placeholder="Título da campanha"
                  [ngClass]="aplicaCssErro('titulo')"
                />
              </div>
              <div class="form-group col-4">
                <label for="plataformas">Plataformas</label>
                <ng-select
                  style="flex: 1"
                  [multiple]="true"
                  placeholder="Plataformas"
                  [items]="plataforma"
                  id="plataformas"
                  formControlName="meioNotificacao"
                  [ngClass]="aplicaCssErro('meioNotificacao')"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="verificarPlataforma('Email')">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-code"></i>&nbsp; Template de Email
            <button
              class="btn btn-sm btn-success float-right"
              [routerLink]="['/create-template']"
              routerLinkActive="router-link-active"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;Novo Template
            </button>
          </div>
          <div class="card-block">
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-muted">
                  <i class="fa fa-info-circle"></i> Informe qual template será
                  utilizado.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ng-select
                  style="flex: 1"
                  formControlName="templateId"
                  [items]="templates"
                  id="templateId"
                  bindValue="templateNotificacaoId"
                  bindLabel="descricao"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <div>
              <i class="fas fa-home"></i>
              &nbsp;Imoveis
            </div>
            <div
              class="text-muted"
              *ngIf="!loadingImoveis && imoveis && imoveis > 0"
            >
              <span class="badge badge-success">{{ imoveis }} </span> Imoveis
              Encontrados
            </div>
            <div class="text-muted" *ngIf="loadingImoveis">
              <i class="fa fa-spin fa-spinner"></i> Pesquisando Imoveis...
            </div>
            <div
              class="text-danger"
              *ngIf="!loadingImoveis && (!imoveis || imoveis == 0)"
            >
              <i class="fa fa-exclamation-triangle"></i> Não foram encontrados
              Imoveis!
            </div>
          </div>
          <div class="card-block" formGroupName="imovel">
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-muted">
                  <i class="fa fa-info-circle"></i> Informe os parâmetros
                  desejados para selecionar os imoveis.
                </p>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label>Período</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    #drp="bsDaterangepicker"
                    bsDaterangepicker
                    placeholder="DD/MM/YYYY - DD/MM/YYYY"
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                    formControlName="data"
                  />
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <div class="col-4">
                <label for="estadoId">Estado</label>
                <div class="input-group">
                  <select
                    class="form-control"
                    formControlName="estado"
                    (change)="onChangeEstadoImovel()"
                    id="estadoId"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let estado of estados"
                      [value]="estado.estadoId"
                    >
                      {{ estado.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <label for="municipioId">Municipio</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    formControlName="municipio"
                    [items]="municipios"
                    (change)="onChangeMunicipioImovel()"
                    id="municipioId"
                    bindValue="municipioId"
                    bindLabel="nome"
                    multiple="true"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-4">
                <label>Bairro</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    formControlName="bairro"
                    [items]="bairros"
                    id="bairroId"
                    bindValue="bairroId"
                    bindLabel="nome"
                    [groupBy]="groupByMunicipio"
                    [groupValue]="groupValueFn"
                    [multiple]="true"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label for="faixa">Valor Inicial</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="valorInicial"
                    placeholder="R$ 0,00"
                    id="faixa"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <label for="faixa">Valor Final</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="valorFinal"
                    placeholder="R$ 0,00"
                    id="faixa"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label>Tipo Imóvel</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    [multiple]="true"
                    placeholder="Tipo de Imóvel"
                    formControlName="tipo"
                    [items]="tipoImovel"
                    id="tipoId"
                    bindValue="tipoId"
                    bindLabel="descricao"
                  >
                    <ng-template
                      ng-multi-label-tmp
                      let-items="items"
                      let-clear="clear"
                    >
                      <div
                        class="ng-value"
                        *ngFor="
                          let item of items | slice : 0 : 1;
                          trackBy: item
                        "
                      >
                        <span class="ng-value-label">
                          {{ item.descricao }}</span
                        >
                        <span
                          class="ng-value-icon right"
                          (click)="clear(item)"
                          aria-hidden="true"
                          >×</span
                        >
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label"
                          >mais {{ items.length - 1 }}...</span
                        >
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <label>Tipo Pregão</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    [multiple]="true"
                    placeholder="Pregão"
                    formControlName="pregao"
                    [items]="tipoPregao"
                    id="pregaoId"
                    bindValue="pregaoId"
                    bindLabel="descricao"
                  >
                    <ng-template
                      ng-multi-label-tmp
                      let-items="items"
                      let-clear="clear"
                    >
                      <div
                        class="ng-value"
                        *ngFor="
                          let item of items | slice : 0 : 1;
                          trackBy: item
                        "
                      >
                        <span class="ng-value-label">
                          {{ item.descricao }}</span
                        >
                        <span
                          class="ng-value-icon right"
                          (click)="clear(item)"
                          aria-hidden="true"
                          >×</span
                        >
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label"
                          >mais {{ items.length - 1 }}...</span
                        >
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label>Origem</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    [multiple]="true"
                    placeholder="Origem"
                    formControlName="origem"
                    [items]="origem"
                    id="origemId"
                    bindValue="origemId"
                    bindLabel="descricao"
                  >
                    <ng-template
                      ng-multi-label-tmp
                      let-items="items"
                      let-clear="clear"
                    >
                      <div
                        class="ng-value"
                        *ngFor="
                          let item of items | slice : 0 : 1;
                          trackBy: item
                        "
                      >
                        <span class="ng-value-label"> {{ item }}</span>
                        <span
                          class="ng-value-icon right"
                          (click)="clear(item)"
                          aria-hidden="true"
                          >×</span
                        >
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label"
                          >mais {{ items.length - 1 }}...</span
                        >
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <label>Modalidade</label>
                <div class="input-group">
                  <ng-select
                    style="flex: 1"
                    [multiple]="true"
                    placeholder="Modalidade"
                    formControlName="modalidade"
                    [items]="modalidade"
                    id="modalidadeId"
                    bindValue="modalidadeId"
                    bindLabel="descricao"
                  >
                    <ng-template
                      ng-multi-label-tmp
                      let-items="items"
                      let-clear="clear"
                    >
                      <div
                        class="ng-value"
                        *ngFor="
                          let item of items | slice : 0 : 1;
                          trackBy: item
                        "
                      >
                        <span class="ng-value-label">
                          {{ item.descricao }}</span
                        >
                        <span
                          class="ng-value-icon right"
                          (click)="clear(item)"
                          aria-hidden="true"
                          >×</span
                        >
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label"
                          >mais {{ items.length - 1 }}...</span
                        >
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <div>
              <i class="fas fa-user-circle" aria-hidden="true"></i> Clientes
            </div>
            <div
              class="text-muted"
              *ngIf="!loadingClientes && clientes && clientes > 0"
            >
              <span class="badge badge-success">{{ clientes }} </span> Clientes
              Encontrados
            </div>
            <div class="text-muted" *ngIf="loadingClientes">
              <i class="fa fa-spin fa-spinner"></i> Pesquisando Clientes...
            </div>
            <div
              class="text-danger"
              *ngIf="!loadingClientes && (!clientes || clientes == 0)"
            >
              <i class="fa fa-exclamation-triangle"></i> Não foram encontrados
              clientes!
            </div>
          </div>
          <div class="card-block" formGroupName="cliente">
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-muted">
                  <i class="fa fa-info-circle"></i> Informe os parâmetros
                  desejados para selecionar os clientes.
                </p>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6">
                <label for="contrato">Tem Contrato?</label>
                <div class="input-group">
                  <select
                    formControlName="temContrato"
                    class="form-control"
                    id="temContrato"
                  >
                    <option value="true">SIM</option>
                    <option value="false">NÃO</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <label for="temOportunidade"> Tem Oportunidade?</label>
                <div class="input-group">
                  <select
                    formControlName="temOportunidade"
                    class="form-control"
                    id="temOportunidade"
                  >
                    <option value="true">SIM</option>
                    <option value="false">NÃO</option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-4">
                <label for="preferencia">Usar Preferências?</label>
                <div class="input-group">
                  <select
                    formControlName="usarInteresses"
                    class="form-control"
                    id="preferencia"
                  >
                    <option value="true">SIM</option>
                    <option value="false">NÃO</option>
                  </select>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fas fa-calendar"></i>&nbsp;Agendamento
          </div>
          <div class="card-block">
            <p class="text-muted">
              <i class="fa fa-info-circle"></i> Configure quando a campanha será
              executada.
            </p>
            <div class="row mb-2">
              <div class="form-group col-6">
                <label for="repetir">Agendamento</label>
                <select
                  class="form-control"
                  formControlName="tipoAgenda"
                  id="repetir"
                  [ngClass]="aplicaCssErro('tipoAgenda')"
                >
                  <option value="1">Apenas uma vez</option>
                  <option value="2">Diário</option>
                  <option value="3">Semanal</option>
                  <option value="4">Mensal</option>
                </select>
              </div>
              <div
                class="form-group"
                [ngClass]="
                  campanhaForm.get('tipoAgenda').value > 1 ? 'col-3' : 'col-6'
                "
              >
                <label for="primairaData">Data de Execução</label>
                <input
                  type="text"
                  class="form-control"
                  id="primeiraData"
                  formControlName="dataExecucao"
                  placeholder="DD/MM/YYYY HH:mm"
                  bsDatepicker
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY HH:mm',
                    adaptivePosition: true
                  }"
                  [minDate]="minDate"
                  [ngClass]="aplicaCssErro('dataExecucao')"
                />
              </div>
              <div
                class="form-group col-3"
                *ngIf="campanhaForm.get('tipoAgenda').value > 1"
              >
                <label for="segundaData">Data de Encerramento</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="dataEncerramento"
                  id="segundaData"
                  placeholder="DD/MM/YYYY HH:mm"
                  bsDatepicker
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY HH:mm',
                    adaptivePosition: true
                  }"
                  [minDate]="minDate"
                />
              </div>
            </div>
            <div
              class="row mb-2"
              *ngIf="campanhaForm.controls.tipoAgenda.value == 3"
            >
              <div class="form-group col-12">
                <label>Dias da Semana</label>
                <div class="week-box">
                  <div
                    *ngFor="let weekDay of weekDays"
                    class="week-day"
                    [ngClass]="{
                      'week-day-clicked': existeParametroAgenda(weekDay)
                    }"
                    (click)="selecionaParametroAgenda(weekDay)"
                  >
                    {{ weekDay | uppercase }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="form-group col-12"
                *ngIf="campanhaForm.controls.tipoAgenda.value == 4"
              >
                <label>Meses</label>
                <div class="week-box">
                  <div
                    *ngFor="let mes of meses"
                    class="week-day"
                    [ngClass]="{
                      'week-day-clicked': existeParametroAgenda(mes)
                    }"
                    (click)="selecionaParametroAgenda(mes)"
                  >
                    {{ mes | uppercase }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button type="submit" class="btn btn-primary mr-2" (click)="onSubmit()">
      <i class="fa fa-save"></i> Salvar
    </button>
    <button type="reset" class="btn btn-danger" [routerLink]="['/campanha']">
      <i class="fa fa-undo"></i> Cancelar
    </button>
  </div>
</div>
