import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-status-intencao-compra-grupo',
  templateUrl: './status-intencao-compra-grupo.component.html',
  styleUrls: ['./status-intencao-compra-grupo.component.scss'],
})
export class StatusIntencaoCompraGrupoComponent implements OnInit {
  grupos: any[] = [];
  backupGrupos: any[] = [];
  loading = false;

  newGrupo: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getGrupo();
  }

  getGrupo() {
    this.loading = true;
    this.restAngular
      .one('GrupoStatus')
      .get()
      .subscribe(
        (response: Response<any[]>) => {
          this.grupos = response.data;
          this.backupGrupos = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os grupos');
          this.loading = false;
        }
      );
  }

  delete(id: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o grupo?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('GrupoStatus', id)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Grupo excluído com sucesso');
                this.getGrupo();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o grupo'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: any): boolean {
    return (
      item.descricao !==
        this.backupGrupos.find((x) => x.id == item.id).descricao &&
      item.descricao !== ''
    );
  }

  saveNewGrupo() {
    this.restAngular
      .all('GrupoStatus')
      .post({ descricao: this.newGrupo })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'Grupo cadastrado com sucesso');
          this.getGrupo();
          this.newGrupo = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o grupo');
        }
      );
  }

  editItem(item: any) {
    this.restAngular
      .one('GrupoStatus')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'Grupo editado com sucesso');
          this.getGrupo();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o grupo');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
