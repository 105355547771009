import {
  Component,
  Input,
  OnInit,
  forwardRef,
  EventEmitter,
  Output,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-select-insert',
  templateUrl: './select-insert.component.html',
  styleUrls: ['./select-insert.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInsertComponent),
      multi: true,
    },
  ],
})
export class SelectInsertComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() items: any[] = [];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() rota: string;
  @Input() loading: boolean = false;
  @Input() ame: string;
  @Input() entity: any;
  @Input() disabled: boolean = false;
  @Input() route: string = '';
  @Input() params: any = {};
  @Input() template = null;
  @Input() modalRef: BsModalRef;
  @Output() updateModalRef: EventEmitter<BsModalRef> = new EventEmitter<BsModalRef>();
  @Output() customChange: EventEmitter<any> = new EventEmitter<any>();

  value: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  isSpinning: boolean = false;

  constructor(
    private restangular: Restangular,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: BsModalService
  ) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // You can implement this if needed
  }

  //todo: ao escolher um estado retornar num parametro de saida os municipios

  updateValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
    this.customChange.emit(value);
  }

  refresh(element: HTMLElement) {

    if(this.disabled) return

    this.isSpinning = true;

    this.restangular
      .one(this.route)
      .get(this.params)
      .subscribe(
        (resp) => {
          this.items = resp.data;
          this.isSpinning = false;
        },
        (error) => {}
      );
  }

  openModal() {
    this.modalRef = this.modalService.show(this.template);
    this.updateModalRef.emit(this.modalRef);
  }

}
