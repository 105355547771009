/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./access-denied.component";
var styles_AccessDeniedComponent = [];
var RenderType_AccessDeniedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccessDeniedComponent, data: {} });
export { RenderType_AccessDeniedComponent as RenderType_AccessDeniedComponent };
export function View_AccessDeniedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "animated fadeIn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "card-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "mt-3 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-exclamation-circle fa-5x text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h1", [["class", "text-danger text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ACESSO NEGADO"])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "p", [["class", "text-muted text-center mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Voc\u00EA n\u00E3o tem permiss\u00E3o para acessar esta p\u00E1gina, entre em contato com o administrador do sistema."]))], null, null); }
export function View_AccessDeniedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-access-denied", [], null, null, null, View_AccessDeniedComponent_0, RenderType_AccessDeniedComponent)), i0.ɵdid(1, 114688, null, 0, i1.AccessDeniedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessDeniedComponentNgFactory = i0.ɵccf("app-access-denied", i1.AccessDeniedComponent, View_AccessDeniedComponent_Host_0, {}, {}, []);
export { AccessDeniedComponentNgFactory as AccessDeniedComponentNgFactory };
