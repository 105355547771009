/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./app-breadcrumbs.component";
var styles_AppBreadcrumbsComponent = [];
var RenderType_AppBreadcrumbsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppBreadcrumbsComponent, data: {} });
export { RenderType_AppBreadcrumbsComponent as RenderType_AppBreadcrumbsComponent };
function View_AppBreadcrumbsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.url; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.parent.context.$implicit.label.title; _ck(_v, 2, 0, currVal_3); }); }
function View_AppBreadcrumbsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.url; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.label.title; _ck(_v, 2, 0, currVal_1); }); }
function View_AppBreadcrumbsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { active: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbsComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbsComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "breadcrumb-item"; var currVal_1 = _ck(_v, 2, 0, _v.parent.context.last); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_v.parent.context.last; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.last; _ck(_v, 6, 0, currVal_3); }, null); }
function View_AppBreadcrumbsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbsComponent_2)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.label.title && (_v.context.$implicit.url.substring((_v.context.$implicit.url.length - 1)) == "/")) || (_v.context.$implicit.label.title && _v.context.last)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppBreadcrumbsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbsComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbs; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppBreadcrumbsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, View_AppBreadcrumbsComponent_0, RenderType_AppBreadcrumbsComponent)), i0.ɵdid(1, 49152, null, 0, i3.AppBreadcrumbsComponent, [i1.Router, i1.ActivatedRoute], null, null)], null, null); }
var AppBreadcrumbsComponentNgFactory = i0.ɵccf("app-breadcrumbs", i3.AppBreadcrumbsComponent, View_AppBreadcrumbsComponent_Host_0, {}, {}, []);
export { AppBreadcrumbsComponentNgFactory as AppBreadcrumbsComponentNgFactory };
