<div class="card animated fadeIn">
  <div class="card-header">
    <p class="card-title d-inline-block">
      <i class="fas fa-user-tie"></i> &nbsp; Corretores
    </p>
    <button class="btn btn-success float-right" (click)="novoCorretor()">
      <i class="fas fa-plus-circle"></i> Novo Corretor
    </button>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table
        class="table table-striped"
        [mfData]="corretores"
        [mfRowsOnPage]="5"
        #mfTable="mfDataTable"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="nome">Nome</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="docmento">Documento</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="celular">Celular</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="email">Email</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td class="text-center text-muted" colspan="7">
              <h5><i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...</h5>
            </td>
          </tr>
          <tr *ngIf="!loading && corretores.length === 0">
            <td class="text-center text-muted" colspan="7">
              <h5>
                <i class="fa fa-spin fa-spinner"></i>&nbsp;Nenhum registro
                encontrado.
              </h5>
            </td>
          </tr>
          <tr *ngFor="let corretor of mfTable.data">
            <td>{{ corretor.nome }}</td>
            <td>{{ corretor.cpfCnpj }}</td>
            <td>{{ corretor.celular }}</td>
            <td>{{ corretor.email }}</td>
            <td class="text-center">
              <a
                (click)="editarCorretor(corretor.corretorId)"
                tooltip="Editar"
                class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i>
              </a>
              <a
                (click)="openModal(uploadContratoCorretor, corretor)"
                tooltip="Verificar documento"
                class="mr-3 pointer"
              >
                <i class="fas fa-file"></i>
              </a>
              <a
                (click)="excluirCorretor(corretor.corretorId)"
                tooltip="Excluir"
                class="pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- Modal upload contrato assinado -->
<ng-template #uploadContratoCorretor>
  <div class="modal-header">
    <h5 class="modal-title" id="statusModalLabel">
      {{
        corretorSelecionado.contrato
          ? " Visualizar Contrato"
          : "Enviar Contrato"
      }}
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div *ngIf="!corretorSelecionado.contrato" class="form-group">
        <label for="contractFile">Selecione o arquivo do contrato</label>
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="contractFile"
            accept="application/pdf"
            (change)="onFileSelected($event)"
          />
          <label class="custom-file-label" for="contractFile">
            {{ fileName }}
          </label>
        </div>
      </div>

      <div *ngIf="corretorSelecionado.contrato">
        <div class="row">
          <div class="col">
            <div>Arquivo</div>
            <a [href]="corretorSelecionado.contrato.url" target="_blank">
              {{ fileName }}
            </a>
            <div>
              <input
                class="d-none"
                type="file"
                id="contractFile"
                accept="application/pdf"
                (change)="onFileSelected($event)"
                #inputFile
              />
            </div>
          </div>
          <div class="col">
            <div>&nbsp;</div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="inputFile.click()"
            >
              <i class="fa fa-plus-circle"></i>
              Atualizar Contrato
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onUpload()"
      *ngIf="!enviandoContrato"
    >
      Enviar
    </button>
    <button type="button" class="btn btn-primary" *ngIf="enviandoContrato">
      <i class="fa fa-spin fa-spinner"></i> Enviando...
    </button>
  </div>
</ng-template>
