import { Component, OnInit } from '@angular/core';
import { Corretor } from '../_models';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-corretor',
  templateUrl: './corretor.component.html',
  styleUrls: ['./corretor.component.scss'],
})
export class CorretorComponent implements OnInit {
  corretores: Corretor[] = [];
  loading: boolean = true;
  modalRef: BsModalRef;
  corretorSelecionado: Corretor;

  selectedFile?: File;
  fileName: string = 'Escolha o arquivo';
  enviandoContrato = false;

  constructor(
    private restAngular: Restangular,
    private norifier: NotifierService,
    private router: Router,
    private confimation: ConfirmationService,
    private modalService: BsModalService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.carregarCorretores();
  }

  editarCorretor(id: number) {
    this.router.navigate(['/update-corretor', id]);
  }

  novoCorretor() {
    this.router.navigate(['/create-corretor']);
  }

  excluirCorretor(id: number) {
    this.confimation
      .create('Atenção', 'Deseja realmente excluir o corretor?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('corretor', id)
            .remove()
            .subscribe(
              () => {
                this.norifier.notify(
                  'success',
                  'Corretor excluído com sucesso'
                );
                this.carregarCorretores();
              },
              () => {
                this.norifier.notify('error', 'Erro ao excluir corretor');
              }
            );
        }
      });
  }

  carregarCorretores() {
    this.loading = true;
    this.restAngular
      .one('corretor')
      .get()
      .subscribe(
        (corretores) => {
          this.corretores = corretores.data;
          this.loading = false;
        },
        () => {
          this.norifier.notify('error', 'Erro ao carregar corretores');
          this.loading = false;
        }
      );
  }

  openModal(template, corretor: Corretor) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    this.corretorSelecionado = corretor;
    this.fileName = 'Selecionar Arquivo';
    if (this.corretorSelecionado.contrato) {
      this.fileName = this.corretorSelecionado.contrato.nome;
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
    } else {
      this.fileName = 'Escolha o arquivo';
    }
  }

  onUpload() {
    if (this.selectedFile) {
      this.enviandoContrato = true;
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        const arquivo = {
          Nome: this.selectedFile.name,
          Tipo: this.selectedFile.type,
          Tamanho: this.selectedFile.size,
          Base64: base64,
          Url: null,
        };
        this.restAngular
          .all('corretor/uploadCorretorContrato')
          .post({
            corretorId: this.corretorSelecionado.corretorId,
            Contrato: arquivo,
          })
          .subscribe(
            (response) => {
              this.notifier.notify('success', 'upload realizado com sucesso');
              this.modalRef.hide();
              this.enviandoContrato = false;
              this.carregarCorretores();
            },
            (error) => {
              this.notifier.notify('error', 'erro ao fazer upload do arquivo');
              this.enviandoContrato = false;
            }
          );
      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      console.error('No file selected');
    }
  }
}
