import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, OrigemContato } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-origem',
  templateUrl: './origem.component.html',
  styleUrls: ['./origem.component.scss'],
})
export class OrigemComponent implements OnInit {
  origens: OrigemContato[] = [];
  backuporigens: OrigemContato[] = [];
  loading = false;

  newOrigem: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getOrigemContato();
  }

  getOrigemContato() {
    this.loading = true;
    this.restAngular
      .one('OrigemContato')
      .get()
      .subscribe(
        (response: Response<OrigemContato[]>) => {
          this.origens = response.data;
          this.backuporigens = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar as origens');
          this.loading = false;
        }
      );
  }

  delete(origemId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir a origem?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('OrigemContato', origemId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Origem excluída com sucesso');
                this.getOrigemContato();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir a origem'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: OrigemContato): boolean {
    return (
      item.descricao !==
        this.backuporigens.find((x) => x.origemId == item.origemId).descricao &&
      item.descricao !== ''
    );
  }

  saveNewOrigem() {
    this.restAngular
      .all('OrigemContato')
      .post({ descricao: this.newOrigem })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'origem cadastrada com sucesso');
          this.getOrigemContato();
          this.newOrigem = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar a origem');
        }
      );
  }

  editItem(item: OrigemContato) {
    this.restAngular
      .one('OrigemContato')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'Origem editada com sucesso');
          this.getOrigemContato();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar a origem');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
