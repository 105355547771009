<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-code" aria-hidden="true"></i>
          &nbsp;Templates de Email
          <button
            type="button"
            class="btn btn-success float-right"
            [routerLink]="['/create-template']"
            routerLinkActive="router-link-active"
          >
            <i class="fa fa-plus-circle"></i>&nbsp; Novo Template
          </button>
        </div>
        <div class="card-block">
          <div class="table-responsive mt-4">
            <table
              class="table table-striped"
              [mfData]="templates"
              #mftipoMeios="mfDataTable"
              [mfRowsOnPage]="100"
            >
              <thead>
                <tr>
                  <th width="10%">
                    <mfDefaultSorter by="templateNotificacaoId"
                      >Id</mfDefaultSorter
                    >
                  </th>
                  <th width="75%">
                    <mfDefaultSorter by="descricao">Nome</mfDefaultSorter>
                  </th>
                  <th width="15%" class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mftipoMeios.data">
                  <td>{{ item.templateNotificacaoId }}</td>
                  <td>{{ item.descricao }}</td>
                  <td class="text-center" nowrap>
                    <a
                      (click)="edit(item.templateNotificacaoId)"
                      tooltip="Editar"
                      class="mr-2 pointer"
                    >
                      <i class="fa-lg far fa-edit"></i
                    ></a>
                    <a
                      (click)="duplicateTemplate(item.templateNotificacaoId)"
                      tooltip="Duplicar template"
                      class="mr-2 pointer"
                      ><i class="far fa-clone fa-lg"></i
                    ></a>
                    <a
                      (click)="delete(item.templateNotificacaoId)"
                      tooltip="Excluir"
                      class="mr-2 pointer"
                      ><i class="fa-lg far fa-times-circle text-danger"></i
                    ></a>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td class="text-center text-muted" colspan="9">
                    <h5>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="9">
                    <mfBootstrapPaginator
                      [rowsOnPageSet]="[5, 10, 25]"
                    ></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
