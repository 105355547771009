<div class="card animated fadeIn">
  <div class="card-header">
    <div class="d-inline">
      <i class="fa fa-user" aria-hidden="true"></i>
      Contatos
    </div>
    <button
      class="btn btn-success float-right"
      [routerLink]="['/create-contato']"
      routerLinkActive="router-link-active"
    >
      <i class="fa fa-plus-circle"></i>&nbsp;Novo Contato
    </button>
    <button
      class="btn btn-info float-right mr-3"
      tooltip="Exportar para excel"
      (click)="downloadExcel()"
      *ngIf="!exportando"
    >
      <i class="fas fa-file-excel"></i> Exportar
    </button>
    <button class="btn btn-info float-right mr-3" *ngIf="exportando">
      <i class="fas fa-spin fa-spinner"></i> Exportando...
    </button>
  </div>
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-6 text-muted">
        Encontrados {{ contatos.length }} Contatos
      </div>
      <div class="col-6 text-right">
        <button
          style="position: relative"
          class="btn btn-primary"
          (click)="openModal(abrirFiltros)"
        >
          <i class="fas fa-filter"></i> Filtros
          <span
            *ngIf="filtrosAplicados > 0"
            class="badge badge-pill badge-danger numFiltros"
            >{{ filtrosAplicados }}</span
          >
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table
        class="table"
        [mfData]="contatos"
        #mfContato="mfDataTable"
        [mfRowsOnPage]="10"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="nome">Nome</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="email">Email</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="celular">Celular</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="origem.origemId">Origem</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="temperatura">Temperatura</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="clienteId">É Cliente?</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="dataCadastro"
                >Data de Cadastro</mfDefaultSorter
              >
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="mfContato.data.length == 0 && !loading">
            <td colspan="8" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="8" class="text-center">
              <i class="fa fa-spin fa-spinner"></i> Carregando...
            </td>
          </tr>
          <tr *ngFor="let contato of mfContato.data; let i = index">
            <td>
              <a
                (click)="visualizar(contato.contatoId)"
                tooltip="Visualizar dados do Contato"
                class="mr-3 pointer"
                >{{ contato.nome | uppercase }}</a
              >
            </td>
            <td>{{ contato.email }}</td>
            <td>
              <a
                [href]="'https://wa.me/' + formatPhoneNumber(contato.telefone)"
                target="_blank"
                tooltip="Iniciar conversa no whatsapp"
                *ngIf="contato.telefone"
                ><i class="fab fa-whatsapp text-success"></i>
                {{ contato.telefone }}</a
              >
            </td>
            <td>{{ contato.origem?.descricao }}</td>
            <td
              class="text-center"
              [innerHTML]="obterIconeLead(contato.temperatura)"
            ></td>
            <td class="text-center">
              <span
                class="badge"
                [ngClass]="{
                  'badge-success': contato.clienteId,
                  'badge-danger': !contato.clienteId
                }"
                >{{ contato.clienteId ? "SIM" : "NÃO" }}</span
              >
            </td>
            <td class="text-center">
              {{
                contato.dataCadastro
                  | amUtc
                  | amLocal
                  | amDateFormat : "DD/MM/yyyy"
              }}
            </td>
            <td class="text-center" nowrap>
              <a
                (click)="edit(contato.contatoId)"
                tooltip="Editar"
                class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                *ngIf="!contato.clienteId"
                (click)="promover(contato.contatoId)"
                tooltip="Promover a cliente"
                class="mr-3 pointer"
                ><i class="fa fa-user-check text-success fa-lg"></i
              ></a>
              <a
                (click)="openModalContato(modalAcompanhamento, contato)"
                tooltip="Acompanhamento"
                class="mr-3 pointer"
                ><i class="far fa-comments fa-lg"></i
              ></a>
              <a
                (click)="delete(contato.contatoId)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="9">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #modalAcompanhamento>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="far fa-comments fa-lg"></i> Acompanhamento -
      {{ contatoSelecionado.nome }}
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="observacaoForm" (ngSubmit)="addObservacao()">
        <div class="form-group">
          <label for="observacao">Observação</label>
          <textarea
            id="observacao"
            formControlName="observacao"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="observacaoForm.invalid"
        >
          Adicionar
        </button>
      </form>
    </div>

    <div>
      <div class="card my-4">
        <div class="card-header bg-primary text-white">
          <i class="fas fa-clipboard-list"></i> Histórico
        </div>
        <div class="card-body card-historico">
          <div
            class="text-center"
            *ngIf="
              !historicoAcompanhamento || historicoAcompanhamento.length == 0
            "
          >
            Contato sem histórico de acompanhamento
          </div>
          <table
            class="table table-striped"
            *ngIf="
              historicoAcompanhamento && historicoAcompanhamento.length > 0
            "
          >
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Data</th>
                <th width="70%">Observação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of historicoAcompanhamento">
                <td>{{ item.usuario?.nome | uppercase }}</td>
                <td>{{ item.dataCadastro | amCalendar }}</td>
                <td>{{ item.observacao }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- MODAL  -->
<ng-template #abrirFiltros>
  <div class="modal-header bg-primary">
    <h5 class="modal-title" id="filtrosModalLabel">
      <i class="fas fa-filter"></i> Filtros
    </h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container-fluid">
    <div class="modal-body" [formGroup]="filtro">
      <div class="mb-2 row">
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="periodo">Período</label>
          </div>
          <input
            class="form-control"
            #drp="bsDaterangepicker"
            bsDaterangepicker
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
            formControlName="data"
            id="periodo"
          />
        </div>
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="faixa">Faixa de Valor</label>
          </div>
          <input
            type="text"
            class="form-control"
            formControlName="valorinicial"
            placeholder="Valor Inicial"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
          <input
            type="text"
            class="form-control"
            formControlName="valorfinal"
            placeholder="Valor Final"
            id="faixa"
            currencyMask
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ','
            }"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="temperatura"
              >Temperatura</label
            >
          </div>
          <select
            formControlName="temperatura"
            class="form-control"
            type="text"
            id="temperatura"
          >
            <option value="">Todos</option>
            <option value="quente">QUENTE</option>
            <option value="medio">MÉDIO</option>
            <option value="frio">FRIO</option>
          </select>
        </div>
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="origemcontato">Origem</label>
          </div>
          <select
            class="form-control"
            id="origemcontato"
            formControlName="origemId"
          >
            <option value="">Todos</option>
            <option *ngFor="let item of origemcontato" [value]="item.origemId">
              {{ item.descricao }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="input-group col-12">
          <div class="input-group-prepend">
            <label class="input-group-text" for="nome">Nome</label>
          </div>
          <input
            formControlName="nome"
            type="text"
            class="form-control"
            placeholder="Nome"
            id="nome"
          />
        </div>
      </div>
      <div class="mb-2 row">
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="email">Email</label>
          </div>
          <input
            formControlName="email"
            type="text"
            class="form-control"
            placeholder="Email"
            id="email"
          />
        </div>
        <div class="input-group col-6">
          <div class="input-group-prepend">
            <label class="input-group-text" for="celular">Celular</label>
          </div>
          <input
            formControlName="celular"
            type="text"
            class="form-control"
            placeholder="Celular"
            id="celular"
          />
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="estadoId">Estado</label>
            </div>
            <ng-select
              class="form-control"
              [multiple]="true"
              formControlName="estados"
              (change)="onChangeEstado()"
              [items]="estados"
              id="estadoId"
              bindValue="estadoId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="municipioId"
                >Municipio</label
              >
            </div>
            <ng-select
              class="form-control"
              [multiple]="true"
              formControlName="municipios"
              (change)="onChangeMunicipio()"
              [items]="municipios"
              bindValue="municipioId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="bairroId">Bairro</label>
            </div>
            <ng-select
              class="form-control"
              [multiple]="true"
              formControlName="bairros"
              [items]="bairros"
              bindValue="bairroId"
              bindLabel="nome"
              ><ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div
                  class="ng-value"
                  *ngFor="let item of items | slice : 0 : 2; trackBy: item"
                >
                  <span class="ng-value-label"> {{ item.nome }}</span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label"
                    >mais {{ items.length - 2 }}...</span
                  >
                </div>
              </ng-template></ng-select
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-success float-right"
      (click)="onChangeFilter()"
      (click)="modalRef?.hide()"
    >
      Filtrar
    </button>
    <div *ngIf="filtrosAplicados > 0">
      <button
        class="btn btn-link float-right mr-2"
        (click)="removerFiltros()"
        (click)="modalRef?.hide()"
      >
        Remover filtros
      </button>
    </div>
  </div>
</ng-template>
