import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  loading = true;
  templates;
  constructor(
    private router: Router,
    private restangular: Restangular,
    private route: ActivatedRoute,
    private NotifierService: NotifierService
  ) {}

  ngOnInit() {
    this.getTemplate();
  }

  getTemplate() {
    this.restangular
      .one('template')
      .get()
      .subscribe((res) => {
        this.templates = res.data;
        this.loading = false;
      });
  }

  edit(id) {
    this.router.navigate(['/update-template', id], { relativeTo: this.route });
  }

  duplicateTemplate(id) {
    this.restangular
      .one('template/' + id + '/duplicar')
      .post()
      .subscribe(
        () => {
          this.NotifierService.notify(
            'success',
            'Template duplicado com sucesso!'
          );
          this.getTemplate();
        },
        () => {
          this.NotifierService.notify('error', 'Erro ao duplicar template!');
        }
      );
  }

  delete(id) {
    this.restangular
      .one('template/' + id)
      .remove()
      .subscribe(
        () => {
          this.NotifierService.notify(
            'success',
            'Template deletado com sucesso!'
          );
          this.getTemplate();
        },
        () => {
          this.NotifierService.notify(
            'error',
            'Não foi possível deletar este template!'
          );
        }
      );
  }
}
