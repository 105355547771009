/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-sidebar-nav.component";
import * as i2 from "../../_services/authorization.service";
import * as i3 from "../../_services/authentication.service";
import * as i4 from "../../directives/replace/replace.directive";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../../directives/nav-dropdown/nav-dropdown.directive";
var styles_AppSidebarNavComponent = [];
var RenderType_AppSidebarNavComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarNavComponent, data: {} });
export { RenderType_AppSidebarNavComponent as RenderType_AppSidebarNavComponent };
function View_AppSidebarNavComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "li", [["class", "nav-divider"]], null, null, null, null, null))], null, null); }
function View_AppSidebarNavComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-sidebar-nav-title", [], null, null, null, View_AppSidebarNavTitleComponent_0, RenderType_AppSidebarNavTitleComponent)), i0.ɵdid(1, 4833280, null, 0, i1.AppSidebarNavTitleComponent, [i0.ElementRef, i0.Renderer2, i2.AuthorizationService, i3.AuthenticationService], { title: [0, "title"] }, null), i0.ɵdid(2, 81920, null, 0, i4.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, null); }
function View_AppSidebarNavComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_4)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTitle(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppSidebarNavComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-sidebar-nav-item", [], null, null, null, View_AppSidebarNavItemComponent_0, RenderType_AppSidebarNavItemComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavItemComponent, [i6.Router], { item: [0, "item"] }, null), i0.ɵdid(2, 81920, null, 0, i4.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, null); }
function View_AppSidebarNavComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_6)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMenuItem(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppSidebarNavComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_2)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_3)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_5)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDivider(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showTitle(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.isDivider(_v.context.$implicit) && !_co.isTitle(_v.context.$implicit)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AppSidebarNavComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "nav", [["class", "sidebar-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavComponent_1)), i0.ɵdid(3, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppSidebarNavComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-nav", [], null, null, null, View_AppSidebarNavComponent_0, RenderType_AppSidebarNavComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavComponent, [i2.AuthorizationService, i3.AuthenticationService], null, null)], null, null); }
var AppSidebarNavComponentNgFactory = i0.ɵccf("app-sidebar-nav", i1.AppSidebarNavComponent, View_AppSidebarNavComponent_Host_0, {}, {}, []);
export { AppSidebarNavComponentNgFactory as AppSidebarNavComponentNgFactory };
var styles_AppSidebarNavItemComponent = [];
var RenderType_AppSidebarNavItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarNavItemComponent, data: {} });
export { RenderType_AppSidebarNavItemComponent as RenderType_AppSidebarNavItemComponent };
function View_AppSidebarNavItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "app-sidebar-nav-link", [], null, null, null, View_AppSidebarNavLinkComponent_0, RenderType_AppSidebarNavLinkComponent)), i0.ɵdid(3, 49152, null, 0, i1.AppSidebarNavLinkComponent, [], { link: [0, "link"] }, null), i0.ɵdid(4, 81920, null, 0, i4.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasClass() ? ("nav-item " + _co.item.class) : "nav-item"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item; _ck(_v, 3, 0, currVal_1); _ck(_v, 4, 0); }, null); }
function View_AppSidebarNavItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "li", [["appNavDropdown", ""], ["routerLinkActive", "open"]], [[2, "open", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵdid(5, 16384, null, 0, i7.NavDropdownDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "app-sidebar-nav-dropdown", [], null, null, null, View_AppSidebarNavDropdownComponent_0, RenderType_AppSidebarNavDropdownComponent)), i0.ɵdid(7, 49152, null, 0, i1.AppSidebarNavDropdownComponent, [], { link: [0, "link"] }, null), i0.ɵdid(8, 81920, null, 0, i4.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.hasClass() ? ("nav-item nav-dropdown " + _co.item.class) : "nav-item nav-dropdown"); _ck(_v, 1, 0, currVal_1); var currVal_2 = "open"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.item; _ck(_v, 7, 0, currVal_3); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppSidebarNavItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["dropdown", 2]], null, 0, null, View_AppSidebarNavItemComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDropdown(); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppSidebarNavItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-nav-item", [], null, null, null, View_AppSidebarNavItemComponent_0, RenderType_AppSidebarNavItemComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavItemComponent, [i6.Router], null, null)], null, null); }
var AppSidebarNavItemComponentNgFactory = i0.ɵccf("app-sidebar-nav-item", i1.AppSidebarNavItemComponent, View_AppSidebarNavItemComponent_Host_0, { item: "item" }, {}, []);
export { AppSidebarNavItemComponentNgFactory as AppSidebarNavItemComponentNgFactory };
var styles_AppSidebarNavLinkComponent = [];
var RenderType_AppSidebarNavLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarNavLinkComponent, data: {} });
export { RenderType_AppSidebarNavLinkComponent as RenderType_AppSidebarNavLinkComponent };
function View_AppSidebarNavLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.link.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AppSidebarNavLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("badge badge-" + _co.link.badge.variant); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link.badge.text; _ck(_v, 2, 0, currVal_1); }); }
function View_AppSidebarNavLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 671744, [[2, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 1), i0.ɵdid(4, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavLinkComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavLinkComponent_3)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.hasVariant() ? ("nav-link nav-link-" + _co.link.variant) : "nav-link"); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 3, 0, _co.link.url); _ck(_v, 2, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.isIcon(); _ck(_v, 8, 0, currVal_5); var currVal_7 = _co.isBadge(); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _co.link.name; _ck(_v, 9, 0, currVal_6); }); }
function View_AppSidebarNavLinkComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.link.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AppSidebarNavLinkComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("badge badge-" + _co.link.badge.variant); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link.badge.text; _ck(_v, 2, 0, currVal_1); }); }
function View_AppSidebarNavLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [], [[8, "href", 4]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavLinkComponent_5)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavLinkComponent_6)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.hasVariant() ? ("nav-link nav-link-" + _co.link.variant) : "nav-link"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.isIcon(); _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.isBadge(); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.link.url, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.link.name; _ck(_v, 4, 0, currVal_3); }); }
export function View_AppSidebarNavLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavLinkComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["external", 2]], null, 0, null, View_AppSidebarNavLinkComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isExternalLink(); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppSidebarNavLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-nav-link", [], null, null, null, View_AppSidebarNavLinkComponent_0, RenderType_AppSidebarNavLinkComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavLinkComponent, [], null, null)], null, null); }
var AppSidebarNavLinkComponentNgFactory = i0.ɵccf("app-sidebar-nav-link", i1.AppSidebarNavLinkComponent, View_AppSidebarNavLinkComponent_Host_0, { link: "link" }, {}, []);
export { AppSidebarNavLinkComponentNgFactory as AppSidebarNavLinkComponentNgFactory };
var styles_AppSidebarNavDropdownComponent = [];
var RenderType_AppSidebarNavDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarNavDropdownComponent, data: {} });
export { RenderType_AppSidebarNavDropdownComponent as RenderType_AppSidebarNavDropdownComponent };
function View_AppSidebarNavDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.link.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AppSidebarNavDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("badge badge-" + _co.link.badge.variant); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link.badge.text; _ck(_v, 2, 0, currVal_1); }); }
function View_AppSidebarNavDropdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-sidebar-nav-item", [], null, null, null, View_AppSidebarNavItemComponent_0, RenderType_AppSidebarNavItemComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavItemComponent, [i6.Router], { item: [0, "item"] }, null), i0.ɵdid(2, 81920, null, 0, i4.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, null); }
export function View_AppSidebarNavDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["appNavDropdownToggle", ""], ["class", "nav-link nav-dropdown-toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).toggleOpen($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i7.NavDropdownToggleDirective, [i7.NavDropdownDirective], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavDropdownComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavDropdownComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "ul", [["class", "nav-dropdown-items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSidebarNavDropdownComponent_3)), i0.ɵdid(9, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isIcon(); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.isBadge(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.link.children; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link.name; _ck(_v, 4, 0, currVal_1); }); }
export function View_AppSidebarNavDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-nav-dropdown", [], null, null, null, View_AppSidebarNavDropdownComponent_0, RenderType_AppSidebarNavDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarNavDropdownComponent, [], null, null)], null, null); }
var AppSidebarNavDropdownComponentNgFactory = i0.ɵccf("app-sidebar-nav-dropdown", i1.AppSidebarNavDropdownComponent, View_AppSidebarNavDropdownComponent_Host_0, { link: "link" }, {}, []);
export { AppSidebarNavDropdownComponentNgFactory as AppSidebarNavDropdownComponentNgFactory };
var styles_AppSidebarNavTitleComponent = [];
var RenderType_AppSidebarNavTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarNavTitleComponent, data: {} });
export { RenderType_AppSidebarNavTitleComponent as RenderType_AppSidebarNavTitleComponent };
export function View_AppSidebarNavTitleComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AppSidebarNavTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-nav-title", [], null, null, null, View_AppSidebarNavTitleComponent_0, RenderType_AppSidebarNavTitleComponent)), i0.ɵdid(1, 4833280, null, 0, i1.AppSidebarNavTitleComponent, [i0.ElementRef, i0.Renderer2, i2.AuthorizationService, i3.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppSidebarNavTitleComponentNgFactory = i0.ɵccf("app-sidebar-nav-title", i1.AppSidebarNavTitleComponent, View_AppSidebarNavTitleComponent_Host_0, { title: "title" }, {}, []);
export { AppSidebarNavTitleComponentNgFactory as AppSidebarNavTitleComponentNgFactory };
