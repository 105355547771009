import { Component, OnInit } from '@angular/core';
import { Response } from '../_models';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss'],
})
export class ContatoComponent implements OnInit {
  contatos: any[] = [];
  filtro: FormGroup;
  origemcontato = [];
  observacaoForm: FormGroup;
  filtrosAplicados: number = 0;

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  modalRef: BsModalRef;
  contatoSelecionado: any;
  historicoAcompanhamento: any = [];
  exportando = false;
  loading = false;
  defaultFormValue;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private confirmation: ConfirmationService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.defaultFormValue = {
      data: [],
      nome: '',
      email: '',
      celular: '',
      origemId: [''],
      estados: [[]],
      municipios: [[]],
      bairros: [[]],
      temperatura: '',
      valorinicial: '',
      valorfinal: '',
    };
    this.filtro = this.fb.group(this.defaultFormValue);

    this.observacaoForm = this.fb.group({
      observacao: ['', Validators.required],
    });

    this.getContato();
    this.getOrigemContato();
  }

  openModal(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.getEstado();
  }

  getEstado() {
    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
      });
    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }
    if (
      this.filtro.value.municipios === null ||
      this.filtro.value.municipios.length === 0
    ) {
      this.filtro.get('bairros').disable();
      return;
    }
  }

  contarFiltros(values: any): void {
    this.filtrosAplicados = Object.values(values).filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    }).length;
  }

  onChangeFilter() {
    this.contatos = [];
    this.getContato();
    this.contarFiltros(this.filtro.value);
  }

  getContato() {
    let dateStart = '';
    let dateEnd = '';

    const datas = this.filtro.controls['data'].value;
    if (datas !== null) {
      dateStart = moment(datas[0]).format('YYYY/MM/DD');
      dateEnd = moment(datas[1]).format('YYYY/MM/DD');
    }

    this.restAngular
      .one('contato')
      .get({ dateStart, dateEnd, ...this.filtro.value })
      .subscribe(
        (response) => {
          this.contatos = response.data;
        },
        (error) => {
          this.notifier.notify(
            'error',
            'Não foi possível carregar os contatos'
          );
        }
      );
  }

  edit(contatoId: number) {
    this.router.navigate(['update-contato', contatoId]);
  }

  obterIconeLead(temperatura: string) {
    switch (temperatura) {
      case 'quente':
        return '<i class="fas fas fa-thermometer-full full fa-2x text-danger" title="Quente"></i>';
      case 'medio':
        return '<i class="fas fa-thermometer-half half fa-2x text-warning" title="Médio"></i>';
      case 'frio':
        return '<i class="fas fa-thermometer-empty empty fa-2x text-info" title="Frio"></i>';
      default:
        return '';
    }
  }

  delete(contatoId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o contato?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('contato', contatoId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Contato excluído com sucesso');
                this.getContato();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o contato'
                );
              }
            );
        }
      });
  }

  promover(contatoId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente promover o  contato a cliente?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('contato', contatoId)
            .one('promover')
            .post()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify(
                  'success',
                  'Contato promovido com sucesso'
                );
                this.getContato();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível promover o contato'
                );
              }
            );
        }
      });
  }

  onChangeEstado() {
    this.filtro.get('municipios').disable();

    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }

    this.restAngular
      .one('localidade/municipio')
      .get({ estados: this.filtro.value.estados })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.filtro.get('municipios').enable();
      });
  }

  onChangeMunicipio() {
    const municipios = this.filtro.value.municipios;

    if (municipios === null || municipios.length === 0) {
      this.filtro.get('bairros').disable();
      return;
    }

    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('estados').markAsTouched();
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }

    this.restAngular
      .one('localidade/bairro')
      .get({ municipios })
      .subscribe((res) => {
        const bairros = res.data;
        this.bairros = bairros;
        this.filtro.get('bairros').enable();
      });
  }

  openModalContato(template, contato) {
    this.contatoSelecionado = contato;
    this.loadHistorico(this.contatoSelecionado.contatoId);
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
  }

  loadHistorico(contatoId) {
    this.restAngular
      .one('acompanhamentoContato', contatoId)
      .get()
      .subscribe((res) => {
        this.historicoAcompanhamento = res.data;
      });
  }

  addObservacao() {
    if (this.observacaoForm.valid) {
      const observacaoData = {
        ...this.observacaoForm.value,
        contatoId: this.contatoSelecionado.contatoId,
      };

      this.restAngular
        .all('acompanhamentoContato')
        .post(observacaoData)
        .subscribe(() => {
          this.loadHistorico(this.contatoSelecionado.contatoId);
          this.observacaoForm.reset();
        });
    }
  }

  formatPhoneNumber(phone: string): string {
    let phoneTratado = phone.replace(/\D/g, '');

    if (!phone.startsWith('5') && phoneTratado.length < 13)
      phoneTratado = '55' + phoneTratado;

    return phoneTratado;
  }

  downloadExcel() {
    this.exportando = true;

    let dateStart = '';
    let dateEnd = '';

    const datas = this.filtro.controls['data'].value;
    if (datas && datas.length > 1) {
      dateStart = moment(datas[0]).format('YYYY/MM/DD');
      dateEnd = moment(datas[1]).format('YYYY/MM/DD');
    }
    this.restAngular
      .one('contato/exportar')
      .withHttpConfig({ responseType: 'blob' })
      .get({ dateStart, dateEnd, ...this.filtro.value })
      .subscribe(
        (response) => {
          this.exportando = false;

          const blob = response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `RelatorioContatos_${moment().unix()}.xlsx`;

          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          }, 100);
        },
        (err) => {
          this.exportando = false;
          this.notifier.notify('error', 'Erro exportar contatos');
        }
      );
  }

  removerFiltros() {
    this.filtro = this.fb.group(this.defaultFormValue);
    this.municipios = [];
    this.bairros = [];
    this.contatos = [];
    this.getContato();
    this.filtrosAplicados = 0;
  }

  visualizar(contatoId: number) {
    this.router.navigate(['visualizar-contato', contatoId]);
  }

  getOrigemContato() {
    this.restAngular
      .one('origemcontato')
      .get()
      .subscribe(
        (response) => {
          this.origemcontato = response.data;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar as origens');
        }
      );
  }
}
