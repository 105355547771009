import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-create-usuarios',
  templateUrl: './create-usuarios.component.html',
  styleUrls: ['./create-usuarios.component.scss'],
})
export class CreateUsuariosComponent implements OnInit {
  formulario: FormGroup;

  perfis = [];
  fieldTextType: boolean;
  imageError: string;
  cardImageBase64: any;
  isImageSaved: boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private restangular: Restangular,
    private notifierService: NotifierService
  ) {
    this.formulario = this.formBuilder.group({
      nome: [null, [Validators.required, Validators.minLength(3)]],
      perfilId: [[], Validators.required],
      orgao: [''],
      matricula: [''],
      login: ['', Validators.required],
      senha: [null, Validators.required],
      assinatura: this.formBuilder.group({
        arquivoId: [0],
        nome: [''],
        base64: [''],
        tipo: [''],
        tamanho: [0],
      }),
    });
  }

  ngOnInit() {
    forkJoin([this.restangular.one('/perfil').get().pipe()]).subscribe(
      (allResp: any[]) => {
        this.perfis = allResp[0].data;
      }
    );
  }

  onSubmit() {
    if (!this.formulario.valid) {
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      return;
    }
    this.restangular
      .all('usuario')
      .post(this.formulario.value)
      .subscribe(
        (a) => {
          this.notifierService.notify('success', 'Usuário criado com sucesso');
          this.router.navigateByUrl('/usuarios');
        },
        (error) => {
          const errors = error.data.Errors;
          for (const k in errors) {
            if (k.toLowerCase() === 'exception') {
              this.notifierService.notify('error', 'Erro ao atualizar usuário');
            } else {
              this.notifierService.notify('error', errors[k]);
            }
          }

          Object.keys(this.formulario.controls).forEach((campo) => {
            const controle = this.formulario.get(campo);
            controle.markAsTouched();
          });
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return {
      'is-invalid': this.verificaValidTouched(campo),
    };
  }

  onValueChange(event, campo) {
    this.formulario.get(campo).markAsTouched();
    this.formulario.get(campo).setValue(event);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 5242880;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'O tamanho máximo permitido é 5Mb';

        return false;
      }

      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Somente imagens são permitidas ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Tamanho máximo permitido ' + max_height + '*' + max_width + 'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            let assinatura = this.formulario.get('assinatura') as FormGroup;
            assinatura.get('base64').setValue(imgBase64Path);
            assinatura.get('nome').setValue(fileInput.target.files[0].name);
            assinatura.get('tamanho').setValue(fileInput.target.files[0].size);
            assinatura.get('tipo').setValue(fileInput.target.files[0].type);
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage(input) {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    input.value = '';
  }
}
