import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConsultaCepService = /** @class */ (function () {
    function ConsultaCepService(http) {
        this.http = http;
    }
    ConsultaCepService.prototype.consultaCEP = function (cep) {
        // Nova variável "cep" somente com dígitos.
        cep = cep.replace(/\D/g, '');
        // Verifica se campo cep possui valor informado.
        if (cep !== '') {
            // Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;
            // Valida o formato do CEP.
            if (validacep.test(cep)) {
                return this.http.get("//viacep.com.br/ws/" + cep + "/json");
            }
        }
        return of({});
    };
    ConsultaCepService.cepValidator = function (control) {
        var cep = control.value;
        if (cep && cep !== '') {
            var validacep = /^[0-9]{8}$/;
            return validacep.test(cep) ? null : { cepInvalido: true };
        }
        return null;
    };
    ConsultaCepService.ngInjectableDef = i0.defineInjectable({ factory: function ConsultaCepService_Factory() { return new ConsultaCepService(i0.inject(i1.HttpClient)); }, token: ConsultaCepService, providedIn: "root" });
    return ConsultaCepService;
}());
export { ConsultaCepService };
