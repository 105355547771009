import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-edit-bairro',
  templateUrl: './edit-bairro.component.html',
  styleUrls: ['./edit-bairro.component.scss'],
})
export class EditBairroComponent implements OnInit {
  formulario: FormGroup;
  formularioNewEstado: FormGroup;
  formularioNewMunicipio: FormGroup;

  modalRef: BsModalRef;
  estados: any;
  municipios: any;

  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private restAngular: Restangular,
    private notifier: NotifierService,
    private route: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      nome: ['', Validators.required],
      zona: [''],
      subZona: [''],
      estadoId: [null, Validators.required],
      municipioId: [null, Validators.required],
      bairroId: [null],
    });

    this.formularioNewEstado = this.formBuilder.group({
      nome: ['', Validators.required],
      sigla: ['', Validators.required],
    });

    this.formularioNewMunicipio = this.formBuilder.group({
      nome: ['', Validators.required],
      estadoId: [null, Validators.required],
    });

    this.id = this.activatedRoute.snapshot.params.id;

    this.restAngular
      .one('localidade/bairro', this.id)
      .get()
      .subscribe((response) => {
        this.updateFormulario(response.data);
      });
  }

  updateFormulario(endereco) {
    this.getEstados();
    this.getMunicipios(endereco.municipio.estadoId);

    this.formulario.patchValue({
      nome: endereco.nome,
      zona: endereco.zona,
      subZona: endereco.subZona,
      estadoId: endereco.municipio.estadoId,
      municipioId: endereco.municipioId,
      bairroId: endereco.bairroId,
    });
  }

  onSubimit() {
    this.restAngular
      .all('localidade/bairro')
      .customPUT(this.formulario.value)
      .subscribe(
        (response) => {
          this.notifier.notify('success', 'Endereço criado com sucesso');
          this.route.navigate(['/bairros']);
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao atualizar endereço');
        }
      );
  }

  onSubmitNewEstado() {
    this.restAngular
      .all('localidade/estado')
      .post(this.formularioNewEstado.value)
      .subscribe(
        (response) => {
          this.modalRef.hide();
          this.notifier.notify('success', 'Estado criado com sucesso');
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao atualizar estado');
        }
      );
  }

  onSubmitNewMunicipio() {
    this.restAngular
      .all('localidade/municipio')
      .post(this.formularioNewMunicipio.value)
      .subscribe(
        (response) => {
          this.modalRef.hide();
          this.notifier.notify('success', 'Municipio criado com sucesso');
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao atualizar municipio');
        }
      );
  }

  estadoChange(estadoId) {
    this.restAngular
      .one('localidade/municipio')
      .get({ estadoId })
      .subscribe((response) => {
        this.municipios = response.data;
      });
  }

  getMunicipios(estadoId) {
    this.restAngular
      .one('localidade/municipio')
      .get({ estadoId })
      .subscribe((response) => {
        this.municipios = response.data;
      });
  }

  getEstados() {
    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((response) => {
        this.estados = response.data;
      });
  }

  closeModal() {
    this.modalRef.hide();
  }

  updateModalRef(modalRef: BsModalRef) {
    this.modalRef = modalRef;
  }
}
