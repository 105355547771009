/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-sidebar-header.component";
var styles_AppSidebarHeaderComponent = [];
var RenderType_AppSidebarHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarHeaderComponent, data: {} });
export { RenderType_AppSidebarHeaderComponent as RenderType_AppSidebarHeaderComponent };
export function View_AppSidebarHeaderComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AppSidebarHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-header", [], null, null, null, View_AppSidebarHeaderComponent_0, RenderType_AppSidebarHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarHeaderComponent, [], null, null)], null, null); }
var AppSidebarHeaderComponentNgFactory = i0.ɵccf("app-sidebar-header", i1.AppSidebarHeaderComponent, View_AppSidebarHeaderComponent_Host_0, {}, {}, []);
export { AppSidebarHeaderComponentNgFactory as AppSidebarHeaderComponentNgFactory };
