import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { Response, TipoPessoa } from 'app/views/_models';

@Component({
  selector: 'app-update-status-intencao-compra',
  templateUrl: './update-status-intencao-compra.component.html',
  styleUrls: ['./update-status-intencao-compra.component.scss'],
})
export class UpdateStatusIntencaoCompraComponent implements OnInit {
  formulario: FormGroup;
  grupos: any[] = [];
  id: number;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.restAngular
      .one('GrupoStatus')
      .get()
      .subscribe(
        (res) => {
          this.grupos = res.data;
        },
        (err) => {
          this.notifier.notify('error', 'erro ao obter grupos');
        }
      );

    this.id = this.activatedRoute.snapshot.params.id;

    this.restAngular
      .one('statusIntencaoCompra', this.id)
      .get()
      .subscribe((data: Response<any>) => {
        const status = data.data;

        this.formulario = this.formBuilder.group({
          id: [this.id],
          descricao: [status.descricao, Validators.required],
          grupoId: [status.grupoId, Validators.required],
          possuiDataLimite: [status.possuiDataLimite, Validators.required],
        });
      });
  }

  onSubimit() {
    this.restAngular
      .all('statusIntencaoCompra')
      .customPUT(this.formulario.value)
      .subscribe(
        () => {
          this.notifier.notify('success', 'Status atualizado com sucesso');
          this.router.navigate(['/eventos']);
        },
        () => {
          this.notifier.notify(
            'error',
            'Erro ao atualizar statusIntencaoCompra'
          );
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'is-invalid': this.verificaValidTouched(campo) };
  }
}
