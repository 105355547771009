<div class="container animated fadeIn">
  <button class="btn btn-success" (click)="openModal(modal)" id="create">
    <i class="fa fa-save"></i>&nbsp;Criar
  </button>

  <email-editor
    id="email-editor"
    [options]="options"
    (ready)="editorReady($event)"
  ></email-editor>

  <ng-template #modal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Criar Template</h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div [formGroup]="formulario">
            <div class="form-group">
              <label for="descricao">Titulo</label>
              <input
                type="text"
                class="form-control"
                id="descricao"
                [ngClass]="aplicaCssErro('descricao')"
                [ngStyle]="{ right: salvar == true ? '135px' : '112px' }"
                placeholder="Digite o titulo do template"
                formControlName="descricao"
              />
            </div>
            <!-- <div class="form-group">
              <label for="tag">Tag</label>
              <input
                type="text"
                class="form-control"
                id="tag"
                rows="3"
                placeholder="Digite a tag"
                formControlName="tag"
              />
            </div> -->
            <div class="form-group">
              <button *ngIf="salvar == true" class="btn btn-success">
                <i class="fa fa-spin fa-spinner"></i>&nbsp;Salvando...
              </button>
              <button
                *ngIf="salvar == false"
                class="btn btn-success"
                (click)="save()"
                id="save"
                [disabled]="formulario.invalid"
              >
                <i class="fa fa-save"></i>&nbsp;Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
