<form [formGroup]="formulario" *ngIf="formulario" (submit)="onSubimit()">
  <div class="card animated fadeIn">
    <div class="card-header">
      <p class="card-title">
        <i class="far fa-user-circle"></i> Dados Pessoais
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-6">
          <label for="celular">Tipo Pessoa</label>
          <select class="form-control" formControlName="tipoPessoaId">
            <option
              *ngFor="let item of tiposPessoa"
              value="{{ item.tipoPessoaId }}"
            >
              {{ item.descricao }}
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <label for="celular">Tipo Pessoa</label>
          <select class="form-control" formControlName="tipoPessoaId">
            <option
              *ngFor="let item of tiposPessoa"
              value="{{ item.tipoPessoaId }}"
            >
              {{ item.descricao }}
            </option>
          </select>
        </div>
      </div>
      <div class="row"></div>
      <div class="row">
        <div class="col-6 form-group">
          <input
            type="text"
            currencyMask
            [options]="{
              prefix: '% ',
              thousands: '.',
              decimal: ','
            }"
            class="form-control"
            id="porcentagem"
            placeholder="Valor"
            formControlName="porcentagem"
          />
        </div>
      </div>
    </div>
  </div>
</form>
