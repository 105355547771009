import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TipoPessoa } from 'app/views/_models';
import { Restangular } from 'ngx-restangular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-visualizar-contato',
  templateUrl: './visualizar-contato.component.html',
  styleUrls: ['./visualizar-contato.component.scss'],
})
export class VisualizarContatoComponent implements OnInit {
  contatoId: number;
  contato;
  origem;

  constructor(
    private restangular: Restangular,
    private notifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private confimation: ConfirmationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.contatoId = this.route.snapshot.params['id'];
    this.getContato();
  }

  getContato() {
    this.restangular
      .one('contato', this.contatoId)
      .get()
      .subscribe(
        (response) => {
          this.contato = response.data;
        },
        (error) => {
          this.notifierService.notify(
            'error',
            'Não foi possível carregar os dados do contato'
          );
        }
      );
    this.getOrigemContato();
  }

  getOrigemContato() {
    this.restangular
      .one('origemcontato')
      .get()
      .subscribe((response) => {
        this.origem = response.data;
      });
  }

  obterIconeLead(temperatura: string) {
    switch (temperatura) {
      case 'quente':
        return '<i class="fas fas fa-thermometer-full full fa-2x text-danger" title="Quente"></i>';
      case 'medio':
        return '<i class="fas fa-thermometer-half half fa-2x text-warning" title="Médio"></i>';
      case 'frio':
        return '<i class="fas fa-thermometer-empty empty fa-2x text-info" title="Frio"></i>';
      default:
        return '';
    }
  }
}
