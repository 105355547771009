/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../app-sidebar-header/app-sidebar-header.component.ngfactory";
import * as i2 from "../app-sidebar-header/app-sidebar-header.component";
import * as i3 from "../../directives/replace/replace.directive";
import * as i4 from "../app-sidebar-form/app-sidebar-form.component.ngfactory";
import * as i5 from "../app-sidebar-form/app-sidebar-form.component";
import * as i6 from "../app-sidebar-nav/app-sidebar-nav.component.ngfactory";
import * as i7 from "../app-sidebar-nav/app-sidebar-nav.component";
import * as i8 from "../../_services/authorization.service";
import * as i9 from "../../_services/authentication.service";
import * as i10 from "../app-sidebar-footer/app-sidebar-footer.component.ngfactory";
import * as i11 from "../app-sidebar-footer/app-sidebar-footer.component";
import * as i12 from "./app-sidebar.component";
var styles_AppSidebarComponent = [];
var RenderType_AppSidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarComponent, data: {} });
export { RenderType_AppSidebarComponent as RenderType_AppSidebarComponent };
export function View_AppSidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-sidebar-header", [], null, null, null, i1.View_AppSidebarHeaderComponent_0, i1.RenderType_AppSidebarHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i2.AppSidebarHeaderComponent, [], null, null), i0.ɵdid(3, 81920, null, 0, i3.ReplaceDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "app-sidebar-form", [], null, null, null, i4.View_AppSidebarFormComponent_0, i4.RenderType_AppSidebarFormComponent)), i0.ɵdid(5, 49152, null, 0, i5.AppSidebarFormComponent, [], null, null), i0.ɵdid(6, 81920, null, 0, i3.ReplaceDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "app-sidebar-nav", [], null, null, null, i6.View_AppSidebarNavComponent_0, i6.RenderType_AppSidebarNavComponent)), i0.ɵdid(8, 49152, null, 0, i7.AppSidebarNavComponent, [i8.AuthorizationService, i9.AuthenticationService], null, null), i0.ɵdid(9, 81920, null, 0, i3.ReplaceDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "app-sidebar-footer", [], null, null, null, i10.View_AppSidebarFooterComponent_0, i10.RenderType_AppSidebarFooterComponent)), i0.ɵdid(11, 49152, null, 0, i11.AppSidebarFooterComponent, [], null, null), i0.ɵdid(12, 81920, null, 0, i3.ReplaceDirective, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); }, null); }
export function View_AppSidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_AppSidebarComponent_0, RenderType_AppSidebarComponent)), i0.ɵdid(1, 49152, null, 0, i12.AppSidebarComponent, [], null, null)], null, null); }
var AppSidebarComponentNgFactory = i0.ɵccf("app-sidebar", i12.AppSidebarComponent, View_AppSidebarComponent_Host_0, {}, {}, []);
export { AppSidebarComponentNgFactory as AppSidebarComponentNgFactory };
