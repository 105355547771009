<form (submit)="onSubimit()">
  <div [formGroup]="formulario" class="card animated fadeIn">
    <div class="card-header">
      <p class="card-title">
        <i class="fas fa-map-marker-alt"></i>
        &nbsp; Editar Bairro
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-6">
          <app-select-insert
            [items]="estados"
            [multiple]="false"
            [bindLabel]="'nome'"
            [bindValue]="'estadoId'"
            [template]="modalInsertEstado"
            [modalRef]="modalRef"
            (customChange)="estadoChange($event)"
            (updateModalRef)="updateModalRef($event)"
            label="Estado"
            formControlName="estadoId"
            route="/localidade/estado"
            inputName="estado"
          >
          </app-select-insert>
        </div>
        <div class="form-group col-6">
          <app-select-insert
            [items]="municipios"
            [multiple]="false"
            [bindLabel]="'nome'"
            [bindValue]="'municipioId'"
            [disabled]="formulario.value.estadoId == null"
            [template]="modalInsertMunicipio"
            [modalRef]="modalRef"
            [params]="{ estadoId: formulario.get('estadoId').value }"
            (updateModalRef)="updateModalRef($event)"
            label="Municipio"
            formControlName="municipioId"
            route="/localidade/municipio"
            inputName="municipio"
          >
          </app-select-insert>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label for="nome">Região</label>
          <input
            type="text"
            class="form-control"
            formControlName="subZona"
            id="subZona"
            placeholder="Região"
          />
        </div>
        <div class="form-group col-4">
          <label for="nome">Zona</label>
          <input
            type="text"
            class="form-control"
            formControlName="zona"
            id="zona"
            placeholder="Zona"
          />
        </div>
        <div class="form-group col-4">
          <label for="nome">Bairro</label>
          <input
            type="text"
            class="form-control"
            formControlName="nome"
            id="nome"
            placeholder="Bairro"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        [disabled]="formulario.invalid"
        type="submit"
        class="btn btn-primary"
      >
        Salvar</button
      >&nbsp;
      <button type="button" class="btn btn-danger" routerLink="/bairros">
        Cancelar
      </button>
    </div>
  </div>
</form>

<!-- Modal Insert Estado -->
<ng-template #modalInsertEstado>
  <div class="modal-header">
    <h5 class="modal-title">Novo Estado</h5>
    <button
      (click)="closeModal()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formularioNewEstado">
      <div class="form-group">
        <label for="nome">Estado</label>
        <input
          type="text"
          class="form-control"
          id="nome"
          placeholder="Nome do Estado"
          formControlName="nome"
        />
      </div>
      <div class="form-group">
        <label for="sigla">Sigla</label>
        <input
          type="text"
          class="form-control"
          id="sigla"
          placeholder="Sigla do Estado"
          formControlName="sigla"
          maxlength="2"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="onSubmitNewEstado()" type="button" class="btn btn-primary">
      Salvar
    </button>
  </div>
</ng-template>

<!-- Modal Insert Municipio -->
<ng-template #modalInsertMunicipio>
  <div class="modal-header">
    <h5 class="modal-title">Novo Município</h5>
    <button
      (click)="closeModal()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formularioNewMunicipio">
      <div class="form-group">
        <label for="sigla">Estado</label>
        <ng-select
          [items]="estados"
          [bindLabel]="'nome'"
          [bindValue]="'estadoId'"
          [(ngModel)]="value"
          [multiple]="false"
          [disabled]="disabled"
          formControlName="estadoId"
        ></ng-select>
      </div>
      <div class="form-group">
        <label for="nome">Município</label>
        <input
          type="text"
          class="form-control"
          id="nome"
          placeholder="Nome do Municipio"
          formControlName="nome"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      (click)="onSubmitNewMunicipio()"
      type="button"
      class="btn btn-primary"
    >
      Salvar
    </button>
  </div>
</ng-template>
