import { Component, OnInit } from '@angular/core';
import { Cliente, Response } from '../_models';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit {
  clientes: Cliente[] = [];
  loading: boolean = false;
  filtro: FormGroup;
  modalRef: BsModalRef;
  filtrando: boolean = false;
  filtrosAplicados: number = 0;
  data;

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];
  defaultFormValue;

  //mask
  public mask: Array<string | RegExp>;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private confirmation: ConfirmationService,
    private router: Router,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');

    this.mask = [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  ngOnInit() {
    this.defaultFormValue = {
      data: [[]],
      nome: '',
      email: '',
      celular: '',
      estados: [[]],
      municipios: [[]],
      bairros: [[]],
      valorinicial: '',
      valorfinal: '',
      temContrato: '',
      temOportunidade: '',
    };
    this.filtro = this.fb.group(this.defaultFormValue);

    this.getCliente();
  }

  contarFiltros(values: any): void {
    this.filtrosAplicados = Object.values(values).filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    }).length;
  }

  getEstado() {
    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
      });
    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }
    if (
      this.filtro.value.municipios === null ||
      this.filtro.value.municipios.length === 0
    ) {
      this.filtro.get('bairros').disable();
      return;
    }
  }

  onChangeEstado() {
    this.filtro.get('municipios').disable();

    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }

    this.restAngular
      .one('localidade/municipio')
      .get({ estados: this.filtro.value.estados })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.filtro.get('municipios').enable();
      });
  }

  onChangeMunicipio() {
    const municipios = this.filtro.value.municipios;

    if (municipios === null || municipios.length === 0) {
      this.filtro.get('bairros').disable();
      return;
    }

    if (
      this.filtro.value.estados === null ||
      this.filtro.value.estados.length === 0
    ) {
      this.filtro.get('estados').markAsTouched();
      this.filtro.get('municipios').disable();
      this.filtro.get('bairros').disable();
      return;
    }

    this.restAngular
      .one('localidade/bairro')
      .get({ municipios })
      .subscribe((res) => {
        const bairros = res.data;
        this.bairros = bairros;
        this.filtro.get('bairros').enable();
      });
  }

  getCliente() {
    this.loading = true;

    let dateStart = '';
    let dateEnd = '';

    const datas = this.filtro.controls['data'].value;
    if (datas && datas.length > 1) {
      dateStart = moment(datas[0]).format('YYYY/MM/DD');
      dateEnd = moment(datas[1]).format('YYYY/MM/DD');
    }

    this.restAngular
      .one('cliente')
      .get({ dateStart, dateEnd, ...this.filtro.value })
      .subscribe(
        (response: Response<Cliente[]>) => {
          this.clientes = response.data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.notifier.notify(
            'error',
            'Não foi possível carregar os clientes'
          );
        }
      );
  }

  edit(clienteId: number) {
    this.router.navigate(['edit-cliente', clienteId]);
  }

  visualizar(clienteId: number) {
    this.router.navigate(['visualizar-cliente', clienteId]);
  }

  delete(clienteId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o cliente?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('cliente', clienteId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Cliente excluído com sucesso');
                this.getCliente();
              },
              (error) => {
                if (error.data && error.data.Message) {
                  this.notifier.notify('error', error.data.Message);
                } else {
                  this.notifier.notify(
                    'error',
                    'Não foi possível excluir o cliente'
                  );
                }
              }
            );
        }
      });
  }

  obeterIconeLead(cliente: Cliente) {
    const { valorInicial, valorFinal, localidadesInteresse } = cliente;

    if (valorInicial > 0 && valorFinal > 0 && localidadesInteresse.length > 0) {
      return '<i class="fas fas fa-thermometer-full full fa-2x text-danger" title="Quente"></i>';
    } else if (
      (valorInicial > 0 && valorFinal > 0) ||
      localidadesInteresse.length > 0
    ) {
      return '<i class="fas fa-thermometer-half half fa-2x text-warning" title="Médio"></i>';
    } else {
      return '<i class="fas fa-thermometer-empty empty fa-2x text-info" title="Frio"></i>';
    }
  }

  formatPhoneNumber(phone: string): string {
    let phoneTratado = phone.replace(/\D/g, '');

    if (!phone.startsWith('5') && phoneTratado.length < 13)
      phoneTratado = '55' + phoneTratado;

    return phoneTratado;
  }

  openModal(template) {
    this.getEstado();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  onChangeFilter() {
    this.clientes = [];
    const celular = this.filtro.get('celular');
    celular.setValue(celular.value.replaceAll('_', ''));
    celular.setValue(celular.value.replaceAll('-', ''));
    this.getCliente();
    this.contarFiltros(this.filtro.value);
  }

  removerFiltros() {
    this.filtrosAplicados = 0;
    this.filtro = this.fb.group(this.defaultFormValue);
    this.municipios = [];
    this.bairros = [];
    this.estados = [];
    this.clientes = [];
    this.getCliente();
  }
}
