<form [formGroup]="formulario" (ngSubmit)="onSubmit()">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-user-edit"></i>
            Dados Pessoais
          </div>

          <div class="card-block">
            <div class="row">
              <div class="form-group col-6">
                <label for="nomeCompleto">Nome Completo</label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('nome')"
                  id="nomeCompleto"
                  placeholder="Nome Completo"
                  formControlName="nome"
                />
              </div>
              <div class="form-group col-6">
                <label for="perfis">Perfil</label>
                <ng-select
                  [items]="perfis"
                  formControlName="perfilId"
                  [ngClass]="aplicaCssErro('perfilId')"
                  bindLabel="descricao"
                  bindValue="perfilId"
                >
                </ng-select>
              </div>
              <div class="form-group col-6">
                <label for="perfis">Login </label>
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="aplicaCssErro('login')"
                  id="login"
                  formControlName="login"
                />
              </div>
              <div class="form-group col-6">
                <label for="perfis">Senha </label>
                <input
                  type="password"
                  class="form-control"
                  [ngClass]="aplicaCssErro('senha')"
                  id="senha"
                  formControlName="senha"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label for="nomeCompleto">Orgao</label>
                <input
                  type="text"
                  class="form-control"
                  id="orgao"
                  placeholder="Orgao"
                  formControlName="orgao"
                />
              </div>
              <div class="form-group col-6">
                <label for="perfis">Matrícula </label>
                <input
                  type="text"
                  class="form-control"
                  id="matricula"
                  placeholder="Matrícula"
                  formControlName="matricula"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4" formGroupName="assinatura">
                <fieldset class="form-group">
                  <label
                    >Assinatura
                    <small class="text-muted"
                      >(Tamanho máximo: 5mb)</small
                    ></label
                  >
                  <div class="custom-file fileInputProfileWrap">
                    <input
                      type="file"
                      (change)="fileChangeEvent($event)"
                      class="fileInputProfile"
                      #inputFile
                      accept="image/*"
                    />
                    <div class="img-space">
                      <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [src]="cardImageBase64" />
                      </ng-container>
                      <ng-template #elseTemplate>
                        <img
                          src="../../../../assets/img/logo-generica.png"
                          class="img-responsive"
                        />
                      </ng-template>
                    </div>
                  </div>
                </fieldset>
                <a
                  class="btn btn-danger"
                  (click)="removeImage(inputFile)"
                  *ngIf="isImageSaved"
                  ><i class="fa fa-trash"></i> Remover</a
                >
                <div class="alert alert-danger" *ngIf="imageError">
                  {{ imageError }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary saveCancel">
              <i class="fa fa-save"></i> Salvar
            </button>
            <button
              type="reset"
              class="btn btn-danger"
              [routerLink]="['/usuarios']"
            >
              <i class="fa fa-undo"></i> Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
