<div class="card animated fadeIn">
  <div class="card-header">
    <div class="d-inline">
      <i class="fa fa-building" aria-hidden="true"></i>
      Análise de Imóveis
    </div>
    <button
      class="btn btn-success float-right mr-2"
      (click)="confirmSelected(true)"
      [disabled]="loadingAprovaTudo"
    >
      <span *ngIf="!loadingAprovaTudo"
        ><i class="fa fa-check-double"></i>&nbsp;Aprovar Todos</span
      >
      <span *ngIf="loadingAprovaTudo"
        ><i class="fa fa-spin fa-spinner"></i> Aprovando...</span
      >
    </button>
    <button
      class="btn btn-primary float-right mr-2"
      (click)="confirmSelected(false)"
      [disabled]="loadingConfirm || loadingAprovaTudo"
    >
      <span *ngIf="!loadingConfirm"
        ><i class="fas fa-check-circle"></i>&nbsp;Aprovar</span
      >
      <span *ngIf="loadingConfirm"
        ><i class="fa fa-spin fa-spinner"></i> Aprovando...</span
      >
    </button>
  </div>
  <div class="card-body">
    <div class="mb-2 filter">
      <div class="align-items-center">
        <p class="m-0 text-muted">Encontrados {{ countAnalises }} Imóveis</p>
      </div>
      <div>
        <div class="input-group d-inline-flex align-items-center w-auto mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >Status</span
            >
          </div>
          <select
            class="form-control"
            placeholder="Status"
            [(ngModel)]="status"
            (ngModelChange)="onChangeFilter()"
          >
            <option value="">Todos</option>
            <option value="2">Aprovado</option>
            <option value="3">Rejeitado</option>
            <option value="4">Ignorado</option>
            <option value="1">Pendente</option>
          </select>
        </div>
        <div class="input-group d-inline-flex align-items-center w-auto mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >Origem</span
            >
          </div>
          <select
            class="form-control"
            placeholder="Origem"
            [(ngModel)]="origem"
            (ngModelChange)="onChangeFilter()"
          >
            <option value="">Todas</option>
            <option *ngFor="let item of origens" [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="input-group d-inline-flex align-items-center w-auto mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >1ª Praça</span
            >
          </div>
          <input
            type="text"
            class="form-control"
            id="dataPrimeiraPraca"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY'
            }"
            bsDatepicker
            [(ngModel)]="dataPrimeiraPraca"
            (ngModelChange)="onChangeFilter()"
          />
        </div>
        <div class="input-group d-inline-flex align-items-center w-auto">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm"
              >2ª Praça</span
            >
          </div>
          <input
            type="text"
            class="form-control"
            id="dataPrimeiraPraca"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY'
            }"
            bsDatepicker
            [(ngModel)]="dataSegundaPraca"
            (ngModelChange)="onChangeFilter()"
          />
        </div>
      </div>
    </div>
    <div class="table-responsive tabela">
      <table
        class="table table-striped"
        [mfData]="analises"
        #mfContato="mfDataTable"
        [mfRowsOnPage]="10"
      >
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                (click)="selectAllAnalises()"
                [(ngModel)]="todasSelecionadas"
              />
            </th>
            <th>
              <mfDefaultSorter by="nome">Foto</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="nome">Titulo</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="valorPrimeiraPraca"
                >1ª Praça</mfDefaultSorter
              >
            </th>
            <th>
              <mfDefaultSorter by="dataSegundaPraca">2ª Praça</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="origem">Origem</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="dataCadastro">DataCadastro</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="statuus">Status</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngIf="countAnalises > 50">
            <td colspan="2">
              Exibindo {{ analises.length }} de {{ countAnalises }} registros.
            </td>
            <td colspan="6"></td>
          </tr> -->
          <tr *ngIf="mfContato.data.length == 0 && !loading">
            <td colspan="11" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="11" class="text-center">
              <i class="fa fa-spin fa-spinner"></i> Carregando...
            </td>
          </tr>
          <tr *ngFor="let analise of mfContato.data; let i = index">
            <td>
              <input
                type="checkbox"
                [(ngModel)]="analise.selecionado"
                (change)="verifyAllItems()"
              />
            </td>
            <td>
              <div class="analise-foto">
                <img
                  [src]="analise.fotos[0] || './assets/img/semimagem.gif'"
                  alt="Foto"
                  style="display: block; width: 100px; object-fit: cover"
                />
              </div>
            </td>
            <td>
              <p style="min-width: 200px">
                <a [href]="analise.link" target="_blank">
                  {{ analise.titulo | uppercase }}</a
                >
              </p>
            </td>
            <td>
              {{
                analise.dataPrimeiraPraca
                  | amUtc
                  | amLocal
                  | amDateFormat : "DD/MM/yyyy"
              }}<br />
              {{
                analise.valorPrimeiraPraca
                  | currency : "BRL" : "symbol" : "1.2-2"
              }}
            </td>
            <td>
              {{
                analise.dataSegundaPraca
                  | amUtc
                  | amLocal
                  | amDateFormat : "DD/MM/yyyy"
              }}<br />
              {{
                analise.valorSegundaPraca
                  | currency : "BRL" : "symbol" : "1.2-2"
              }}
            </td>

            <td>{{ analise.origem }}</td>
            <td>
              {{
                analise.dataCadastro
                  | amUtc
                  | amLocal
                  | amDateFormat : "DD/MM/yyyy HH:mm"
              }}
            </td>
            <td [ngSwitch]="analise.status">
              <span *ngSwitchCase="2" class="badge badge-success"
                >Aprovado</span
              >
              <span *ngSwitchCase="3" class="badge badge-danger"
                >Rejeitado</span
              >
              <span *ngSwitchCase="4" class="badge badge-danger">Ignorado</span>
              <span *ngSwitchDefault class="badge badge-warning">Pendente</span>
            </td>
            <td class="text-center" nowrap>
              <ng-container>
                <a
                  [routerLink]="'/edit-leilao/' + analise.analiseLeilaoId"
                  tooltip="Editar"
                  class="mr-3 pointer"
                  ><i class="fa-lg far fa-edit"></i
                ></a>
                <a
                  *ngIf="analise.status == 1 || analise.status == 4"
                  (click)="reprovar(analise.analiseLeilaoId)"
                  tooltip="Rejeitar"
                  class="mr-1 pointer"
                  ><i class="fa-lg far fa-times-circle text-danger"></i
                ></a>
              </ng-container>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="11" class="text-center">
              <pagination
                [totalItems]="countAnalises"
                [(ngModel)]="page"
                [itemsPerPage]="10"
                (pageChanged)="loadMoreAnalises($event)"
                [maxSize]="5"
                nextText="Próximo"
                previousText="Anterior"
              >
              </pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
