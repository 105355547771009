import { Component, OnInit } from '@angular/core';
import {
  Bairro,
  Contrato,
  Estado,
  Imovel,
  Oportunidade,
  Municipio,
  Response,
  Status,
  Arquivo,
} from '../_models';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss'],
})
export class ContratoComponent implements OnInit {
  estados: Estado[];
  municipios: Municipio[];
  bairros: Bairro[];
  imoveis: Imovel[];

  contratos: Contrato[] = [];
  loading: boolean = true;
  pesquisa: string = '';
  status: string = '1';
  formulario: FormGroup;
  formularioIntencao: FormGroup;

  contratoSelecionado: Contrato;

  modalRef: BsModalRef;
  intensoesCadastradas: Oportunidade[] = [];
  intencoes: Oportunidade[] = [];
  statusOportunidade: Status[] = [];

  detalhesAssinatura: any;

  selectedFile?: File;
  fileName: string = 'Escolha o arquivo';
  enviandoContrato = false;
  enviandoParaAssinatura = [];

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private confimation: ConfirmationService
  ) {}

  onChangeFilter() {
    this.contratos = [];
    this.carregarContratos();
  }

  ngOnInit() {
    this.onChangeFilter();

    this.formulario = this.formBuilder.group({
      pesquisa: [''],
      estadoId: [null],
      municipioId: [null],
      bairroId: [null],
    });

    this.formularioIntencao = this.formBuilder.group({
      statusId: [null],
      observacao: [''],
    });

    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((res) => (this.estados = res.data));

    this.restAngular
      .one('intencaoCompra/status')
      .get()
      .subscribe((res) => (this.statusOportunidade = res.data));

    this.formulario.get('bairroId').disable();
    this.formulario.get('municipioId').disable();
  }

  onChangeEstado() {
    this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restAngular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restAngular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((bairros) => {
        this.bairros = bairros.data;
        this.formulario.get('bairroId').enable();
      });
  }

  editarContrato(id: number) {
    this.router.navigate(['/update-contrato', id]);
  }

  novoContrato() {
    this.router.navigate(['/create-contrato']);
  }

  cancelarContrato(id: number) {
    this.confimation
      .create('Atenção', 'Deseja realmente cancelar esse contrato?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('contrato/cancelar/', id)
            .customPUT()
            .subscribe(
              () => {
                this.notifier.notify(
                  'success',
                  'Contrato cancelado com sucesso'
                );
                this.carregarContratos();
              },
              () => {
                this.notifier.notify('error', 'Erro ao cancelar contrato');
              }
            );
        }
      });
  }

  carregarContratos() {
    this.loading = true;

    if (this.status == undefined) this.status = '';

    this.restAngular
      .one('contrato')
      .get({ nome: this.pesquisa, status: this.status })
      .subscribe(
        (response) => {
          this.contratos = response.data.map((contrato) => {
            return {
              ...contrato,
              status: this.definirStatus(
                contrato.statusVigencia,
                contrato.cancelado
              ),
              showIntencoes: false,
            };
          });

          this.loading = false;
        },
        () => {
          this.notifier.notify('error', 'Erro ao carregar contratos');
          this.loading = false;
        }
      );
  }

  carregarImoveis() {
    const estadoId = this.formulario.value.estadoId
      ? this.formulario.value.estadoId
      : 0;
    const municipioId = this.formulario.value.municipioId
      ? this.formulario.value.municipioId
      : 0;
    const bairroId = this.formulario.value.bairroId
      ? this.formulario.value.bairroId
      : 0;
    const pesquisa = this.formulario.value.pesquisa
      ? this.formulario.value.pesquisa
      : '';

    this.restAngular
      .one('imovel/interesse')
      .get({ estadoId, municipioId, bairroId, pesquisa })
      .subscribe(
        (response) => {
          this.imoveis = response.data;
        },
        () => {
          this.notifier.notify('error', 'Erro ao carregar imóveis');
        }
      );
  }

  openModal(template, contrato: Contrato) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    this.contratoSelecionado = contrato;
  }

  visualizarOportunidades(contrato) {
    contrato.showIntencoes = true;
    this.contratoSelecionado = contrato;
    this.restAngular
      .one(`intencaoCompra/contrato/${contrato.contratoId}/detalhado`)
      .get()
      .subscribe((res: Response<Oportunidade[]>) => {
        this.intencoes = res.data;

        this.intencoes.forEach((intencao) => {
          intencao.showDetail = false;
        });
      });
  }

  verStatusAssinatura(template, contratoId: number) {
    this.restAngular
      .one(`contrato/${contratoId}`)
      .get()
      .subscribe((res: Response<any>) => {
        this.detalhesAssinatura = res.data;
        this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
      });
  }

  showDetails = (item: Oportunidade) => {
    this.router.navigate(['oportunidade', item.contratoId], {
      queryParams: {
        imovelId: item.imovelId,
        oportunidadeId: item.intencaoCompraId,
      },
    });
  };

  salvarIntencao(intencao) {
    const body = {
      statusId: this.formularioIntencao.value.statusId,
      observacao: this.formularioIntencao.value.observacao,
      oportunidadeId: intencao.intencaoCompraId,
    };

    this.restAngular
      .all('intencaoCompra/atualizar')
      .post(body)
      .subscribe(
        () => {
          this.notifier.notify(
            'success',
            'Intenção de compra atualizada com sucesso'
          );
          this.modalService.hide(1);

          this.formularioIntencao.reset();
        },
        () => {
          this.notifier.notify('error', 'Erro ao atualizar oportunidade');
        }
      );
  }

  hasOportunidade(imovelId: number) {
    return this.intensoesCadastradas.some(
      (intencao) => intencao.imovelId === imovelId
    );
  }

  adicionarOportunidade(imovelId: number) {
    const body = {
      imovelId,
      contratoId: this.contratoSelecionado.contratoId,
      usuarioId: 0,
    };

    this.restAngular
      .all('intencaoCompra')
      .post(body)
      .subscribe(
        () => {
          this.notifier.notify(
            'success',
            'Intenção de compra adicionada com sucesso'
          );
          this.modalService.hide(1);
        },
        (e) => {
          if (e.data.Message.includes('já foi adicionado')) {
            this.notifier.notify('error', e.data.Message);
          } else {
            this.notifier.notify('error', 'Erro ao adicionar oportunidade');
          }
        }
      );
  }

  definirStatus(vigencia: boolean, cancelado: boolean) {
    if (cancelado) {
      return { descricao: 'Cancelado', classe: 'badge badge-danger' };
    } else if (!vigencia) {
      return { descricao: 'Inativo', classe: 'badge badge-danger' };
    } else {
      return { descricao: 'Ativo', classe: 'badge badge-success' };
    }
  }

  visualizarContrato(id: number) {
    this.restAngular
      .one('contrato/gerar/', id)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .subscribe((fileBlob: Blob) => {
        const file = new Blob([fileBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileName = this.selectedFile.name;
    } else {
      this.fileName = 'Escolha o arquivo';
    }
  }

  onUpload() {
    if (this.selectedFile) {
      this.enviandoContrato = true;

      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        const arquivo = {
          Nome: this.selectedFile.name,
          Tipo: this.selectedFile.type,
          Tamanho: this.selectedFile.size,
          Base64: base64,
        };
        this.restAngular
          .all('contrato/uploadContratoAssinado')
          .post({
            arquivo,
            contratoId: this.contratoSelecionado.contratoId,
          })
          .subscribe(
            (response) => {
              this.notifier.notify('success', 'upload realizado com sucesso');
              this.carregarContratos();
              this.modalRef.hide();
              this.enviandoContrato = false;
            },
            (error) => {
              this.notifier.notify('error', 'erro ao fazer upload do arquivo');
              this.enviandoContrato = false;
            }
          );
      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      console.error('No file selected');
    }
  }

  downloadContrato(arquivo: any) {
    this.restAngular
      .one('arquivo', arquivo.arquivoId)
      .one('download')
      .get()
      .subscribe(
        (res) => {
          window.open(res.data.urlArquivo, '_blank');
        },
        (err) => {
          console.error('Erro ao baixar o arquivo', err);
        }
      );
  }

  getStatusClass() {
    switch (this.detalhesAssinatura.statusAssinatura) {
      case 0:
        return 'text-warning'; // Aguardando
      case 1:
        return 'text-success'; // Finalizado
      case 2:
        return 'text-danger'; // Cancelado
      default:
        return '';
    }
  }

  verificarEnvio(contratoId) {
    return this.enviandoParaAssinatura.some((x) => x == contratoId);
  }

  enviarAssinatura(contratoId: number) {
    this.confimation
      .create(
        'Atenção',
        'Deseja realmente enviar esse contrato para assinatura?'
      )
      .subscribe((ans) => {
        if (ans.resolved) {
          this.enviandoParaAssinatura.push(contratoId);
          this.restAngular
            .all('contrato/enviarAssinatura')
            .post({ contratoId })
            .subscribe(
              (res) => {
                this.notifier.notify(
                  'success',
                  'Enviado para assinatura com sucesso!'
                );
                this.enviandoParaAssinatura.splice(
                  this.enviandoParaAssinatura.indexOf(contratoId),
                  1
                );
                this.carregarContratos();
              },
              (err) => {
                this.enviandoParaAssinatura.splice(
                  this.enviandoParaAssinatura.indexOf(contratoId),
                  1
                );
                this.notifier.notify('error', 'Erro ao enviar para assinatura');
              }
            );
        }
      });
  }
}
