import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-analise-leilao',
  templateUrl: './analise-leilao.component.html',
  styleUrls: ['./analise-leilao.component.scss'],
})
export class AnaliseLeilaoComponent implements OnInit {
  analises: any[] = [];
  analisesIgnoradas: number[] = [];
  todasSelecionadas: boolean = true;
  countAnalises: number = 0; //gambiarra talvez?
  origem: string = '';
  dataPrimeiraPraca: any = '';
  dataSegundaPraca: any = '';
  status = '1';
  loading: boolean = false;
  loadingConfirm: boolean = false;
  loadingAprovaTudo: boolean = false;
  origens: string[] = [];
  page: number = 1;
  pageSearch: number;

  constructor(
    private restangular: Restangular,
    private notifer: NotifierService,
    private localeService: BsLocaleService,
    private confirmation: ConfirmationService
  ) {
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.getFilterLocalStorage();
    this.getAnalises();
  }

  getAnalises() {
    this.loading = true;

    let dataPrimeiraPraca = this.dataPrimeiraPraca;
    let dataSegundaPraca = this.dataSegundaPraca;

    if (
      this.dataPrimeiraPraca === 'Data inválida' ||
      this.dataPrimeiraPraca == null ||
      this.dataPrimeiraPraca == 'null'
    )
      dataPrimeiraPraca = '';
    else if (this.dataPrimeiraPraca)
      dataPrimeiraPraca = moment
        .utc(this.dataPrimeiraPraca)
        .format('YYYY-MM-DD');

    if (
      this.dataSegundaPraca === 'Data inválida' ||
      this.dataSegundaPraca == null ||
      this.dataPrimeiraPraca == 'null'
    )
      dataSegundaPraca = '';
    else if (this.dataSegundaPraca)
      dataSegundaPraca = moment.utc(this.dataSegundaPraca).format('YYYY-MM-DD');

    this.restangular
      .one('analiseLeilao')
      .get({
        origem: this.origem,
        dataPrimeiraPraca: dataPrimeiraPraca,
        dataSegundaPraca: dataSegundaPraca,
        page: this.page || 1,
        status: this.status || '',
      })
      .subscribe((response) => {
        this.countAnalises = response.data.count;

        const idsIgnorados = this.getIdsIgnoradosFromLocalStorage();

        if (idsIgnorados.length > 0) this.todasSelecionadas = false;

        const analises = response.data.analises.map((analise) => {
          return {
            ...analise,
            selecionado: !idsIgnorados.includes(analise.analiseLeilaoId),
            fotos: analise.fotos || [],
          };
        });

        this.analises = analises;

        this.loading = false;

        this.origens = response.data.origens;

        this.loading = false;
      });
  }

  confirmSelected(todos: boolean) {
    this.confirmation
      .create(
        'Atenção',
        `Deseja realmente aprovar${todos ? ' <b>TODOS</b> ' : ' '}os imóveis?`
      )
      .subscribe((ans) => {
        if (ans.resolved) {
          const analisesSelecionadas = this.analises.filter(
            (analise) => analise.selecionado
          );

          const analisesNaoSelecionadas = this.analises.filter(
            (analise) => !analise.selecionado
          );

          const ids = analisesSelecionadas.map(
            (analise) => analise.analiseLeilaoId
          );
          const idsIgnorados = analisesNaoSelecionadas.map(
            (analise) => analise.analiseLeilaoId
          );

          this.loadingAprovaTudo = todos;
          this.loadingConfirm = !todos;

          this.restangular
            .all('analiseLeilao/aprovar')
            .post({ ids, idsIgnorados, aprovarTudo: todos })
            .subscribe(
              () => {
                this.loadingConfirm = false;
                this.loadingAprovaTudo = false;
                this.notifer.notify(
                  'success',
                  'Análises confirmadas com sucesso!'
                );
                this.getAnalises();
                this.removeIdsIgnorados();
              },
              () => {
                this.loadingConfirm = false;
                this.loadingAprovaTudo = false;
                this.notifer.notify('error', 'Erro ao confirmar análises');
                this.getAnalises();
              }
            );
        }
      });
  }

  selectAllAnalises() {
    this.todasSelecionadas = !this.todasSelecionadas;

    if (this.todasSelecionadas) {
      this.analises = this.analises.map((analise) => {
        return { ...analise, selecionado: true };
      });
    } else {
      this.analises = this.analises.map((analise) => {
        return { ...analise, selecionado: false };
      });
    }

    this.saveIdsIgnorados();
  }

  hasNoItemSelected() {
    return this.analises.filter((analise) => analise.selecionado).length === 0;
  }

  verifyAllItems() {
    const allNotSelected = this.analises.filter((x) => !x.selecionado);
    this.todasSelecionadas = allNotSelected.length === 0;

    this.saveIdsIgnorados();
  }

  onChangeFilter() {
    this.analises = [];
    this.page = 1;
    this.saveFilterLocalStorage();

    this.getAnalises();
  }

  reprovar(analiseId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente aprovar os imóveis?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restangular
            .one('analiseLeilao/reprovar', analiseId)
            .remove()
            .subscribe(
              () => {
                this.notifer.notify('success', 'Análise reprovada com sucesso');
                this.getAnalises();
                this.removeOneIdIgnorado(analiseId);
              },
              () => {
                this.notifer.notify('error', 'Erro ao reprovar análise');
              }
            );
        }
      });
  }

  saveFilterLocalStorage() {
    localStorage.setItem(
      'analiseLeilaoFilter',
      JSON.stringify({
        origem: this.origem,
        dataPrimeiraPraca: this.dataPrimeiraPraca,
        dataSegundaPraca: this.dataSegundaPraca,
        status: this.status,
        page: this.page,
      })
    );
  }

  removeFilterLocalStorage() {
    localStorage.removeItem('analiseLeilaoFilter');
  }

  getFilterLocalStorage() {
    const filter = localStorage.getItem('analiseLeilaoFilter');

    if (filter) {
      let { origem, dataPrimeiraPraca, dataSegundaPraca, page, status } =
        JSON.parse(filter);

      if (dataPrimeiraPraca)
        dataPrimeiraPraca = moment(dataPrimeiraPraca).local().toDate();
      if (dataSegundaPraca)
        dataSegundaPraca = moment(dataSegundaPraca).local().toDate();

      this.origem = origem;
      this.dataPrimeiraPraca = dataPrimeiraPraca;
      this.dataSegundaPraca = dataSegundaPraca;
      this.page = page;
      this.status = status;
    }
  }

  loadMoreAnalises(e) {
    this.page = e.page;

    this.removeIdsIgnorados();
    this.saveFilterLocalStorage();
    this.getAnalises();
  }

  getIdsIgnoradosFromLocalStorage() {
    const idsIgnorados = localStorage.getItem('analiseLeilaoIdsIgnorados');

    if (idsIgnorados) {
      return JSON.parse(idsIgnorados) as number[];
    }

    return [];
  }

  saveIdsIgnorados() {
    let idsLocalStorage = this.getIdsIgnoradosFromLocalStorage();
    const idsIgnorados = this.analises
      .filter(
        (x) => !x.selecionado && !idsLocalStorage.includes(x.analiseLeilaoId)
      )
      .map((x) => x.analiseLeilaoId);
    const idsJaIgnorados = this.analises
      .filter(
        (x) => idsLocalStorage.includes(x.analiseLeilaoId) && x.selecionado
      )
      .map((x) => x.analiseLeilaoId);

    for (const id of idsJaIgnorados) {
      idsLocalStorage = idsLocalStorage.filter((x) => x !== id);
    }

    const analiseLeilaoIdsIgnorados = [...idsLocalStorage, ...idsIgnorados];

    localStorage.setItem(
      'analiseLeilaoIdsIgnorados',
      JSON.stringify(analiseLeilaoIdsIgnorados)
    );
  }

  removeIdsIgnorados() {
    localStorage.removeItem('analiseLeilaoIdsIgnorados');
  }

  removeOneIdIgnorado(id: number) {
    const idsIgnorados = this.getIdsIgnoradosFromLocalStorage();

    const ids = idsIgnorados.filter((x) => x !== id);

    localStorage.setItem('analiseLeilaoIdsIgnorados', JSON.stringify(ids));
  }
}
