<div *ngIf="cliente" class="animated fadeIn">
  <div class="card">
    <div class="card-header"><i class="fa fa-user"></i> Dados do Cliente</div>
    <div class="card-block">
      <div class="row">
        <div class="col-2">
          <label><b>Tipo Pessoa</b></label>
          <p>{{ cliente.tipoPessoa.descricao }}</p>
        </div>
        <div class="col-3">
          <label *ngIf="cliente.tipoPessoaId == 2"><b>Razão Social</b></label>
          <label *ngIf="cliente.tipoPessoaId == 1"><b>Nome</b></label>
          <p>{{ cliente.nome }}</p>
        </div>
        <div [ngClass]="cliente.tipoPessoaId == 2 ? 'col-3' : 'col-6'">
          <label><b>N° Documento</b></label>
          <p>{{ cliente.cpfCnpj }}</p>
        </div>
        <div *ngIf="cliente.tipoPessoaId == 2" class="col-3">
          <label><b>Nome Contato</b></label>
          <p>{{ cliente.nomeContato }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label><b>RG</b></label>
          <p>{{ cliente.rg }}</p>
        </div>
        <div class="col-3">
          <label><b>Telefone</b></label>
          <p>{{ cliente.telefone | formatPhone }}</p>
        </div>
        <div class="col-3">
          <label><b>Celular</b></label>
          <p>{{ cliente.celular | formatPhone }}</p>
        </div>
        <div class="col-3">
          <label><b>E-Mail</b></label>
          <p>{{ cliente.email }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label><b>Nacionalidade</b></label>
          <p>{{ cliente.nacionalidade }}</p>
        </div>
        <div class="col-3">
          <label><b>Estado Civil</b></label>
          <p>
            <ng-container [ngSwitch]="cliente.estadoCivil">
              <span *ngSwitchCase="0">Solteiro</span>
              <span *ngSwitchCase="1">Casado</span>
              <span *ngSwitchCase="2">Divorciado</span>
              <span *ngSwitchCase="3">Viúvo</span>
            </ng-container>
          </p>
        </div>
        <div class="col-4">
          <label><b>Profissão</b></label>
          <p>{{ cliente.profissao }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label><b>Endereço</b></label>
          <p>{{ cliente.endereco.enderecoCompleto }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fas fa-file-contract"></i> Contratos
      <button
        (click)="novoContrato()"
        class="btn btn-success float-right"
        tooltip="Novo Contrato"
        *ngIf="!contrato"
      >
        <i class="fas fa-plus-circle fa-lg"></i> Novo Contrato
      </button>
    </div>
    <div class="card-block">
      <div *ngIf="contrato">
        <div class="row">
          <div class="col-3">
            <label><b>Data da Assinatura</b></label>
            <p>
              {{
                contrato.dataAssinatura
                  | amUtc
                  | amLocal
                  | amDateFormat : "DD/MM/yyyy"
              }}
            </p>
          </div>
          <div class="col-3">
            <label><b>Status</b></label>
            <p>
              <span class="badge badge-success" *ngIf="contrato.ativo"
                >ATIVO</span
              >
              <span class="badge badge-danger" *ngIf="!contrato.ativo"
                >INATIVO</span
              >
            </p>
          </div>
          <div class="col-3">
            <label><b>Tipo de Contrato</b></label>
            <p *ngIf="contrato.tipoContrato == 2">Assinatura Digital</p>
            <p *ngIf="contrato.tipoContrato == 1">Assinatura Manual</p>
          </div>
          <div class="col-3">
            <label><b>Ações</b></label>
            <p>
              <a
                (click)="editarContrato(contrato.contratoId)"
                class="mr-1 pointer"
                tooltip="Editar Contrato"
              >
                <i class="fa-lg far fa-edit"></i>
              </a>
              <a
                *ngIf="
                  contrato.tipoContrato == 1 &&
                  contrato.arquivoAssinado == null &&
                  contrato.arquivoAssinado == undefined
                "
                (click)="visualizarContrato(contrato.contratoId)"
                tooltip="Imprimir Contrato"
                class="mr-1 pointer"
              >
                <i class="fa-lg fas fa-print"></i>
              </a>
              <a
                *ngIf="
                  contrato.tipoContrato == 1 &&
                  contrato.arquivoAssinado == null &&
                  contrato.arquivoAssinado == undefined
                "
                (click)="openModal(uploadContratoAssinado, contrato)"
                tooltip="Upload documento assinado"
                class="mr-1 pointer"
              >
                <i class="fas fa-upload"></i>
              </a>
              <a
                *ngIf="
                  contrato.tipoContrato == 1 &&
                  contrato.arquivoAssinado != undefined
                "
                (click)="downloadContrato(contrato.arquivoAssinado)"
                tooltip="Download documento assinado"
                class="mr-1 pointer"
              >
                <i class="fas fa-download"></i>
              </a>
              <a
                *ngIf="contrato.tipoContrato == 2 && contrato.referenciaId"
                (click)="
                  verStatusAssinatura(statusAssinatura, contrato.contratoId)
                "
                tooltip="Ver status da assinatura"
                class="mr-1 pointer"
              >
                <i class="fas fa-signature"></i>
              </a>
              <a
                *ngIf="
                  contrato.tipoContrato == 2 &&
                  !contrato.referenciaId &&
                  !verificarEnvio(contrato.contratoId)
                "
                (click)="enviarAssinatura(contrato.contratoId)"
                tooltip="Enviar para assinatura"
                class="mr-1 pointer"
              >
                <i class="fas fa-file-upload"></i>
              </a>
              <a *ngIf="verificarEnvio(contrato.contratoId)">
                <i class="fa fa-spin fa-spinner"></i>
              </a>
              <a
                (click)="cancelarContrato(contrato.contratoId)"
                tooltip="Cancelar Contrato"
                class="mr-1 pointer"
              >
                <i class="fa-lg far fa-times-circle"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!contrato">
        <div class="col-12">
          <h4 class="text-center text-danger mt-3 mb-3">
            <i class="fa fa-exclamation-triangle"></i> Nenhum contrato ativo!
          </h4>
        </div>
      </div>
      <div class="card" *ngIf="contrato">
        <div class="card-header">
          <i class="fa-lg fas fa-shopping-cart"></i> Oportunidades
          <a
            [routerLink]="['/oportunidade/', contrato.contratoId]"
            class="btn btn-sm btn-success float-right"
            *ngIf="oportunidades.length > 0"
          >
            <i class="fas fa-building"></i> Acicionar Oportunidade
          </a>
        </div>
        <div class="card-block" *ngIf="oportunidades.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th width="5%">Referência</th>
                <th width="25%">Título</th>
                <th width="25%">Local do Imóvel</th>
                <th width="15%">1ª Praça</th>
                <th width="15%">2ª Praça</th>
                <th width="10%">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lotes of oportunidades">
                <td>{{ lotes.imovel.imovelId }}</td>
                <td>
                  <a
                    [routerLink]="['/update-imovel/' + lotes.imovel.imovelId]"
                    title="Visualizar Imóvel"
                    target="_blank"
                  >
                    {{ lotes.imovel.titulo | uppercase }}</a
                  >
                </td>
                <td>
                  <span *ngIf="lotes.imovel.bairro">
                    {{ lotes.imovel.bairro?.nome }} -
                    {{ lotes.imovel.bairro?.municipio?.nome }} -
                    {{ lotes.imovel.bairro?.municipio?.estado?.sigla }}
                  </span>
                </td>
                <td>
                  <span *ngIf="lotes.imovel.pracas[0]">
                    {{
                      lotes.imovel.pracas[0].praca.data
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/yyyy"
                    }}<br />
                    {{
                      lotes.imovel.pracas[0].praca.valor
                        | currency : "BRL" : "symbol" : "1.2-2"
                    }}
                  </span>
                </td>
                <td>
                  <span *ngIf="lotes.imovel.pracas[1]">
                    {{
                      lotes.imovel.pracas[1].praca.data
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/yyyy"
                    }}<br />
                    {{
                      lotes.imovel.pracas[1].praca.valor
                        | currency : "BRL" : "symbol" : "1.2-2"
                    }}
                  </span>
                </td>
                <td>{{ lotes.status.descricao }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="card-block text-center mt-3"
          *ngIf="oportunidades.length == 0"
        >
          <h4 class="text-danger">
            <i class="fa fa-exclamation-triangle"></i> Nenhuma oportunidade
            cadastrada!
          </h4>
          <a
            [routerLink]="['/oportunidade/' + contrato.contratoId]"
            tooltip="Adicionar Oportunidade"
            class="mr-1 pointer"
          >
            Adicionar nova oportunidade
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-funnel-dollar"></i> Faixa de Valor
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-6 text-center">
          <label><b>Valor Inicial</b></label>
          <p>
            {{
              cliente.valorInicial || 0 | currency : "BRL" : "symbol" : "1.2-2"
            }}
          </p>
        </div>
        <div class="col-6">
          <label><b>Valor final</b></label>
          <p>
            {{
              cliente.valorFinal || 0 | currency : "BRL" : "symbol" : "1.2-2"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fas fa-map-marker-alt"></i>&nbsp; Localidade de Interesse
    </div>
    <div class="card-block">
      <table class="table" *ngIf="cliente.localidadesInteresse.length > 0">
        <thead>
          <tr>
            <th width="33%">Estado</th>
            <th width="33%">Cidade</th>
            <th width="33%">Bairro</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let local of cliente.localidadesInteresse">
            <td>{{ local.bairro.municipio.estado.nome }}</td>
            <td>{{ local.bairro.municipio.nome }}</td>
            <td>{{ local.bairro.nome }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row" *ngIf="cliente.localidadesInteresse.length == 0">
        <div class="col-12">
          <h5 class="text-center text-danger">
            <i class="fa fa-exclamation-triangle"></i> Nenhuma localidade de
            interesse adicionada
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-file-earmark-text"
        viewBox="0 0 16 16"
      >
        <path
          d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
        />
        <path
          d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"
        />
      </svg>
      &nbsp;Documentos
    </div>
    <div class="card-block">
      <table class="table">
        <thead>
          <tr>
            <th width="60%">Arquivo</th>
            <th width="40%">Tipo</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of cliente.documentos">
            <tr>
              <td>
                <a [href]="item.arquivo.url">
                  {{ item.arquivo.nome }}
                </a>
              </td>
              <td>
                {{ item.tipoDocumento.descricao }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-comments"></i>
      &nbsp;Observações
    </div>
    <div class="card-block">
      <p>{{ cliente.observacao }}</p>
    </div>
    <div class="card-footer">
      <button class="btn btn-danger" [routerLink]="['/clientes']">
        <i class="fa fa-undo"></i> Voltar
      </button>
    </div>
  </div>
</div>
<!-- Modal Visualizar Status Documento Assinatura -->
<ng-template #statusAssinatura>
  <div class="modal-header">
    <h5 class="modal-title" id="statusModalLabel">Status Assinatura Digital</h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong> Data da Geração do Documento: </strong>
      {{
        detalhesAssinatura.dataCadastro
          | amUtc
          | amLocal
          | amDateFormat : "DD/MM/yyyy"
      }}
    </p>
    <p *ngIf="detalhesAssinatura.statusAssinatura == 1">
      <strong>Data da Assinatura:</strong>
      {{
        detalhesAssinatura.dataAssinatura
          | amUtc
          | amLocal
          | amDateFormat : "DD/MM/yyyy"
      }}
    </p>
    <p>
      <strong> Status da Assinatura digital: </strong>
      <span [ngClass]="getStatusClass()">
        {{
          detalhesAssinatura.statusAssinatura === 0
            ? "Aguardando"
            : detalhesAssinatura.statusAssinatura === 1
            ? "Finalizado"
            : "Cancelado"
        }}
      </span>
    </p>
    <p>
      <a
        *ngIf="detalhesAssinatura.statusAssinatura === 1"
        href="{{ detalhesAssinatura.arquivoAssinado.url }}"
        target="_blank"
        class="btn btn-primary"
      >
        <i class="fas fa-download"></i>
        Download do Contrato Assinado
      </a>
    </p>
  </div>
</ng-template>

<!-- Modal upload contrato assinado -->
<ng-template #uploadContratoAssinado>
  <div class="modal-header">
    <h5 class="modal-title" id="statusModalLabel">Upload contrato assinado</h5>
    <button
      (click)="modalRef?.hide()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="contractFile">Selecione o arquivo do contrato</label>
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="contractFile"
            (change)="onFileSelected($event)"
          />
          <label class="custom-file-label" for="contractFile">{{
            fileName
          }}</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRef?.hide()">
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onUpload()"
      *ngIf="!enviandoContrato"
    >
      Enviar
    </button>
    <button type="button" class="btn btn-primary" *ngIf="enviandoContrato">
      <i class="fa fa-spin fa-spinner"></i> Enviando...
    </button>
  </div>
</ng-template>
