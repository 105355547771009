<form [formGroup]="formulario" *ngIf="formulario" (submit)="onSubimit()">
  <div class="card animated fadeIn">
    <div class="card-header">
      <p class="card-title">
        <i class="far fa-calendar-alt"></i> Atualizar Evento
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6 form-group">
          <label for="descricao">Descricao</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('descricao')"
            id="descricao"
            name="descricao"
            formControlName="descricao"
          />
        </div>
        <div class="col-6 form-group">
          <label for="grupo">Grupo</label>
          <ng-select
            [ngClass]="aplicaCssErro('grupoId')"
            [items]="grupos"
            bindLabel="descricao"
            bindValue="id"
            formControlName="grupoId"
          ></ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6 form-group">
          <label for="possuiDataLimite">Possui Data limite? </label><br />
          <label class="switch switch-text switch-pill switch-primary">
            <input
              type="checkbox"
              id="possuiDataLimite"
              class="switch-input"
              formControlName="possuiDataLimite"
            />
            <span class="switch-label" data-on="Sim" data-off="Não"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="submit"
        class="btn btn-primary saveCancel"
        [disabled]="!formulario.valid"
      >
        <i class="fa fa-save"></i> Salvar
      </button>
      <button type="reset" class="btn btn-danger" [routerLink]="['/eventos']">
        <i class="fa fa-undo"></i> Cancelar
      </button>
    </div>
  </div>
</form>
