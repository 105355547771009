<form [formGroup]="formulario" *ngIf="formulario" (submit)="onSubimit()">
  <div class="card animated fadeIn">
    <div class="card-header">
      <p class="card-title">
        <i class="far fa-user-circle"></i> Dados Pessoais
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-4">
          <label for="celular">Tipo Pessoa</label>
          <select class="form-control" formControlName="tipoPessoaId">
            <option
              *ngFor="let item of tiposPessoa"
              value="{{ item.tipoPessoaId }}"
            >
              {{ item.descricao }}
            </option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="cpfCnpj">Nº Documento</label>
          <input
            *ngIf="formulario.controls['tipoPessoaId'].value == 1"
            type="text"
            class="form-control"
            id="cpfCnpj"
            placeholder="CPF"
            formControlName="cpfCnpj"
            [textMask]="{ mask: maskCpf }"
          />
          <input
            *ngIf="formulario.controls['tipoPessoaId'].value == 2"
            type="text"
            class="form-control"
            id="cpfCnpj"
            placeholder="CNPJ"
            formControlName="cpfCnpj"
            [textMask]="{ mask: maskCnpj }"
          />
        </div>
        <div class="form-group col-4">
          <label for="nome">Nome</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('nome')"
            id="nome"
            placeholder="Nome"
            name="nome"
            formControlName="nome"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            [ngClass]="aplicaCssErro('email')"
            id="email"
            placeholder="E-mail"
            name="email"
            formControlName="email"
          />
        </div>
        <div class="col-6 form-group">
          <label for="celular">Celular</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('celular')"
            id="celular"
            placeholder="Celular"
            name="celular"
            formControlName="celular"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <p class="card-title"><i class="fas fa-building"></i> Dados Bancarios</p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-4">
          <label for="banco">Banco</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('banco')"
            id="banco"
            placeholder="Banco"
            name="banco"
            formControlName="banco"
          />
        </div>
        <div class="form-group col-4">
          <label for="agencia">Agência</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('agencia')"
            id="agencia"
            placeholder="Agência"
            name="agencia"
            formControlName="agencia"
          />
        </div>
        <div class="form-group col-4">
          <label for="conta">Conta</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('conta')"
            id="conta"
            placeholder="Conta"
            name="conta"
            formControlName="conta"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label for="chavePix">Chave Pix</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="aplicaCssErro('chavePix')"
            id="chavePix"
            placeholder="Chave Pix"
            name="chavePix"
            formControlName="chavePix"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="submit"
        class="btn btn-primary mr-2"
        [disabled]="!formulario.valid"
      >
        <i class="fa fa-save"></i> Salvar
      </button>
      <button
        type="reset"
        class="btn btn-danger"
        [routerLink]="['/corretores']"
      >
        <i class="fa fa-undo"></i> Cancelar
      </button>
    </div>
  </div>
</form>
