<div>
	<div class="d-flex align-items-center justify-content-between">
		<label for="depositario">{{ label }}</label>
		<div>
			<a (click)="openModal()"
				title="Adicionar Novo"
				class="text-primary">
				<i class="fas fa-plus pointer mr-2"></i>
			</a>
			<a *ngIf="!isSpinning; else loadingAnimation"
				(click)="refresh($event.target)"
				title="Atualizar Lista"
				class="text-primary">
				<i id="refresh"
					class="fa fa-sync-alt pointer"></i>
			</a>
		</div>
	</div>
	<ng-select [items]="items"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[(ngModel)]="value"
		[multiple]="multiple"
		[disabled]="disabled"
		(ngModelChange)="updateValue($event)"></ng-select>
</div>



<ng-template #loadingAnimation>
	<a class="text-primary">
		<i class="fas fa-sync-alt pointer fa-spin"
			title="Atualizando..."></i>
	</a>
</ng-template>
