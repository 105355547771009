<div class="card animated fadeIn">
  <div class="card-header">
    <div class="card-title d-inline">
      <i class="fas fa-map-signs"></i> Origens
    </div>
    <button
      class="btn btn-success btn-round float-right"
      style="margin-top: 0 !important"
      [disabled]="newOrigem.length < 2"
      (click)="saveNewOrigem()"
    >
      <i class="fa fa-plus-circle"></i> Nova Origem
    </button>
    <input
      type="text"
      class="form-control float-right col-2 mr-3"
      placeholder="Nova Origem"
      [(ngModel)]="newOrigem"
      (keydown.enter)="saveNewOrigem()"
    />
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table
        class="table"
        [mfData]="origens"
        #tableOrigem="mfDataTable"
        [mfRowsOnPage]="5"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="origemId">Id</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="descricao">Descrição</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="tableOrigem.data.length == 0 && !loading">
            <td colspan="2" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="2" class="text-center">Carregando...</td>
          </tr>
          <tr *ngFor="let item of tableOrigem.data; let i = index">
            <td>{{ item.origemId }}</td>
            <td>
              <div class="d-inline">
                <span
                  class="edit-icon"
                  (click)="toggleEditMode(i)"
                  class="mr-2"
                  style="cursor: pointer"
                  ><i class="fa fa-edit"></i
                ></span>
                <input
                  type="text"
                  [(ngModel)]="item.descricao"
                  [ngClass]="{ 'edit-mode': isEditMode(i) }"
                  class="form-control col-4 d-inline"
                  (keydown.enter)="editItem(item)"
                />
              </div>
            </td>
            <td class="text-center" nowrap>
              <a (click)="editItem(item)" tooltip="Editar" class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                (click)="delete(item.origemId)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
