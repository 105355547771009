import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Restangular } from 'ngx-restangular';
import { Corretor, Response, TipoPessoa } from 'app/views/_models';

@Component({
  selector: 'app-update-corretor',
  templateUrl: './update-corretor.component.html',
  styleUrls: ['./update-corretor.component.scss'],
})
export class UpdateCorretorComponent implements OnInit {
  formulario: FormGroup;
  tiposPessoa: TipoPessoa[] = [];
  id: number;

  public maskCnpj: Array<string | RegExp>;
  public maskCpf: (string | RegExp)[];

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.maskCpf = [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
    this.maskCnpj = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  }

  ngOnInit() {
    this.restAngular
      .one('cliente/tipoPessoa')
      .get()
      .subscribe((tiposPessoa) => {
        this.tiposPessoa = tiposPessoa.data;
      });

    this.id = this.activatedRoute.snapshot.params.id;

    this.restAngular
      .one('corretor', this.id)
      .get()
      .subscribe((data: Response<Corretor>) => {
        const corretor = data.data;

        this.formulario = this.formBuilder.group({
          corretorId: [corretor.corretorId],
          tipoPessoaId: [corretor.tipoPessoaId],
          nome: [corretor.nome, Validators.required],
          cpfCnpj: [corretor.cpfCnpj],
          celular: [corretor.celular],
          email: [corretor.email],
          banco: [corretor.banco],
          agencia: [corretor.agencia],
          conta: [corretor.conta],
          chavePix: [corretor.chavePix],
        });
      });
  }

  onSubimit() {
    this.restAngular
      .all('corretor')
      .customPUT(this.formulario.value)
      .subscribe(
        () => {
          this.notifier.notify('success', 'Corretor atualizado com sucesso');
          this.router.navigate(['/corretores']);
        },
        () => {
          this.notifier.notify('error', 'Erro ao cadastrar corretor');
        }
      );
  }

  verificaValidTouched(campo) {
    return (
      !this.formulario.get(campo).valid && this.formulario.get(campo).touched
    );
  }

  aplicaCssErro(campo) {
    return { 'is-invalid': this.verificaValidTouched(campo) };
  }
}
