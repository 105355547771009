import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Restangular } from 'ngx-restangular';
import { Subscription, Subject } from 'rxjs';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';
import { PdfService } from 'app/_services/pdf.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit, OnDestroy {
  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('template', { read: ViewContainerRef }) template;
  data$: Subject<string> = new Subject();

  id;
  filtro: FormGroup;
  notificacao;
  modalRef: BsModalRef;
  tableLog;
  loading = true;
  sub: Subscription[] = [];
  dataEnvio;
  mensagem;
  logsDefault;
  logsExcel;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  page = 1;
  totalLogs = 0;
  dataInicial = null;
  dataFinal = null;
  destinatario = null;
  resumo;
  exportando;
  campanhaInfo;

  constructor(
    private route: ActivatedRoute,
    private restangular: Restangular,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private pdfService: PdfService,
    private localeService: BsLocaleService,
    private sanitizer: DomSanitizer,
    private notifierService: NotifierService
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.filtro = this.formBuilder.group({
      data: [],
      email: [null],
      comErro: [''],
      meioNotificacao: [''],
      aberto: [''],
      clicado: [''],
    });
    this.modalService.onShown.subscribe(() => {
      const iframe = document.getElementById('iframe');
      this.setIframeReady(iframe);
    });
    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.applyLocale();
    this.getInfoCampanha();
    this.getLogs();
    this.getResumo();
  }

  getWidthProgressBar(value) {
    return this.sanitizer.bypassSecurityTrustStyle(
      `width: ${value.replace(',', '.')}`
    );
  }

  getResumo() {
    this.sub.push(
      this.restangular
        .one(`Campanha/ResumoLog`)
        .get({
          campanhaId: this.id,
        })
        .subscribe((dados) => {
          this.resumo = dados.data;
        })
    );
  }

  getInfoCampanha() {
    this.restangular
      .one(`campanha/${this.id}`)
      .get({ campanhaid: this.id })
      .subscribe((response) => {
        this.campanhaInfo = response.data;
      });
  }

  getLogs() {
    this.sub.push(
      this.restangular
        .one(`campanha/logs`)
        .get({
          CampanhaId: this.id,
          DataInicial: this.dataInicial ? this.dataInicial : '',
          DataFinal: this.dataFinal ? this.dataFinal : '',
          Destinatario: this.destinatario ? this.destinatario : '',
          ComErro: this.filtro.get('comErro').value
            ? this.filtro.get('comErro').value
            : '',
          Aberto: this.filtro.get('aberto').value
            ? this.filtro.get('aberto').value
            : '',
          Clicado: this.filtro.get('clicado').value
            ? this.filtro.get('clicado').value
            : '',
          MeioNotificacao: this.filtro.get('meioNotificacao').value,
          PageSize: 10,
          PageNumber: this.page,
        })
        .subscribe(
          (dados) => {
            this.tableLog = dados.data;
            this.totalLogs = dados.totalRecords;
            this.loading = false;
          },
          () => (this.loading = false)
        )
    );
  }

  loadLogs(e) {
    this.page = e.page;
    this.getLogs();
  }

  openModal(template: TemplateRef<any>, mensagem) {
    this.mensagem = mensagem;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  onValueChange(event, campo) {
    this.filtro.get(campo).markAsTouched();
    this.filtro.get(campo).setValue(event);
    this.filtrar();
  }

  filtrar() {
    const datas = this.filtro.controls['data'].value;
    this.dataInicial = null;
    this.dataFinal = null;
    if (datas && datas.length > 1) {
      this.dataInicial = moment(datas[0]).toISOString();
      this.dataFinal = moment(datas[1]).toISOString();
    }
    this.getLogs();
  }

  onSearch() {
    this.destinatario = '';
    const email = this.filtro.controls['email'].value;
    if (email.length >= 3) {
      this.destinatario = email.toLowerCase();
    }
    this.getLogs();
  }

  exportAsExcel() {
    this.exportando = true;
    this.restangular
      .one(`campanha/exportarlogs`)
      .withHttpConfig({ responseType: 'blob' })
      .get({
        campanhaId: this.id,
        DataInicial: this.dataInicial ? this.dataInicial : '',
        DataFinal: this.dataFinal ? this.dataFinal : '',
        Destinatario: this.destinatario ? this.destinatario : '',
        ComErro: this.filtro.get('comErro').value
          ? this.filtro.get('comErro').value
          : '',
        MeioNotificacao: this.filtro.get('meioNotificacao').value,
        pageNumber: this.page,
        pageSize: 10,
      })
      .subscribe(
        (response) => {
          const blob = new Blob([response], { type: 'application/xlsx' });
          fileSaver.saveAs(blob, `LogsCampanha.xlsx`);
          this.exportando = false;
        },
        () => {
          this.exportando = false;
          this.notifierService.notify(
            'error',
            'Não foi possivel fazer o download!'
          );
        }
      );
  }

  setIframeReady(iframe) {
    const win: Window = iframe.contentWindow;
    const doc: Document = win.document;
    doc.open();
    doc.write(this.mensagem);
  }

  applyLocale() {
    this.localeService.use('pt-br');
  }

  ngOnDestroy(): void {
    this.sub.forEach((s) => s.unsubscribe());
  }
}
