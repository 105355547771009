export const navigation = [
  {
    title: true,
    name: 'OPERAÇÃO',
    grupo: 'OPERACAO',
  },
  {
    name: 'Imóveis',
    url: '/imoveis',
    icon: 'fa fa-building',
    source: 'IMOVEL',
    grupo: 'CADASTROS',
  },
  {
    name: 'Contatos',
    url: '/contato',
    icon: 'fas fa-user-circle',
    source: 'CONTATO',
  },
  {
    name: 'Clientes',
    url: '/clientes',
    icon: 'fas fa-user',
    source: 'CLIENTE',
  },
  {
    name: 'Contratos',
    url: '/contratos',
    icon: 'fas fa-file-contract',
    source: 'CONTRATO',
  },
  {
    name: 'Análise de Imóveis',
    url: '/analise-imovel',
    icon: 'fa fa-building',
    source: 'ANALISE_IMOVEL',
  },
  {
    title: true,
    name: 'MARKETING',
    grupo: 'MARKETING',
  },
  {
    name: 'Templates',
    url: '/template',
    icon: 'fa fa-code',
    source: 'MARKETINGL',
  },
  {
    name: 'Campanhas',
    url: '/campanha',
    icon: 'fas fa-envelope-square',
    source: 'MARKETING',
  },
  {
    title: true,
    name: 'RELATÓRIOS',
    grupo: 'RELATORIOS',
  },
  {
    name: 'Relatório de Imoveis',
    url: '/relatorios-imovel',
    icon: 'fas fa-file-alt',
    source: 'RELATORIOS',
  },
  {
    title: true,
    name: 'CADASTROS',
    grupo: 'CADASTROS',
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    icon: 'fa fa-user',
    source: 'USUARIO',
    grupo: 'CADASTROS',
  },
  {
    name: 'Perfis',
    url: '/perfil',
    icon: 'fa fa-users',
    source: 'PERFIL',
    grupo: 'CADASTROS',
  },
  {
    name: 'Campos',
    url: '/campos',
    icon: 'fas fa-list-ul',
    source: 'CAMPO',
  },
  {
    name: 'Tipo de Imóvel',
    url: '/tipo-imovel',
    icon: 'fa fa-cog',
    source: 'TIPO',
  },
  {
    name: 'Tipo de Pregão',
    url: '/pregoes',
    icon: 'fas fa-gavel',
    source: 'PREGAO',
  },
  {
    name: 'Modalidades',
    url: '/modalidades',
    icon: 'fas fa-list-ul',
    source: 'MODALIDADE',
  },
  {
    name: 'Instituicoes',
    url: '/instituicoes',
    icon: 'fas fa-building',
    source: 'INSTITUICAO',
  },
  {
    name: 'Corretores',
    url: '/corretores',
    icon: 'fas fa-user-tie',
    source: 'CORRETOR',
  },
  {
    name: 'Documentos',
    url: '/documento',
    icon: 'fas fa-id-card',
    source: 'DOCUMENTO',
  },
  {
    name: 'Bairros',
    url: '/bairros',
    icon: 'fas fa-map-marker-alt',
    source: 'BAIRRO',
  },
  {
    name: 'Checklist',
    url: '/checklist-campos',
    icon: 'fas fa-check-double',
    source: 'CHECKLISTCAMPO',
  },
  {
    name: 'Eventos',
    url: '/eventos',
    icon: 'fa fa-calendar-alt',
    source: 'EVENTOS',
  },
  {
    name: 'Grupo de Evento',
    url: '/grupo-eventos',
    icon: 'fas fa-object-group',
    source: 'EVENTOS',
  },
  {
    name: 'Origens',
    url: '/origens',
    icon: 'fas fa-map-signs',
    source: 'ORIGEM',
  },
];
