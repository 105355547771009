<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <p class="d-inline">
            <i class="fa fa-user"></i>
            &nbsp;Usuários
          </p>
          <button
            type="button"
            class="btn btn-success float-right"
            [routerLink]="['/create-usuario']"
          >
            <i class="fa fa-plus-circle"></i>&nbsp; Novo Usuário
          </button>
        </div>
        <div class="card-block">
          <div class="table-responsive mt-4">
            <table
              class="table table-striped"
              [mfData]="usuarios"
              #mfUsuarios="mfDataTable"
              [mfRowsOnPage]="5"
            >
              <thead>
                <tr>
                  <th>
                    <mfDefaultSorter by="usuarioId">Id</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="nome">Nome</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="login">Login</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="login">Perfil</mfDefaultSorter>
                  </th>
                  <th>
                    <mfDefaultSorter by="dataCadastro"
                      >Data Cadastro</mfDefaultSorter
                    >
                  </th>

                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mfUsuarios.data">
                  <td>{{ item.usuarioId }}</td>
                  <td>{{ item.nome | uppercase }}</td>
                  <td>{{ item.login }}</td>
                  <td>{{ item.perfil }}</td>
                  <td nowrap>
                    {{ item.dataCadastro | amDateFormat : "DD/MM/YYYY HH:mm" }}
                  </td>

                  <td class="text-center" nowrap>
                    <a
                      (click)="edit(item.usuarioId)"
                      tooltip="Editar"
                      class="mr-3 pointer"
                      ><i class="fa-lg far fa-edit"></i
                    ></a>
                    <a
                      (click)="delete(item.usuarioId)"
                      tooltip="Excluir"
                      class="mr-1 pointer"
                      ><i class="fa-lg far fa-times-circle text-danger"></i
                    ></a>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td class="text-center text-muted" colspan="6">
                    <h5>
                      <i class="fa fa-spin fa-spinner"></i>&nbsp;Carregando...
                    </h5>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="9">
                    <mfBootstrapPaginator
                      [rowsOnPageSet]="[5, 10, 25]"
                    ></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #bloquearUsuario>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Motivo do bloqueio</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [formGroup]="formulario" class="col-12">
      <label for="motivo">Motivo</label>
      <textarea
        class="form-control"
        id="motivo"
        rows="3"
        formControlName="motivo"
      ></textarea>
      <button
        [disabled]="formulario.invalid"
        type="button"
        class="btn btn-danger mt-2"
        (click)="bloquear()"
      >
        Bloquear
      </button>
    </div>
  </div>
</ng-template>
