import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Tipo } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.scss'],
})
export class TipoComponent implements OnInit {
  tipos: Tipo[] = [];
  backuptipos: Tipo[] = [];
  loading = false;

  newTipo: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getTipo();
  }

  getTipo() {
    this.loading = true;
    this.restAngular
      .one('tipo')
      .get()
      .subscribe(
        (response: Response<Tipo[]>) => {
          this.tipos = response.data;
          this.backuptipos = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os tipos');
          this.loading = false;
        }
      );
  }

  delete(tipoId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o tipo?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('tipo', tipoId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'tipo excluído com sucesso');
                this.getTipo();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o tipo'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Tipo): boolean {
    return (
      item.descricao !==
        this.backuptipos.find((x) => x.tipoId == item.tipoId).descricao &&
      item.descricao !== ''
    );
  }

  saveNewTipo() {
    this.restAngular
      .all('tipo')
      .post({ descricao: this.newTipo })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'tipo cadastrado com sucesso');
          this.getTipo();
          this.newTipo = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o tipo');
        }
      );
  }

  editItem(item: Tipo) {
    this.restAngular
      .one('tipo')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'tipo editado com sucesso');
          this.getTipo();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o tipo');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
