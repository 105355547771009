import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnonyGuard, AuthGuard } from './_guards/index';

// Import Containers
import { FullLayoutComponent } from './containers';

import {
  LoginComponent,
  AccessDeniedComponent,
  UsuariosComponent,
  UpdateUsuariosComponent,
  CreateUsuariosComponent,
  HomeComponent,
  PerfilComponent,
  ImovelComponent,
  CreateImovelComponent,
  UpdateImovelComponent,
  CampoComponent,
  InteresseComponent,
  PregaoComponent,
  InstituicaoComponent,
  ModalidadeComponent,
  ClienteComponent,
  CreateClienteComponent,
  EditClienteComponent,
  CorretorComponent,
  CreateCorretorComponent,
  UpdateCorretorComponent,
  ContratoComponent,
  CreateContratoComponent,
  UpdateContratoComponent,
  UpdateContatoComponent,
  ContatoComponent,
  AnaliseLeilaoComponent,
  TipoComponent,
  EditAnaliseLeilaoComponent,
  BairroComponent,
  CreateBairroComponent,
  EditBairroComponent,
  OportunidadeComponent,
  ChecklistCampoComponent,
  CreateCheckListCampoComponent,
  UpdateChecklistCampoComponent,
  StatusIntencaoCompraComponent,
  CreateStatusIntencaoCompraComponent,
  UpdateStatusIntencaoCompraComponent,
  StatusIntencaoCompraGrupoComponent,
  DocumentoComponent,
  CreateContatoComponent,
} from './views';

import { SourceGuard } from './_guards/source.guard';
import { OrigemComponent } from './views/origem/origem.component';
import { VisualizarClienteComponent } from './views/cliente/visualizar-cliente/visualizar-cliente.component';
import { VisualizarContatoComponent } from './views/contato/visualizar-contato/visualizar-contato.component';
import { TemplateComponent } from './views/template/template.component';
import { UpdateTemplateComponent } from './views/template/update-template/update-template.component';
import { CreateTemplateComponent } from './views/template/create-template/create-template.component';
import { CampanhaComponent } from './views/campanha/campanha.component';
import { UpdateCampanhaComponent } from './views/campanha/update-campanha/update-campanha.component';
import { CreateCampanhaComponent } from './views/campanha/create-campanha/create-campanha.component';
import { LogsComponent } from './views/campanha/logs/logs.component';
import { RelatoriosImovelComponent } from './views/relatorios-imovel/relatorios-imovel.component';
export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'home',
        component: HomeComponent,
        data: {
          title: 'Home',
        },
        canActivate: [AnonyGuard],
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        data: {
          title: 'Usuários',
          source: 'USUARIO',
        },
        canActivate: [SourceGuard],
      },
      {
        path: 'create-usuario',
        component: CreateUsuariosComponent,
        data: {
          title: 'Novo Usuário',
          source: 'USUARIO',
        },
        canActivate: [SourceGuard],
      },
      {
        path: 'update-usuarios/:id',
        component: UpdateUsuariosComponent,
        data: {
          title: 'Editar Usuário',
          source: 'USUARIO',
        },
        canActivate: [SourceGuard],
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: {
          title: 'Acesso Negado',
        },
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        data: {
          title: 'Perfis',
          source: 'PERFIL',
        },
      },
      {
        path: 'imoveis',
        component: ImovelComponent,
        data: {
          title: 'Imóveis',
          source: 'IMOVEL',
        },
      },
      {
        path: 'create-imovel',
        component: CreateImovelComponent,
        data: {
          title: 'Novo Imóvel',
          source: 'IMOVEL',
        },
      },
      {
        path: 'update-imovel/:id',
        component: UpdateImovelComponent,
        data: {
          title: 'Editar Imóvel',
          source: 'IMOVEL',
        },
      },
      {
        path: 'relatorios-imovel',
        component: RelatoriosImovelComponent,
        data: {
          title: 'Relatórios de Imóveis',
          source: 'IMOVEL',
        },
      },
      {
        path: 'campos',
        component: CampoComponent,
        data: {
          title: 'Campos',
          source: 'CAMPO',
        },
      },
      {
        path: 'interesses',
        component: InteresseComponent,
        data: {
          title: 'Interesses',
          source: 'INTERESSE',
        },
      },
      {
        path: 'pregoes',
        component: PregaoComponent,
        data: {
          title: 'Pregões',
          source: 'PREGAO',
        },
      },
      {
        path: 'instituicoes',
        component: InstituicaoComponent,
        data: {
          title: 'Instituicões',
          source: 'INSTITUICAO',
        },
      },
      {
        path: 'modalidades',
        component: ModalidadeComponent,
        data: {
          title: 'Modalidades',
          source: 'MODALIDADE',
        },
      },
      {
        path: 'tipo-imovel',
        component: TipoComponent,
        data: {
          title: 'Tipo de Imóvel',
          source: 'TIPO',
        },
      },
      {
        path: 'clientes',
        component: ClienteComponent,
        data: {
          title: 'Clientes',
          source: 'CLIENTE',
        },
      },
      {
        path: 'create-cliente',
        component: CreateClienteComponent,
        data: {
          title: 'Novo Cliente',
          source: 'CLIENTE',
        },
      },
      {
        path: 'edit-cliente/:id',
        component: EditClienteComponent,
        data: {
          title: 'Editar Cliente',
          source: 'CLIENTE',
        },
      },
      {
        path: 'visualizar-cliente/:id',
        component: VisualizarClienteComponent,
        data: {
          title: 'Visualizar Cliente',
          source: 'CLIENTE',
        },
      },
      {
        path: 'corretores',
        component: CorretorComponent,
        data: {
          title: 'Corretores',
          source: 'CORRETOR',
        },
      },
      {
        path: 'create-corretor',
        component: CreateCorretorComponent,
        data: {
          title: 'Novo Corretor',
          source: 'CORRETOR',
        },
      },
      {
        path: 'documento',
        component: DocumentoComponent,
        data: {
          title: 'Novo Documento',
          source: 'DOCUMENTO',
        },
      },
      {
        path: 'update-corretor/:id',
        component: UpdateCorretorComponent,
        data: {
          title: 'Editar Corretor',
          source: 'CORRETOR',
        },
      },
      {
        path: 'contratos',
        component: ContratoComponent,
        data: {
          title: 'Contratos',
          source: 'CORRETOR',
        },
      },
      {
        path: 'create-contrato',
        component: CreateContratoComponent,
        data: {
          title: 'Novo Contrato',
          source: 'CORRETOR',
        },
      },
      {
        path: 'update-contrato/:id',
        component: UpdateContratoComponent,
        data: {
          title: 'Editar Contrato',
          source: 'CORRETOR',
        },
      },
      {
        path: 'create-contato',
        component: CreateContatoComponent,
        data: {
          title: 'Adicionar Contato',
          source: 'CONTATO',
        },
      },
      {
        path: 'update-contato/:id',
        component: UpdateContatoComponent,
        data: {
          title: 'Editar Contato',
          source: 'CONTATO',
        },
      },
      {
        path: 'contato',
        component: ContatoComponent,
        data: {
          title: 'Contatos',
          source: 'CONTATO',
        },
      },
      {
        path: 'visualizar-contato/:id',
        component: VisualizarContatoComponent,
        data: {
          title: 'Visualizar Contato',
          source: 'CONTATO',
        },
      },
      {
        path: 'analise-imovel',
        component: AnaliseLeilaoComponent,
        data: {
          title: 'Analise de Imóveis',
          source: 'ANALISE_LEILAO',
        },
      },
      {
        path: 'edit-leilao/:id',
        component: EditAnaliseLeilaoComponent,
        data: {
          title: 'Editar Analise Leilao',
          source: 'ANALISE_LEILAO',
        },
      },
      {
        path: 'bairros',
        component: BairroComponent,
        data: {
          title: 'Bairros',
          source: 'BAIRROS',
        },
      },
      {
        path: 'create-bairro',
        component: CreateBairroComponent,
        data: {
          title: 'Novo Bairro',
          source: 'BAIRRO',
        },
      },
      {
        path: 'edit-bairro/:id',
        component: EditBairroComponent,
        data: {
          title: 'Editar Bairro',
          source: 'BAIRRO',
        },
      },
      {
        path: 'oportunidade/:id',
        component: OportunidadeComponent,
        data: {
          title: 'Oportunidade',
          source: 'INTENCAO_COMPRA',
        },
      },
      {
        path: 'checklist-campos',
        component: ChecklistCampoComponent,
        data: {
          title: 'Checklist - Análise Jurídica',
          source: 'CHECKLISTCAMPO',
        },
      },
      {
        path: 'create-checklist-campo',
        component: CreateCheckListCampoComponent,
        data: {
          title: 'Novo Campo',
          source: 'CHECKLISTCAMPO',
        },
      },
      {
        path: 'update-checklist-campo/:id',
        component: UpdateChecklistCampoComponent,
        data: {
          title: 'Atualizar Campo',
          source: 'CHECKLISTCAMPO',
        },
      },
      {
        path: 'template',
        component: TemplateComponent,
        data: {
          title: 'Templates de Email',
          source: 'TEMPLATE',
        },
      },
      {
        path: 'create-template',
        component: CreateTemplateComponent,
        data: {
          title: 'Novo Template',
          source: 'TEMPLATE',
        },
      },
      {
        path: 'update-template/:id',
        component: UpdateTemplateComponent,
        data: {
          title: 'Editar Template',
          source: 'TEMPLATE',
        },
      },
      {
        path: 'campanha',
        component: CampanhaComponent,
        data: {
          title: 'Campanhas',
          source: 'CAMPANHA',
        },
      },
      {
        path: 'update-campanha/:id',
        component: UpdateCampanhaComponent,
        data: {
          title: 'Editar Campanha',
          source: 'CAMPANHA',
        },
      },
      {
        path: 'create-campanha',
        component: CreateCampanhaComponent,
        data: {
          title: 'Criar Campanha',
          source: 'CAMPANHA',
        },
      },
      {
        path: 'eventos',
        component: StatusIntencaoCompraComponent,
        data: {
          title: 'Eventos - Oportunidade',
          source: 'EVENTOS',
        },
      },
      {
        path: 'create-evento',
        component: CreateStatusIntencaoCompraComponent,
        data: {
          title: 'Novo Evento',
          source: 'EVENTOS',
        },
      },
      {
        path: 'update-evento/:id',
        component: UpdateStatusIntencaoCompraComponent,
        data: {
          title: 'Atualizar Evento',
          source: 'EVENTOS',
        },
      },
      {
        path: 'grupo-eventos',
        component: StatusIntencaoCompraGrupoComponent,
        data: {
          title: 'Grupo de Evento',
          source: 'EVENTOS',
        },
      },
      {
        path: 'origens',
        component: OrigemComponent,
        data: {
          title: 'Origem',
          source: 'ORIGEM',
        },
      },
      {
        path: 'logs/:id',
        component: LogsComponent,
        data: {
          title: 'Logs',
          source: 'LOGS',
        },
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
