<div
  class="row d-flex justify-content-center animated fadeIn"
  *ngIf="!imovelViaParametro"
>
  <div class="col-6">
    <label>Pesquise o Imóvel</label>
    <ng-select
      [items]="imoveis"
      [loading]="imoveisLoading"
      [searchFn]="imoveisCustomSearch"
      [(ngModel)]="imovelSelecionadoId"
      (search)="onSearch($event)"
      (change)="onSelectChange($event)"
      bindLabel="titulo"
      bindValue="imovelId"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="search"
      >
        <div style="margin: 10px 0; white-space: normal" class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div>
                  <small class="text-muted"
                    >Referência: {{ item.imovelId }}</small
                  >
                </div>
                <h5 [ngOptionHighlight]="search" class="card-title">
                  {{ item.titulo | uppercase }}
                </h5>
                <h6 class="text-muted" *ngIf="item.bairro">
                  {{ item.bairro?.nome }} - {{ item.bairro?.municipio?.nome }} -
                  {{ item.bairro?.municipio?.estado?.sigla }}
                </h6>
                <span
                  class="badge badge-secondary"
                  *ngIf="
                    item.parecerPositivo == null ||
                    item.parecerPositivo == undefined
                  "
                >
                  SEM PARECER
                </span>
                <span
                  class="badge badge-danger"
                  *ngIf="item.parecerPositivo == false"
                >
                  PARECER NEGATIVO
                </span>
                <span
                  class="badge badge-success"
                  *ngIf="item.parecerPositivo == true"
                >
                  PARECER POSITIVO
                </span>
              </div>
              <div class="col-6">
                <div *ngIf="item.pracas[0]">
                  <h6 class="d-inline">
                    1ª Praça:&nbsp;
                    {{
                      item.pracas[0].praca.data
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/yyyy"
                    }}
                  </h6>
                  <br />
                  <small>
                    {{
                      item.pracas[0].praca.valor
                        | currency : "BRL" : "symbol" : "1.2-2"
                    }}
                  </small>
                </div>
                <div *ngIf="item.pracas[1]">
                  <h6 class="d-inline">
                    2ª Praça:&nbsp;
                    {{
                      item.pracas[1].praca.data
                        | amUtc
                        | amLocal
                        | amDateFormat : "DD/MM/yyyy"
                    }}
                  </h6>
                  <br />
                  <small>
                    {{
                      item.pracas[1].praca.valor
                        | currency : "BRL" : "symbol" : "1.2-2"
                    }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ item.titulo | uppercase }}
      </ng-template>
    </ng-select>
  </div>
</div>
<div class="row mt-3" *ngIf="imovel">
  <div class="col-12">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <img class="card-img" src="{{ imovel.fotos[0].arquivo.url }}" alt />
        </div>
        <div class="col-9">
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="card-title mr-2">
                {{ imovel.titulo | uppercase }}
              </h5>
              <p class="card-text" *ngIf="imovel.bairro">
                {{ imovel.bairro?.nome }} -
                {{ imovel.bairro?.municipio?.nome }} -
                {{ imovel.bairro?.municipio?.estado?.sigla }}
              </p>
            </div>
            <div>
              <span
                class="badge badge-secondary"
                *ngIf="
                  imovel.parecerPositivo == null ||
                  imovel.parecerPositivo == undefined
                "
              >
                SEM PARECER
              </span>
              <span
                class="badge badge-danger"
                *ngIf="imovel.parecerPositivo == false"
              >
                PARECER NEGATIVO
              </span>
              <span
                class="badge badge-success"
                *ngIf="imovel.parecerPositivo == true"
              >
                PARECER POSITIVO
              </span>
            </div>
          </div>
          <p [innerHTML]="imovel.descricao" class="descricao mt-3"></p>
          <div>
            <div *ngIf="imovel.pracas[0]">
              <h5 class="d-inline mr-2">
                1ª Praça:
                {{
                  imovel.pracas[0].praca.data
                    | amUtc
                    | amLocal
                    | amDateFormat : "DD/MM/yyyy"
                }}
              </h5>
              <span>
                {{
                  imovel.pracas[0].praca.valor
                    | currency : "BRL" : "symbol" : "1.2-2"
                }}
              </span>
            </div>
            <div *ngIf="imovel.pracas[1]">
              <h5 class="d-inline mr-2">
                2ª Praça:
                {{
                  imovel.pracas[1].praca.data
                    | amUtc
                    | amLocal
                    | amDateFormat : "DD/MM/yyyy"
                }}
              </h5>
              <span>
                {{
                  imovel.pracas[1].praca.valor
                    | currency : "BRL" : "symbol" : "1.2-2"
                }}
              </span>
            </div>
          </div>
          <div class="mt-3" *ngIf="!imovelViaParametro">
            <button
              (click)="adicionarIntencao()"
              type="button"
              class="btn btn-success"
              *ngIf="imovel"
            >
              <i class="fas fa-cart-plus"></i> Adicionar Oportunidade
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
