import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Interesse } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-interesse',
  templateUrl: './interesse.component.html',
  styleUrls: ['./interesse.component.scss'],
})
export class InteresseComponent implements OnInit {
  interesses: Interesse[] = [];
  backupinteresses: Interesse[] = [];
  loading = false;

  newInteresse: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getInteresse();
  }

  getInteresse() {
    this.loading = true;
    this.restAngular
      .one('interesse')
      .get()
      .subscribe(
        (response: Response<Interesse[]>) => {
          this.interesses = response.data;
          this.backupinteresses = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os interesses');
          this.loading = false;
        }
      );
  }

  delete(interesseId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o interesse?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('interesse', interesseId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'interesse excluído com sucesso');
                this.getInteresse();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o interesse'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Interesse): boolean {
    return (
      item.descricao !==
        this.backupinteresses.find((x) => x.interesseId == item.interesseId).descricao &&
      item.descricao !== ''
    );
  }

  saveNewInteresse() {
    this.restAngular
      .all('interesse')
      .post({ descricao: this.newInteresse })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'interesse cadastrado com sucesso');
          this.getInteresse();
          this.newInteresse = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o interesse');
        }
      );
  }

  editItem(item: Interesse) {
    this.restAngular
      .one('interesse')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'interesse editado com sucesso');
          this.getInteresse();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o interesse');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
