import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'app-create-bairro',
  templateUrl: './create-bairro.component.html',
  styleUrls: ['./create-bairro.component.scss'],
})
export class CreateBairroComponent implements OnInit {
  formulario: FormGroup;
  formularioNewEstado: FormGroup;
  formularioNewMunicipio: FormGroup;

  modalRef: BsModalRef;
  estados: any;
  municipios: any;

  constructor(
    private formBuilder: FormBuilder,
    private restAngular: Restangular,
    private notifier: NotifierService,
    private route: Router
  ) {}

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      nome: ['', Validators.required],
      zona: [''],
      subZona: [''],
      estadoId: [null, Validators.required],
      municipioId: [null, Validators.required],
    });

    this.formularioNewEstado = this.formBuilder.group({
      nome: ['', Validators.required],
      sigla: ['', Validators.required],
    });

    this.formularioNewMunicipio = this.formBuilder.group({
      nome: ['', Validators.required],
      estadoId: [null, Validators.required],
    });

    this.getEstados();
  }

  onSubimit() {
    this.restAngular
      .all('localidade/bairro')
      .post(this.formulario.value)
      .subscribe(
        (response) => {
          this.notifier.notify('success', 'Endereço criado com sucesso');
          this.route.navigate(['/bairros']);
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao criar endereço');
        }
      );
  }

  onSubmitNewEstado() {
    this.restAngular
      .all('localidade/estado')
      .post(this.formularioNewEstado.value)
      .subscribe(
        (response) => {
          this.modalRef.hide();
          this.notifier.notify('success', 'Estado criado com sucesso');
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao criar estado');
        }
      );
  }

  onSubmitNewMunicipio() {
    this.restAngular
      .all('localidade/municipio')
      .post(this.formularioNewMunicipio.value)
      .subscribe(
        (response) => {
          this.modalRef.hide();
          this.notifier.notify('success', 'Municipio criado com sucesso');
        },
        (error) => {
          this.notifier.notify('error', 'Erro ao criar municipio');
        }
      );
  }

  estadoChange(estadoId) {
    this.restAngular
      .one('localidade/municipio')
      .get({ estadoId })
      .subscribe((response) => {
        this.municipios = response.data;
      });
  }

  getEstados() {
    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((response) => {
        this.estados = response.data;
      });
  }

  closeModal() {
    this.modalRef.hide();
  }

  updateModalRef(modalRef: BsModalRef) {
    this.modalRef = modalRef;
  }
}
