var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
var ContatoComponent = /** @class */ (function () {
    function ContatoComponent(restAngular, notifier, confirmation, router, fb, modalService, localeService) {
        this.restAngular = restAngular;
        this.notifier = notifier;
        this.confirmation = confirmation;
        this.router = router;
        this.fb = fb;
        this.modalService = modalService;
        this.localeService = localeService;
        this.contatos = [];
        this.origemcontato = [];
        this.filtrosAplicados = 0;
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.historicoAcompanhamento = [];
        this.exportando = false;
        this.loading = false;
        localeService.use('pt-br');
    }
    ContatoComponent.prototype.ngOnInit = function () {
        this.defaultFormValue = {
            data: [],
            nome: '',
            email: '',
            celular: '',
            origemId: [''],
            estados: [[]],
            municipios: [[]],
            bairros: [[]],
            temperatura: '',
            valorinicial: '',
            valorfinal: '',
        };
        this.filtro = this.fb.group(this.defaultFormValue);
        this.observacaoForm = this.fb.group({
            observacao: ['', Validators.required],
        });
        this.getContato();
        this.getOrigemContato();
    };
    ContatoComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        this.getEstado();
    };
    ContatoComponent.prototype.getEstado = function () {
        var _this = this;
        this.restAngular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
        });
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        if (this.filtro.value.municipios === null ||
            this.filtro.value.municipios.length === 0) {
            this.filtro.get('bairros').disable();
            return;
        }
    };
    ContatoComponent.prototype.contarFiltros = function (values) {
        this.filtrosAplicados = Object.values(values).filter(function (value) {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value;
        }).length;
    };
    ContatoComponent.prototype.onChangeFilter = function () {
        this.contatos = [];
        this.getContato();
        this.contarFiltros(this.filtro.value);
    };
    ContatoComponent.prototype.getContato = function () {
        var _this = this;
        var dateStart = '';
        var dateEnd = '';
        var datas = this.filtro.controls['data'].value;
        if (datas !== null) {
            dateStart = moment(datas[0]).format('YYYY/MM/DD');
            dateEnd = moment(datas[1]).format('YYYY/MM/DD');
        }
        this.restAngular
            .one('contato')
            .get(__assign({ dateStart: dateStart, dateEnd: dateEnd }, this.filtro.value))
            .subscribe(function (response) {
            _this.contatos = response.data;
        }, function (error) {
            _this.notifier.notify('error', 'Não foi possível carregar os contatos');
        });
    };
    ContatoComponent.prototype.edit = function (contatoId) {
        this.router.navigate(['update-contato', contatoId]);
    };
    ContatoComponent.prototype.obterIconeLead = function (temperatura) {
        switch (temperatura) {
            case 'quente':
                return '<i class="fas fas fa-thermometer-full full fa-2x text-danger" title="Quente"></i>';
            case 'medio':
                return '<i class="fas fa-thermometer-half half fa-2x text-warning" title="Médio"></i>';
            case 'frio':
                return '<i class="fas fa-thermometer-empty empty fa-2x text-info" title="Frio"></i>';
            default:
                return '';
        }
    };
    ContatoComponent.prototype.delete = function (contatoId) {
        var _this = this;
        this.confirmation
            .create('Atenção', 'Deseja realmente excluir o contato?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restAngular
                    .one('contato', contatoId)
                    .remove()
                    .subscribe(function (response) {
                    _this.notifier.notify('success', 'Contato excluído com sucesso');
                    _this.getContato();
                }, function (error) {
                    _this.notifier.notify('error', 'Não foi possível excluir o contato');
                });
            }
        });
    };
    ContatoComponent.prototype.promover = function (contatoId) {
        var _this = this;
        this.confirmation
            .create('Atenção', 'Deseja realmente promover o  contato a cliente?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restAngular
                    .one('contato', contatoId)
                    .one('promover')
                    .post()
                    .subscribe(function (response) {
                    _this.notifier.notify('success', 'Contato promovido com sucesso');
                    _this.getContato();
                }, function (error) {
                    _this.notifier.notify('error', 'Não foi possível promover o contato');
                });
            }
        });
    };
    ContatoComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.filtro.get('municipios').disable();
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        this.restAngular
            .one('localidade/municipio')
            .get({ estados: this.filtro.value.estados })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.filtro.get('municipios').enable();
        });
    };
    ContatoComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipios = this.filtro.value.municipios;
        if (municipios === null || municipios.length === 0) {
            this.filtro.get('bairros').disable();
            return;
        }
        if (this.filtro.value.estados === null ||
            this.filtro.value.estados.length === 0) {
            this.filtro.get('estados').markAsTouched();
            this.filtro.get('municipios').disable();
            this.filtro.get('bairros').disable();
            return;
        }
        this.restAngular
            .one('localidade/bairro')
            .get({ municipios: municipios })
            .subscribe(function (res) {
            var bairros = res.data;
            _this.bairros = bairros;
            _this.filtro.get('bairros').enable();
        });
    };
    ContatoComponent.prototype.openModalContato = function (template, contato) {
        this.contatoSelecionado = contato;
        this.loadHistorico(this.contatoSelecionado.contatoId);
        this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    };
    ContatoComponent.prototype.loadHistorico = function (contatoId) {
        var _this = this;
        this.restAngular
            .one('acompanhamentoContato', contatoId)
            .get()
            .subscribe(function (res) {
            _this.historicoAcompanhamento = res.data;
        });
    };
    ContatoComponent.prototype.addObservacao = function () {
        var _this = this;
        if (this.observacaoForm.valid) {
            var observacaoData = __assign({}, this.observacaoForm.value, { contatoId: this.contatoSelecionado.contatoId });
            this.restAngular
                .all('acompanhamentoContato')
                .post(observacaoData)
                .subscribe(function () {
                _this.loadHistorico(_this.contatoSelecionado.contatoId);
                _this.observacaoForm.reset();
            });
        }
    };
    ContatoComponent.prototype.formatPhoneNumber = function (phone) {
        var phoneTratado = phone.replace(/\D/g, '');
        if (!phone.startsWith('5') && phoneTratado.length < 13)
            phoneTratado = '55' + phoneTratado;
        return phoneTratado;
    };
    ContatoComponent.prototype.downloadExcel = function () {
        var _this = this;
        this.exportando = true;
        var dateStart = '';
        var dateEnd = '';
        var datas = this.filtro.controls['data'].value;
        if (datas && datas.length > 1) {
            dateStart = moment(datas[0]).format('YYYY/MM/DD');
            dateEnd = moment(datas[1]).format('YYYY/MM/DD');
        }
        this.restAngular
            .one('contato/exportar')
            .withHttpConfig({ responseType: 'blob' })
            .get(__assign({ dateStart: dateStart, dateEnd: dateEnd }, this.filtro.value))
            .subscribe(function (response) {
            _this.exportando = false;
            var blob = response;
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "RelatorioContatos_" + moment().unix() + ".xlsx";
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }, 100);
        }, function (err) {
            _this.exportando = false;
            _this.notifier.notify('error', 'Erro exportar contatos');
        });
    };
    ContatoComponent.prototype.removerFiltros = function () {
        this.filtro = this.fb.group(this.defaultFormValue);
        this.municipios = [];
        this.bairros = [];
        this.contatos = [];
        this.getContato();
        this.filtrosAplicados = 0;
    };
    ContatoComponent.prototype.visualizar = function (contatoId) {
        this.router.navigate(['visualizar-contato', contatoId]);
    };
    ContatoComponent.prototype.getOrigemContato = function () {
        var _this = this;
        this.restAngular
            .one('origemcontato')
            .get()
            .subscribe(function (response) {
            _this.origemcontato = response.data;
        }, function (error) {
            _this.notifier.notify('error', 'Não foi possível carregar as origens');
        });
    };
    return ContatoComponent;
}());
export { ContatoComponent };
