<div class="app flex-row align-items-center animated fadeIn">
  <div class="container-fluid container-login">
    <div class="row justify-content-center">
      <div class="col">
        <div class="text-center mb-4">
          <img src="./assets/img/logo.png" />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="card-group mb-0">
          <div class="card p-2">
            <div class="card-block">
              <h1>LOGIN</h1>
              <form [formGroup]="loginForm" (ngSubmit)="login()">
                <p class="text-muted">Entre com seu email e senha</p>
                <div
                  class="input-group mb-1"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    placeholder="Login"
                  />
                  <div
                    *ngIf="submitted && f.username.errors"
                    class="help-block"
                  >
                    login é obrigatório
                  </div>
                </div>
                <div
                  class="input-group mb-2"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                >
                  <div class="input-group">
                    <input
                      [type]="fieldTextType ? 'text' : 'password'"
                      class="form-control"
                      formControlName="password"
                    />
                    <div
                      class="input-group-append"
                      (click)="toggleFieldTextType()"
                    >
                      <span class="input-group-text" *ngIf="fieldTextType">
                        <i class="fa fa-eye"></i>
                      </span>
                      <span class="input-group-text" *ngIf="!fieldTextType">
                        <i class="fa fa-eye-slash"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="submitted && f.password.errors"
                    class="help-block"
                  >
                    Senha é obrigatório
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-6">
                    <button
                      type="submit"
                      class="btn btn-primary px-2 form-control"
                      [disabled]="loading"
                    >
                      Entrar
                      <img
                        *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
