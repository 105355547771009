<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col">
              <div class="mt-3 text-center"><i class="fa fa-exclamation-circle fa-5x text-danger"></i></div>
              <h1 class="text-danger text-center">ACESSO NEGADO</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="text-muted text-center mt-3">Você não tem permissão para acessar esta página, entre em contato com o administrador do sistema.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
