<form *ngIf="formulario" (submit)="onSubimit()">
  <div class="card animated fadeIn" [formGroup]="formulario">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header d-flex align-items-center">
              <i class="fas fa-user-circle" aria-hidden="true"></i>
              &nbsp;Informações Principais
            </div>
            <div class="card-block">
              <div class="row">
                <div class="form-group col-8">
                  <label for="nome">Nome *</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nome"
                    formControlName="nome"
                    placeholder="Nome"
                    [ngClass]="aplicaCssErro('nome')"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <label for="telefone">Celular</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefone"
                    formControlName="telefone"
                    placeholder="Telefone"
                    [textMask]="{ mask: maskTelefone }"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="origemcontato">Origem</label>
                  <select
                    class="form-control"
                    id="origemcontato"
                    formControlName="origemId"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let item of origemcontato"
                      [value]="item.origemId"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <label for="comoContactar">Preferência de contato</label>
                  <select
                    class="form-control"
                    id="comoContactar"
                    formControlName="comoContactar"
                  >
                    <option value="Ligação">Ligação</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Email">Email</option>
                    <option value="SMS">SMS</option>
                  </select>
                </div>
              </div>
              <!-- <div class="form-group">
                <label for="mensagem">Mensagem</label>
                <textarea
                  class="form-control"
                  id="mensagem"
                  formControlName="mensagem"
                  placeholder="Mensagem"
                  rows="10"
                ></textarea>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div>
                <i class="fa fa-home"></i>
                &nbsp;Endereço
              </div>
              <div>
                <input
                  type="checkbox"
                  name="hasEndereco"
                  [formControl]="hasEndereco"
                  (change)="addEndereco()"
                />
              </div>
            </div>

            <div
              class="card-block"
              *ngIf="hasEndereco.value"
              formGroupName="endereco"
            >
              <div class="row">
                <div class="form-group col-4">
                  <label for="cep">CEP</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cep"
                    formControlName="cep"
                    (blur)="consultaCEP()"
                    [textMask]="{ mask: maskCep }"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="logradouro">Logradouro</label>
                  <input
                    type="text"
                    class="form-control"
                    id="logradouro"
                    formControlName="logradouro"
                  />
                </div>

                <div class="form-group col-4">
                  <label for="numero">Número</label>
                  <input
                    type="text"
                    class="form-control"
                    id="numero"
                    formControlName="numero"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group col-3">
                  <label for="complemento">Complemento</label>
                  <input
                    type="text"
                    class="form-control"
                    id="complemento"
                    formControlName="complemento"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="bairro">Bairro</label>
                  <input
                    type="text"
                    class="form-control"
                    id="bairro"
                    formControlName="bairro"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="cidade">Cidade</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cidade"
                    formControlName="cidade"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="estado">Estado</label>
                  <input
                    type="text"
                    class="form-control"
                    id="estado"
                    formControlName="estado"
                    maxlength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div>
                <i class="fas fa-funnel-dollar"></i>&nbsp; Faixa de valor
              </div>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="form-group col-6">
                  <label for="telefone">Valor Inicial</label>
                  <input
                    type="text"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                    class="form-control"
                    id="valorInicial"
                    placeholder="Valor Inicial"
                    formControlName="valorInicial"
                  />
                </div>
                <div class="form-group col-6">
                  <label for="celular">Valor Final</label>
                  <input
                    type="text"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                    class="form-control"
                    id="valorFinal"
                    placeholder="Valor Final"
                    formControlName="valorFinal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div>
                <i class="fas fa-map-marker-alt"></i>&nbsp; Localidades de
                Interesse
              </div>
              <div>
                <input
                  type="checkbox"
                  name="hasLocalidadeInteresse"
                  [formControl]="hasLocalidadeInteresse"
                />
              </div>
            </div>
            <div class="card-block" *ngIf="hasLocalidadeInteresse.value">
              <div class="row">
                <div class="form-group col-3">
                  <label for="estadoId">Estado </label>
                  <ng-select
                    [multiple]="false"
                    formControlName="estadoId"
                    (change)="onChangeEstado()"
                    [items]="estados"
                    bindValue="estadoId"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-4">
                  <label for="municipioId">Municipio </label>
                  <ng-select
                    [multiple]="false"
                    formControlName="municipioId"
                    (change)="onChangeMunicipio()"
                    [items]="municipios"
                    bindValue="municipioId"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-4">
                  <label for="titulo">Bairro</label>
                  <select class="form-control" formControlName="bairroId">
                    <ng-container *ngFor="let item of bairrosAgrupados">
                      <ng-container *ngIf="item.children">
                        <optgroup [label]="item.label">
                          <option
                            *ngFor="let bairro of item.children"
                            [ngValue]="bairro.bairroId"
                          >
                            {{ bairro.nome }}
                          </option>
                        </optgroup>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div class="col-1 d-flex align-items-center">
                  <button
                    [disabled]="!formulario.value.bairroId"
                    type="button"
                    class="btn btn-link text-success"
                    tooltip="Adicionar localidade"
                    (click)="addLocalidadeInteresse()"
                  >
                    <i class="fa fa-plus"></i> Adicionar
                  </button>
                </div>
              </div>
              <div
                class="row p-3"
                *ngIf="this.formulario.value.localidadesInteresse.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>Bairro</th>
                      <th>Municipio</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="
                        let item of this.formulario.value.localidadesInteresse;
                        let i = index
                      "
                    >
                      <tr>
                        <td>
                          {{ item.bairro }}
                        </td>
                        <td>
                          {{ item.municipio }}
                        </td>
                        <td>
                          {{ item.estado }}
                        </td>
                        <td class="text-center">
                          <label
                            class="btn btn-danger m-0"
                            (click)="removeLocalidadeInteresse(i)"
                            ><i class="fa fa-trash"></i> Remover</label
                          >
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        [disabled]="formulario.invalid"
        type="submit"
        class="btn btn-primary mr-2"
      >
        <i class="fa fa-save"></i> Salvar
      </button>
      <button type="reset" class="btn btn-danger" [routerLink]="['/contato']">
        <i class="fa fa-undo"></i> Cancelar
      </button>
    </div>
  </div>
</form>
