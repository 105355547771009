import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotifierService } from 'angular-notifier';
import { Modalidade } from 'app/views/_models';

import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Restangular } from 'ngx-restangular';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-create-imovel',
  templateUrl: './create-imovel.component.html',
  styleUrls: ['./create-imovel.component.scss'],
})
export class CreateImovelComponent implements OnInit {
  @ViewChild('inputFotos') inputFotos: ElementRef;
  @ViewChild('inputAnexos') inputAnexos: ElementRef;

  loading: boolean = false;
  formulario: FormGroup;

  interesses: any;
  tipos: any;
  pregaos: any;
  modalidades: Modalidade[];
  campos: any;
  imageError: string;
  fileToUpload: File | null = null;
  hasLocalidade = this.formBuilder.control(false);

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  //fotos
  fotosbase64: any;
  fotosnome: any;
  fotostamanho: any;
  fotostipo: any;
  numeroAdcFoto: number;
  arrayFotos = [];

  //anexos
  anexosbase64: any;
  anexosnome: any;
  anexostamanho: any;
  anexostipo: any;
  numeroAdcAnexo: number;
  arrayAnexos = [];

  local: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Descrição detalhada...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'roboto', name: 'Roboto' },
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    sanitize: true,
  };
  instituicoes: any;

  constructor(
    private restangular: Restangular,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');

    this.formulario = this.formBuilder.group({
      tipoId: [null, Validators.required],
      titulo: [null, Validators.required],
      descricao: [null],
      googleMaps: [null],
      pregaoId: [null, Validators.required],
      pracas: this.formBuilder.array([]),
      linkOrigem: [null],
      interesses: [null],
      campos: this.formBuilder.array([]),
      anexos: this.formBuilder.array([]),
      fotos: this.formBuilder.array([]),
      bairroId: [null],
      municipioId: [3243],
      estadoId: [19],
      endereco: [null],
      modalidadeId: [null, Validators.required],
      numeroProcesso: [null],
      instituicaoId: [null],
    });

    this.formulario.get('titulo').valueChanges.subscribe((value) => {
      if (value) {
        this.formulario
          .get('titulo')
          .setValue(value.toUpperCase(), { emitEvent: false });
      }
    });
  }

  ngOnInit() {
    forkJoin([
      this.restangular.one('interesse').get(),
      this.restangular.one('imovel/tipo').get(),
      this.restangular.one('imovel/pregao').get(),
      this.restangular.one('imovel/campo').get(),
      this.restangular.one('modalidade').get(),
      this.restangular.one('instituicao').get(),
      this.restangular.one('localidade/estado').get(),
    ]).subscribe(
      ([
        interesses,
        tipos,
        pregaos,
        campos,
        modalidades,
        instituicoes,
        estados,
      ]: any) => {
        this.interesses = interesses.data;
        this.tipos = tipos.data;
        this.pregaos = pregaos.data;
        this.campos = campos.data;
        this.modalidades = modalidades.data;
        this.instituicoes = instituicoes.data;
        this.estados = estados.data;

        this.onChangeEstado();
        this.onChangeMunicipio();
      }
    );
  }

  onChangeEstado() {
    const estadoId = this.formulario.value.estadoId;

    if (!estadoId) this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((bairros) => {
        this.bairros = bairros.data;
        this.formulario.get('bairroId').enable();
      });
  }

  onSubmit() {
    if (!this.formulario.valid) {
      Object.keys(this.formulario.controls).forEach((campo) => {
        const controle = this.formulario.get(campo);
        controle.markAsTouched();
      });
      this.notifierService.notify(
        'error',
        'Preencha todos os campos obrigatórios'
      );
      return false;
    }

    // if (!this.hasLocalidade) {
    //   this.formulario.controls.localidade.setValue(null);
    // }

    const formulario = this.formulario.getRawValue();

    this.restangular
      .all('imovel')
      .post(formulario)
      .subscribe(
        (a) => {
          this.notifierService.notify('success', 'Imóvel Criado com sucesso');
          this.router.navigate(['/imoveis']);
        },
        (error) => {
          this.notifierService.notify('error', 'Erro ao Criar o Imóvel!');
        }
      );
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    const arrayImagens = fileInput.target.files.length;

    for (let i = 0; i < arrayImagens; i++) {
      this.arrayFotos.push(fileInput.target.files[i]);
    }

    this.arrayFotos.forEach((x: any) => {
      if (x) {
        // Size Filter Bytes
        const max_size = 5242880;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 15200;
        const max_width = 25600;

        if (x.size > max_size) {
          this.imageError = 'Maximum size allowed is 5Mb';
          this.arrayFotos = [];
          return false;
        }

        // if (!_.includes(allowed_types, x.type)) {
        //   this.arrayFotos = [];
        //   this.imageError = 'Only Images are allowed ( JPG | PNG )';
        //   return false;
        // }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (rs) => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];
            this.arrayFotos = [];

            if (img_height > max_height && img_width > max_width) {
              this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
              return false;
            } else {
              const imgBase64Path = e.target.result;
              const arquivo = {
                url: imgBase64Path,
                nome: x.name,
                base64: imgBase64Path,
                tipo: x.type,
                tamanho: x.size,
              };
              this.arrayFotos = [];
              this.atualizarFoto(arquivo, this.numeroAdcFoto);
            }
          };
        };

        reader.readAsDataURL(x);
      }
    });
  }

  anexoChangeEvent(anexoInput: FileList) {
    this.fileToUpload = anexoInput.item(0);
    this.fileToUpload.name;
    this.fileToUpload.size;
    this.fileToUpload.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.anexosbase64 = reader.result;
      const arquivo = this.formBuilder.group({
        arquivoId: 0,
        nome: [this.fileToUpload.name, Validators.required],
        base64: this.anexosbase64,
        tipo: this.fileToUpload.type,
        tamanho: this.fileToUpload.size,
        dataCadastro: moment().utc().toISOString(),
      });

      this.atualizarAnexo(arquivo, this.numeroAdcAnexo);
    };
  }

  adicionarCampo(imovelCampoId = null) {
    const campos = this.formulario.get('campos') as FormArray;
    campos.push(
      this.formBuilder.group({
        campoId: [imovelCampoId, Validators.required],
        valor: ['', Validators.required],
        acao: 'I',
      })
    );
  }

  atualizarFoto(obj, i) {
    const fotos = this.formulario.get('fotos') as FormArray;

    if (i < 0) {
      fotos.insert(
        0,
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
        })
      );
    } else {
      const valor = fotos.value[i];
      fotos.removeAt(i);
      fotos.insert(
        i,
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
        })
      );
    }
  }

  alterarFoto(i) {
    this.numeroAdcFoto = i;
    this.inputFotos.nativeElement.click();
  }

  atualizarAnexo(obj, i) {
    const anexos = this.formulario.get('anexos') as FormArray;

    if (i < 0) {
      anexos.push(
        this.formBuilder.group({
          arquivo: obj,
          acao: 'I',
          nome: [obj.nome, Validators.required],
        })
      );
    } else {
      const valor = anexos.value[i];
      anexos.removeAt(i);
      anexos.insert(
        i,
        this.formBuilder.group({
          arquivo: obj,
          acao: 'A',
          nome: [obj.nome, Validators.required],
        })
      );
    }
  }

  alterarAnexo(i) {
    this.numeroAdcAnexo = i;
    this.inputAnexos.nativeElement.click();
  }

  filterList(campo: string) {
    const fotos = this.formulario.get(campo) as FormArray;
    return fotos.controls.filter(
      (x) => (x as FormGroup).controls['acao'].value !== 'D'
    );
  }

  deleteCampo(indexCampo: number) {
    const campos = this.formulario.controls['campos'] as FormArray;
    const campo = campos.at(indexCampo) as FormGroup;
    if (campo.controls['acao'].value !== 'I') {
      campo.controls['acao'].setValue('D');
    } else {
      campos.removeAt(indexCampo);
    }
  }

  deleteAnexo(indexAnexo: number) {
    const anexos = this.formulario.controls['anexos'] as FormArray;
    const anexo = anexos.at(indexAnexo) as FormGroup;
    if (anexo.controls['acao'].value !== 'I') {
      anexo.controls['acao'].setValue('D');
    } else {
      anexos.removeAt(indexAnexo);
    }
  }

  deleteFoto(indexFoto: number) {
    const fotos = this.formulario.controls['fotos'] as FormArray;
    const foto = fotos.at(indexFoto) as FormGroup;
    if (foto.controls['acao'].value !== 'I') {
      foto.controls['acao'].setValue('D');
    } else {
      fotos.removeAt(indexFoto);
    }
  }

  onValueChangePraca(event, campo, i) {
    const pracas = this.formulario.get('pracas') as FormArray;
    const praca = pracas.at(i) as FormGroup;
    praca.controls[campo].markAsTouched();
    praca.controls[campo].setValue(event);
  }

  adicionarPraca() {
    const pracas = this.formulario.get('pracas') as FormArray;
    pracas.push(
      this.formBuilder.group({
        numero: [pracas.length + 1],
        data: [null, Validators.required],
        valor: [null],
      })
    );
  }

  deletePraca(indexPraca: number) {
    const pracas = this.formulario.controls['pracas'] as FormArray;
    pracas.removeAt(indexPraca);
  }

  verificaValidTouched(campo) {
    if (!this.formulario.get(campo).disabled) {
      return (
        !this.formulario.get(campo).valid && this.formulario.get(campo).touched
      );
    }
    return false;
  }

  verificaValidList(campoArray, campo, i) {
    const lista = this.formulario.get(campoArray) as FormArray;
    const item = lista.controls[i] as FormGroup;
    return !item.get(campo).valid;
  }

  aplicaCssErro(campo) {
    return { 'is-invalid': this.verificaValidTouched(campo) };
  }

  aplicaCssErroLista(campoArray, campo, i) {
    return { 'is-invalid': this.verificaValidList(campoArray, campo, i) };
  }
}
