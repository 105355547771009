import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { OportunidadeService } from './oportunidade.service';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-oportunidade',
  templateUrl: './oportunidade.component.html',
  styleUrls: ['./oportunidade.component.scss'],
})
export class OportunidadeComponent implements OnInit {
  contratoId: number;
  contrato;
  selectedImovel: number | null = null;
  intencaoCompraId;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private activeRoute: ActivatedRoute,
    private oportunidadeService: OportunidadeService,
    private location: Location
  ) {
    this.contratoId = activeRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.oportunidadeService.selectedImovel$.subscribe((imovel) => {
      this.selectedImovel = imovel;
    });
    this.oportunidadeService.intencaoCompraId$.subscribe((intencaoCompraId) => {
      this.intencaoCompraId = intencaoCompraId;
    });

    this.carregarContrato();
  }

  carregarContrato() {
    this.restAngular
      .one('contrato/' + this.contratoId)
      .get()
      .subscribe(
        (response) => {
          this.contrato = response.data;
          this.contrato.status = this.definirStatus(
            response.data.vigenciaContrato,
            response.data.cancelado
          );
        },
        () => {
          this.notifier.notify('error', 'Erro ao carregar contrato');
        }
      );
  }

  definirStatus(vigencia: Date, cancelado: boolean) {
    var vigenciaData = moment(vigencia, 'YYYY-MM-DD');

    var dataAtual = moment();

    var diferencaDias = vigenciaData.diff(dataAtual, 'days');

    if (cancelado) {
      return { descricao: 'Cancelado', classe: 'badge badge-danger' };
    } else if (diferencaDias <= 0) {
      return { descricao: 'Vencido', classe: 'badge badge-danger' };
    } else if (diferencaDias <= 30) {
      return {
        descricao: 'Vence em ' + diferencaDias + ' dias',
        classe: 'badge badge-warning',
      };
    } else {
      return { descricao: 'Vigente', classe: 'badge badge-success' };
    }
  }

  goBack() {
    this.location.back();
  }
}
