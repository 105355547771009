/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-sidebar-footer.component";
var styles_AppSidebarFooterComponent = [];
var RenderType_AppSidebarFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarFooterComponent, data: {} });
export { RenderType_AppSidebarFooterComponent as RenderType_AppSidebarFooterComponent };
export function View_AppSidebarFooterComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AppSidebarFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-footer", [], null, null, null, View_AppSidebarFooterComponent_0, RenderType_AppSidebarFooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarFooterComponent, [], null, null)], null, null); }
var AppSidebarFooterComponentNgFactory = i0.ɵccf("app-sidebar-footer", i1.AppSidebarFooterComponent, View_AppSidebarFooterComponent_Host_0, {}, {}, []);
export { AppSidebarFooterComponentNgFactory as AppSidebarFooterComponentNgFactory };
