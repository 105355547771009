import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Imovel, Response } from '../_models';
import { NotifierService } from 'angular-notifier';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-imovel',
  templateUrl: './imovel.component.html',
  styleUrls: ['./imovel.component.scss'],
})
export class ImovelComponent implements OnInit {
  imoveis: Imovel[];
  loading: boolean = true;
  exportando: boolean = false;
  exportandoPdf: boolean = false;
  titulo: string = '';
  filtro: FormGroup;
  modalRef: BsModalRef;
  filtrando: boolean = false;
  filtrosAplicados: number = 0;

  public mask: Array<string | RegExp>;

  bsValue = new Date();
  bsRangeValue: Date[];
  bsValue2 = new Date();
  bsRangeValue2: Date[];
  maxDate = new Date();
  origens = [];
  defaultFormValue;
  tipoImovel;
  tipoPregao;
  modalidade;

  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];
  qtdImoveis = 0;
  page = 1;

  constructor(
    private restangular: Restangular,
    private notifier: NotifierService,
    private confirmation: ConfirmationService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use('pt-br');

    this.mask = [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      '.',
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  ngOnInit() {
    this.defaultFormValue = {
      estado: [null],
      municipio: [[]],
      bairro: [[]],
      zona: [''],
      titulo: [''],
      data: [],
      data2: [],
      imoveisActive: [true],
      origem: [[]],
      referencia: [''],
      valorInicial: '',
      valorFinal: '',
      pregao: [[]],
      tipo: [[]],
      modalidade: [[]],
      numProcesso: '',
    };
    this.filtro = this.fb.group(this.defaultFormValue);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.bsRangeValue2 = [this.bsValue2, this.maxDate];
    this.getAllImoveis();
    this.getTipoImovel();
    this.getTipoPregao();
    this.getModalidade();
    this.getOrigens();

    // this.filtro.valueChanges.subscribe((value) => {
    //   this.onChangeFilter();
    // });
  }

  openModal(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.getEstado();
  }

  getEstado() {
    this.restangular
      .one('localidade/estado')
      .get()
      .subscribe((estados) => {
        this.estados = estados.data;
      });
    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }
    if (
      this.filtro.value.municipio === null ||
      this.filtro.value.municipio.length === 0
    ) {
      this.filtro.get('bairro').disable();
      return;
    }
  }

  onChangeEstado() {
    this.filtro.get('municipio').disable();
    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estados: this.filtro.value.estado })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.filtro.get('municipio').enable();
      });
  }

  onChangeMunicipio() {
    const municipios = this.filtro.value.municipio;

    if (municipios === null || municipios.length === 0) {
      this.filtro.get('bairro').disable();
      return;
    }

    if (
      this.filtro.value.estado === null ||
      this.filtro.value.estado.length === 0
    ) {
      this.filtro.get('estado').markAsTouched();
      this.filtro.get('municipio').disable();
      this.filtro.get('bairro').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipios })
      .subscribe((res) => {
        const bairros = res.data;
        this.bairros = bairros;
        this.filtro.get('bairro').enable();
      });
  }

  contarFiltros(values: any): void {
    this.filtrosAplicados = Object.entries(values).filter(([key, value]) => {
      if (key !== 'imoveisActive') {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value;
      }
      return false;
    }).length;
  }

  onChangeFilter() {
    this.imoveis = [];
    this.getAllImoveis();
    this.contarFiltros(this.filtro.value);
  }

  getTipoImovel() {
    this.restangular
      .one('tipo')
      .get()
      .subscribe((response) => {
        this.tipoImovel = response.data;
      });
  }

  getTipoPregao() {
    this.restangular
      .one('pregao')
      .get()
      .subscribe((response) => {
        this.tipoPregao = response.data;
      });
  }

  getModalidade() {
    this.restangular
      .one('modalidade')
      .get()
      .subscribe((response) => {
        this.modalidade = response.data;
      });
  }

  getOrigens() {
    this.restangular
      .one('imovel/origem')
      .get()
      .subscribe((response) => {
        this.origens = response.data.map((x) => {
          return { value: x };
        });
      });
  }

  getAllImoveis() {
    let dataInicialPrimeiraPraca = '';
    let dataFinalPrimeiraPraca = '';
    let dataInicialSegundaPraca = '';
    let dataFinalSegundaPraca = '';

    const datas = this.filtro.controls['data'].value;
    if (datas !== null) {
      dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
      dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
    }

    const datas2 = this.filtro.controls['data2'].value;
    if (datas2 !== null) {
      dataInicialSegundaPraca = moment(datas2[0]).toISOString();
      dataFinalSegundaPraca = moment(datas2[1]).toISOString();
    }

    this.loading = true;
    const filtro = this.filtro.getRawValue();
    this.restangular
      .one('imovel')
      .get({
        titulo: filtro.titulo,
        bairros: filtro.bairro,
        estado: filtro.estado || '',
        municipios: filtro.municipio,
        zona: filtro.zona,
        dataInicialPrimeiraPraca,
        dataFinalPrimeiraPraca,
        dataInicialSegundaPraca,
        dataFinalSegundaPraca,
        origem: filtro.origem,
        id: filtro.referencia,
        ImoveisActive: filtro.imoveisActive,
        valorInicial: filtro.valorInicial,
        valorFinal: filtro.valorFinal,
        tipo: filtro.tipo,
        modalidade: filtro.modalidade,
        pregao: filtro.pregao,
        PageSize: 10,
        PageNumber: this.page,
        processo: this.removerMask(filtro.numProcesso),
      })
      .subscribe(
        (res) => {
          this.loading = false;
          this.imoveis = res.data;
          this.qtdImoveis = res.totalRecords;
          //this.origens = res.data.origens;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  proximaPagina(e) {
    this.imoveis = [];
    this.page = e.page;
    this.getAllImoveis();
  }

  delete(imovelId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o imóvel?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restangular
            .one('imovel', imovelId)
            .remove()
            .subscribe(
              (res: Response<string>) => {
                this.getAllImoveis();
              },
              (err) => {
                this.notifier.notify('error', 'Erro ao excluir imóvel');
              }
            );
        }
      });
  }

  downloadRelatorio(tipo) {
    let dataInicialPrimeiraPraca = '';
    let dataFinalPrimeiraPraca = '';
    let dataInicialSegundaPraca = '';
    let dataFinalSegundaPraca = '';

    const datas = this.filtro.controls['data'].value;
    if (datas !== null) {
      dataInicialPrimeiraPraca = moment(datas[0]).toISOString();
      dataFinalPrimeiraPraca = moment(datas[1]).toISOString();
    }

    const datas2 = this.filtro.controls['data2'].value;
    if (datas2 !== null) {
      dataInicialSegundaPraca = moment(datas2[0]).toISOString();
      dataFinalSegundaPraca = moment(datas2[1]).toISOString();
    }
    const filtro = this.filtro.getRawValue();

    const url =
      tipo == 'PDF' ? 'imovel/relatorio/pdf' : 'imovel/relatorio/excel';
    const extensao = tipo == 'PDF' ? '.pdf' : '.xlsx';
    this.exportando = tipo == 'EXCEL';
    this.exportandoPdf = tipo == 'PDF';

    this.restangular
      .one(url)
      .withHttpConfig({ responseType: 'blob' })
      .get({
        titulo: filtro.titulo,
        bairros: filtro.bairro || '',
        estado: filtro.estado || '',
        municipios: filtro.municipio || '',
        zona: filtro.zona,
        dataInicialPrimeiraPraca,
        dataFinalPrimeiraPraca,
        dataInicialSegundaPraca,
        dataFinalSegundaPraca,
        origem: filtro.origem,
        id: filtro.referencia,
        ImoveisActive: filtro.imoveisActive,
        valorInicial: filtro.valorInicial,
        valorFinal: filtro.valorFinal,
        tipo: filtro.tipo,
        modalidade: filtro.modalidade,
        pregao: filtro.pregao,
        processo: this.removerMask(filtro.numProcesso),
      })
      .subscribe(
        (response) => {
          this.exportando = false;
          this.exportandoPdf = false;

          const blob = response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `RelatorioImoveis_${moment().unix()}${extensao}`;

          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          }, 100);
        },
        (err) => {
          this.exportando = false;
          this.exportandoPdf = false;
          this.notifier.notify('error', 'Erro ao gerar o relatorio de imóveis');
        }
      );
  }

  removerMask(value: string): string {
    return value.replace(/\D/g, '');
  }

  removerFiltros() {
    this.filtrosAplicados = 0;
    this.filtro = this.fb.group(this.defaultFormValue);
    this.imoveis = [];
    this.getAllImoveis();
  }
}
