import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotifierService } from 'angular-notifier';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Restangular } from 'ngx-restangular';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-analise-leilao',
  templateUrl: './edit-analise-leilao.component.html',
  styleUrls: ['./edit-analise-leilao.component.scss'],
})
export class EditAnaliseLeilaoComponent implements OnInit {
  @ViewChild('inputFotos') inputFotos: ElementRef;
  @ViewChild('inputEdtial') inputEdtial: ElementRef;

  id: number;

  loading: boolean = false;
  formulario: FormGroup;
  fileToUpload: File | null = null;
  editalbase64: any;

  //fotos
  fotosbase64: any;
  fotosnome: any;
  fotostamanho: any;
  fotostipo: any;
  numeroAdcFoto: number;
  arrayFotos = [];
  imageError: string;

  modalidade: any;
  tipo: any;
  //localidade
  estados: any = [];
  municipios: any = [];
  bairros: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Descrição detalhada...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'roboto', name: 'Roboto' },
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    sanitize: true,
  };

  constructor(
    private restangular: Restangular,
    private router: Router,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private route: ActivatedRoute,
    private localeService: BsLocaleService
  ) {
    localeService.use('pt-br');

    this.id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.restangular
      .one('analiseLeilao', this.id)
      .get()
      .subscribe((res) => {
        const analise = res.data;

        // let fotos = analise.fotos;
        // if (fotos) {
        //   fotos = JSON.parse(fotos).fotos;
        // } else {
        //   fotos = [];
        // }

        this.formulario = this.formBuilder.group({
          analiseLeilaoId: [this.id],
          link: [analise.link],
          fotos: [analise.fotos],
          titulo: [analise.titulo.toUpperCase()],
          edital: [analise.edital],
          descricao: [analise.descricao],
          numeroProcesso: [analise.numeroProcesso],
          linkMaps: [analise.linkMaps],
          origem: [analise.origem],
          tipoId: [analise.tipoId],
          dataPrimeiraPraca: [
            analise.dataPrimeiraPraca
              ? moment.utc(analise.dataPrimeiraPraca).local().toDate()
              : null,
          ],
          dataSegundaPraca: [
            analise.dataSegundaPraca
              ? moment.utc(analise.dataSegundaPraca).local().toDate()
              : null,
          ],
          modalidadeId: [analise.modalidadeId],
          valorPrimeiraPraca: [analise.valorPrimeiraPraca],
          valorSegundaPraca: [analise.valorSegundaPraca],
          bairroId: [analise.bairroId],
          municipioId: [analise.municipioId],
          estadoId: [analise.estadoId],
          endereco: [analise.endereco],
        });

        this.formulario.get('titulo').valueChanges.subscribe((value) => {
          if (value) {
            this.formulario
              .get('titulo')
              .setValue(value.toUpperCase(), { emitEvent: false });
          }
        });

        this.restangular
          .one('modalidade')
          .get()
          .subscribe((res) => (this.modalidade = res.data));
        this.restangular
          .one('tipo')
          .get()
          .subscribe((res) => (this.tipo = res.data));

        this.restangular
          .one('localidade/estado')
          .get()
          .subscribe((estados) => {
            this.estados = estados.data;

            if (analise.bairroId) {
              this.formulario
                .get('estadoId')
                .setValue(analise.bairro.municipio.estado.estadoId);
              this.formulario
                .get('municipioId')
                .setValue(analise.bairro.municipio.municipioId);
            }

            this.onChangeEstado();
            this.onChangeMunicipio();
          });
      });
  }

  onChangeEstado() {
    const estadoId = this.formulario.value.estadoId;

    if (!estadoId) this.formulario.get('municipioId').disable();

    if (this.formulario.value.estadoId === null) {
      this.formulario.get('municipioId').disable();
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/municipio')
      .get({ estadoId: this.formulario.value.estadoId })
      .subscribe((municipios) => {
        this.municipios = municipios.data;
        this.formulario.get('municipioId').enable();
      });
  }

  onChangeMunicipio() {
    const municipioId = this.formulario.value.municipioId;

    if (municipioId === null) {
      this.formulario.get('bairroId').disable();
      return;
    }

    this.restangular
      .one('localidade/bairro')
      .get({ municipioId })
      .subscribe((bairros) => {
        this.bairros = bairros.data;
        this.formulario.get('bairroId').enable();
      });
  }

  removeEdital() {
    this.formulario.get('edital').setValue('');
    this.inputEdtial.nativeElement.value = '';
    this.inputEdtial.nativeElement.click();
  }

  alterarFoto(i) {
    this.numeroAdcFoto = i;
    this.inputFotos.nativeElement.click();
  }

  onSubmit() {
    if (this.formulario.valid) {
      this.loading = true;

      const body = this.formulario.value;

      // body.fotos = JSON.stringify({ fotos: body.fotos });
      //body.analiseLeilaoId = this.id;

      this.restangular
        .one('analiseLeilao')
        .customPUT(body)
        .subscribe((response) => {
          this.loading = false;
          this.notifierService.notify(
            'success',
            'Registro de leilão atualizada com sucesso!'
          );
          this.router.navigate(['/analise-imovel']);
        });
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      controle.markAsTouched();
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  uploadFileRecoverUrl(body) {
    return this.restangular
      .all('arquivo')
      .post(body)
      .pipe(
        map((response: any) => {
          return response.data.url;
        })
      );
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    const arrayImagens = fileInput.target.files.length;

    for (let i = 0; i < arrayImagens; i++) {
      this.arrayFotos.push(fileInput.target.files[i]);
    }

    this.arrayFotos.forEach((x: any) => {
      if (x) {
        // Size Filter Bytes
        const max_size = 5242880;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 15200;
        const max_width = 25600;

        if (x.size > max_size) {
          this.imageError = 'Maximum size allowed is 5Mb';
          this.arrayFotos = [];
          return false;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (rs) => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];
            this.arrayFotos = [];

            if (img_height > max_height && img_width > max_width) {
              this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
              return false;
            } else {
              const imgBase64Path = e.target.result;
              const arquivo = {
                url: imgBase64Path,
                nome: x.name,
                base64: imgBase64Path,
                tipo: x.type,
                folder: 'fotos-imob',
              };
              this.arrayFotos = [];
              this.atualizarFoto(arquivo);
            }
          };
        };

        reader.readAsDataURL(x);
      }
    });
  }

  async editalChangeEvent(editalInput: any) {
    this.fileToUpload = editalInput.target.files[0];
    this.fileToUpload.name;
    this.fileToUpload.size;
    this.fileToUpload.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.editalbase64 = reader.result;
      const arquivo = {
        nome: this.fileToUpload.name,
        base64: this.editalbase64,
        tipo: this.fileToUpload.type,
        folder: 'anexos-imob',
      };

      this.uploadFileRecoverUrl(arquivo).subscribe((url) => {
        this.formulario.get('edital').setValue(url);
      });
    };
  }

  atualizarFoto(obj) {
    const fotos: string[] = this.formulario.get('fotos').value;
    this.uploadFileRecoverUrl(obj).subscribe((url) => {
      fotos.push(url);
    });
  }

  deleteFoto(indexFoto: number) {
    const fotos = this.formulario.controls['fotos'];
    fotos.value.splice(indexFoto, 1);
  }

  verificaValidTouched(campo) {
    if (!this.formulario.get(campo).disabled) {
      return (
        !this.formulario.get(campo).valid && this.formulario.get(campo).touched
      );
    }
    return false;
  }

  verificaValidList(campoArray, campo, i) {
    const lista = this.formulario.get(campoArray) as FormArray;
    const item = lista.controls[i] as FormGroup;
    return !item.get(campo).valid;
  }

  aplicaCssErro(campo) {
    return { 'is-invalid': this.verificaValidTouched(campo) };
  }

  aplicaCssErroLista(campoArray, campo, i) {
    return { 'is-invalid': this.verificaValidList(campoArray, campo, i) };
  }
}
