import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Response, Instituicao } from '../_models';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-instituicao',
  templateUrl: './instituicao.component.html',
  styleUrls: ['./instituicao.component.scss'],
})
export class InstituicaoComponent implements OnInit {
  instituicaos: Instituicao[] = [];
  backupinstituicaos: Instituicao[] = [];
  loading = false;

  newInstituicao: string = '';

  editingIndex: number | null = null;

  constructor(
    private restAngular: Restangular,
    private confirmation: ConfirmationService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getInstituicao();
  }

  getInstituicao() {
    this.loading = true;
    this.restAngular
      .one('instituicao')
      .get()
      .subscribe(
        (response: Response<Instituicao[]>) => {
          this.instituicaos = response.data;
          this.backupinstituicaos = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível carregar os instituicaos');
          this.loading = false;
        }
      );
  }

  delete(instituicaoId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o instituicao?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('instituicao', instituicaoId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'instituicao excluído com sucesso');
                this.getInstituicao();
              },
              (error) => {
                this.notifier.notify(
                  'error',
                  'Não foi possível excluir o instituicao'
                );
              }
            );
        }
      });
  }

  isEditDisabled(item: Instituicao): boolean {
    return (
      item.nome !==
        this.backupinstituicaos.find((x) => x.instituicaoId == item.instituicaoId).nome &&
      item.nome !== ''
    );
  }

  saveNewInstituicao() {
    this.restAngular
      .all('instituicao')
      .post({ nome: this.newInstituicao })
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'instituicao cadastrado com sucesso');
          this.getInstituicao();
          this.newInstituicao = '';
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível salvar o instituicao');
        }
      );
  }

  editItem(item: Instituicao) {
    this.restAngular
      .one('instituicao')
      .customPUT(item)
      .subscribe(
        (response: Response<string>) => {
          this.notifier.notify('success', 'instituicao editado com sucesso');
          this.getInstituicao();
        },
        (error) => {
          this.notifier.notify('error', 'Não foi possível editar o instituicao');
        }
      );
  }

  toggleEditMode(index: number): void {
    this.editingIndex = this.editingIndex === index ? null : index;
  }

  isEditMode(index: number): boolean {
    return this.editingIndex === index;
  }
}
