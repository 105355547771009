<div class="card animated fadeIn">
  <div class="card-header">
    <div class="card-title d-inline">
      <i class="fa fa-cog"></i> Tipo de Imóvel
    </div>
    <button
      class="btn btn-success btn-round float-right"
      style="margin-top: 0 !important"
      [disabled]="newTipo.length < 2"
      (click)="saveNewTipo()"
    >
      <i class="fas fa-plus-circle"></i> Novo Tipo
    </button>
    <input
      type="text"
      class="form-control float-right col-2 mr-3"
      placeholder="Novo Tipo"
      [(ngModel)]="newTipo"
      (keydown.enter)="saveNewTipo()"
    />
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table
        class="table"
        [mfData]="tipos"
        #tableTipo="mfDataTable"
        [mfRowsOnPage]="5"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="tipoId">Id</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="descricao">Descrição</mfDefaultSorter>
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="tableTipo.data.length == 0 && !loading">
            <td colspan="3" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="3" class="text-center">
              <i class="fa fa-spin fa-spinner"></i> Carregando...
            </td>
          </tr>
          <tr *ngFor="let item of tableTipo.data; let i = index">
            <td>{{ item.tipoId }}</td>
            <td>
              <div class="d-inline">
                <span
                  class="edit-icon"
                  (click)="toggleEditMode(i)"
                  class="mr-2"
                  style="cursor: pointer"
                  ><i class="fa fa-edit"></i
                ></span>
                <input
                  type="text"
                  [(ngModel)]="item.descricao"
                  [ngClass]="{ 'edit-mode': isEditMode(i) }"
                  class="form-control col-4 d-inline"
                  (keydown.enter)="editItem(item)"
                />
              </div>
            </td>
            <td class="text-center" nowrap>
              <a (click)="editItem(item)" tooltip="Editar" class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                (click)="delete(item.tipoId)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
