<div class="card animated fadeIn">
  <div class="card-header">
    <p class="card-title d-inline-block">
      <i class="far fa-calendar-alt"></i> &nbsp; Eventos
    </p>
    <button class="btn btn-success float-right" (click)="novoStatus()">
      <i class="fas fa-plus-circle"></i> Novo Evento
    </button>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table
        class="table table-striped"
        [mfData]="status"
        [mfRowsOnPage]="10"
        #mfTable="mfDataTable"
      >
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="id">Id</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="descricao">Descricao</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="grupo.descricao">Grupo</mfDefaultSorter>
            </th>
            <th class="text-center">
              <mfDefaultSorter by="possuiDataLimite"
                >Possui Data limite?</mfDefaultSorter
              >
            </th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td class="text-center text-muted" colspan="7">
              <h5>
                <i class="fagear fa-spin fa-spinner"></i>&nbsp;Carregando...
              </h5>
            </td>
          </tr>
          <tr *ngIf="!loading && status.length === 0">
            <td class="text-center text-muted" colspan="7">
              <h5>
                <i class="fa fa-spin fa-spinner"></i>&nbsp;Nenhum registro
                encontrado.
              </h5>
            </td>
          </tr>
          <tr *ngFor="let status of mfTable.data">
            <td>{{ status.id }}</td>
            <td>{{ status.descricao }}</td>
            <td>{{ status.grupo.descricao }}</td>
            <td class="text-center">
              <span class="badge badge-success" *ngIf="status.possuiDataLimite"
                >SIM</span
              >
              <span class="badge badge-danger" *ngIf="!status.possuiDataLimite"
                >NÃO</span
              >
            </td>
            <td class="text-center">
              <a
                (click)="editarStatus(status.id)"
                tooltip="Editar"
                class="mr-3 pointer"
                ><i class="fa-lg far fa-edit"></i
              ></a>
              <a
                (click)="excluirStatus(status.id)"
                tooltip="Excluir"
                class="mr-1 pointer"
                ><i class="fa-lg far fa-times-circle text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <mfBootstrapPaginator
                [rowsOnPageSet]="[5, 10, 25]"
              ></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
