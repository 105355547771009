var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ConsultaCepService } from 'app/views/usuarios/shared/consulta-cep/consulta-cep.service';
import { Restangular } from 'ngx-restangular';
var CreateContatoComponent = /** @class */ (function () {
    function CreateContatoComponent(formBuilder, restangular, notifierService, router, cepService) {
        this.formBuilder = formBuilder;
        this.restangular = restangular;
        this.notifierService = notifierService;
        this.router = router;
        this.cepService = cepService;
        this.loadorigem = false;
        this.origemcontato = [];
        //localidade
        this.estados = [];
        this.municipios = [];
        this.bairros = [];
        this.hasLocalidadeInteresse = this.formBuilder.control(true);
        this.hasEndereco = this.formBuilder.control(false);
        this.maskTelefone = [
            '(',
            /[1-9]/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
        this.maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
        this.maskCpf = [
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
        ];
        this.maskCnpj = [
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
        ];
        this.maskRg = [
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
        ];
    }
    CreateContatoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getOrigemContato();
        this.formulario = this.formBuilder.group({
            nome: ['', Validators.required],
            email: [null],
            telefone: [null],
            mensagem: [null],
            origemId: [null],
            comoContactar: [null],
            localidadesInteresse: this.formBuilder.array([]),
            valorInicial: [null],
            valorFinal: [null],
            bairroId: [null],
            municipioId: [null],
            estadoId: [null],
        });
        this.restangular
            .one('localidade/estado')
            .get()
            .subscribe(function (estados) {
            _this.estados = estados.data;
            _this.formulario.get('municipioId').disable();
            _this.formulario.get('bairroId').disable();
        });
    };
    CreateContatoComponent.prototype.onSubimit = function () {
        var _this = this;
        if (this.formulario.invalid) {
            this.notifierService.notify('error', 'Formulário inválido');
            return;
        }
        var body = __assign({}, this.formulario.value, { localidadesInteresse: this.formulario.value.localidadesInteresse.map(function (x) { return x.bairroId; }) });
        this.restangular
            .all('contato')
            .post(body)
            .subscribe(function () {
            _this.notifierService.notify('success', 'Contato atualizado com sucesso');
            _this.router.navigate(['contato']);
        }, function (error) {
            _this.notifierService.notify('error', 'Erro ao criar contato');
        });
    };
    CreateContatoComponent.prototype.onChangeEstado = function () {
        var _this = this;
        this.formulario.get('municipioId').disable();
        if (this.formulario.value.estadoId === null) {
            this.formulario.get('municipioId').disable();
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/municipio')
            .get({ estadoId: this.formulario.value.estadoId })
            .subscribe(function (municipios) {
            _this.municipios = municipios.data;
            _this.formulario.get('municipioId').enable();
        });
    };
    CreateContatoComponent.prototype.onChangeMunicipio = function () {
        var _this = this;
        var municipioId = this.formulario.value.municipioId;
        if (municipioId === null) {
            this.formulario.get('bairroId').disable();
            return;
        }
        this.restangular
            .one('localidade/bairro')
            .get({ municipioId: municipioId })
            .subscribe(function (res) {
            var bairros = res.data;
            // Organizar os bairros por zona usando reduce
            var bairrosPorZona = bairros.reduce(function (acc, bairro) {
                var zona = bairro.zona || 'Sem Zona';
                if (!acc[zona]) {
                    acc[zona] = [];
                }
                acc[zona].push({ bairroId: bairro.bairroId, nome: bairro.nome });
                return acc;
            }, {});
            // Converter para o formato desejado
            var bairrosFormatados = Object.keys(bairrosPorZona).map(function (zona) {
                return {
                    label: zona,
                    children: bairrosPorZona[zona],
                };
            });
            // Atualizar os bairros organizados por zona
            _this.bairrosAgrupados = bairrosFormatados;
            _this.bairros = bairros;
            _this.formulario.get('bairroId').enable();
        });
    };
    CreateContatoComponent.prototype.addLocalidadeInteresse = function () {
        var _this = this;
        var localidadesInteresse = this.formulario.get('localidadesInteresse');
        if (localidadesInteresse.controls.filter(function (x) { return x.value.bairroId === _this.formulario.value.bairroId; }).length > 0) {
            this.notifierService.notify('error', 'Localidade já adicionada');
            return;
        }
        localidadesInteresse.push(this.formBuilder.group({
            bairroId: [this.formulario.value.bairroId],
            bairro: [
                this.bairros.find(function (bairro) { return bairro.bairroId === _this.formulario.value.bairroId; }).nome,
            ],
            municipio: [
                this.municipios.find(function (municipio) {
                    return municipio.municipioId === _this.formulario.value.municipioId;
                }).nome,
            ],
            estado: [
                this.estados.find(function (estado) { return estado.estadoId === _this.formulario.value.estadoId; }).nome,
            ],
        }));
        this.formulario.get('bairroId').reset();
    };
    CreateContatoComponent.prototype.removeLocalidadeInteresse = function (index) {
        var localidadesInteresse = this.formulario.get('localidadesInteresse');
        localidadesInteresse.removeAt(index);
    };
    CreateContatoComponent.prototype.verificaValidTouched = function (campo) {
        return (!this.formulario.get(campo).valid && this.formulario.get(campo).touched);
    };
    CreateContatoComponent.prototype.aplicaCssErro = function (campo) {
        return { 'is-invalid': this.verificaValidTouched(campo) };
    };
    CreateContatoComponent.prototype.consultaCEP = function () {
        var _this = this;
        var cep = this.formulario.get('endereco.cep').value;
        if (cep != null && cep !== '') {
            this.cepService
                .consultaCEP(cep)
                .subscribe(function (dados) { return _this.populaDadosForm(dados); });
        }
    };
    CreateContatoComponent.prototype.addEndereco = function () {
        if (this.hasEndereco) {
            this.formulario.addControl('endereco', this.formBuilder.group({
                enderecoId: [0],
                cep: [''],
                numero: [''],
                complemento: [''],
                bairro: [''],
                cidade: [''],
                estado: [''],
                logradouro: [''],
            }));
        }
        else {
            this.formulario.removeControl('endereco');
        }
    };
    CreateContatoComponent.prototype.populaDadosForm = function (dados) {
        this.formulario.patchValue({
            endereco: {
                logradouro: dados.logradouro,
                complemento: dados.complemento,
                bairro: dados.bairro,
                cidade: dados.localidade,
                estado: dados.uf,
            },
        });
    };
    CreateContatoComponent.prototype.getOrigemContato = function () {
        var _this = this;
        this.loadorigem = true;
        this.restangular
            .one('origemcontato')
            .get()
            .subscribe(function (response) {
            _this.origemcontato = response.data;
            _this.loadorigem = false;
        }, function (error) {
            _this.notifierService.notify('error', 'Não foi possível carregar as origens');
            _this.loadorigem = false;
        });
    };
    return CreateContatoComponent;
}());
export { CreateContatoComponent };
