import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-checklistCampo',
  templateUrl: './checklist-campo.component.html',
  styleUrls: ['./checklist-campo.component.scss'],
})
export class ChecklistCampoComponent implements OnInit {
  checklistCampos: any[] = [];
  loading: boolean = true;

  constructor(
    private restAngular: Restangular,
    private norifier: NotifierService,
    private router: Router,
    private confimation: ConfirmationService
  ) {}

  ngOnInit() {
    this.carregarChecklistCampos();
  }

  editarChecklistCampo(id: number) {
    this.router.navigate(['/update-checklist-campo', id]);
  }

  novoChecklistCampo() {
    const lastOrdem =
      this.checklistCampos[this.checklistCampos.length - 1].ordem;

    this.router.navigate(['/create-checklist-campo'], {
      queryParams: { lastOrdem },
    });
  }

  excluirChecklistCampo(id: number) {
    this.confimation
      .create('Atenção', 'Deseja realmente excluir o checklistCampo?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('checklistCampo', id)
            .remove()
            .subscribe(
              () => {
                this.norifier.notify(
                  'success',
                  'ChecklistCampo excluído com sucesso'
                );
                this.carregarChecklistCampos();
              },
              () => {
                this.norifier.notify('error', 'Erro ao excluir checklistCampo');
              }
            );
        }
      });
  }

  carregarChecklistCampos() {
    this.loading = true;
    this.restAngular
      .one('checklistCampo')
      .get()
      .subscribe(
        (checklistCampos) => {
          this.checklistCampos = checklistCampos.data;
          this.loading = false;
        },
        () => {
          this.norifier.notify('error', 'Erro ao carregar checklistCampos');
          this.loading = false;
        }
      );
  }
}
