import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';

@Component({
  selector: 'app-campanha',
  templateUrl: './campanha.component.html',
  styleUrls: ['./campanha.component.scss'],
})
export class CampanhaComponent implements OnInit {
  campanhas;

  loading: boolean = false;

  //mask
  public mask: Array<string | RegExp>;

  constructor(
    private restangular: Restangular,
    private router: Router,
    private confirmation: ConfirmationService,
    private NotifierService: NotifierService
  ) {}

  ngOnInit() {
    this.getCampanha();
  }

  getCampanha() {
    this.loading = true;
    this.restangular
      .one('campanha')
      .get()
      .subscribe(
        (res) => {
          this.campanhas = res.data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.NotifierService.notify(
            'Erro',
            'Não foi possível carregar as campanhas!'
          );
        }
      );
  }

  edit(campanhaId: number) {
    this.router.navigate(['update-campanha', campanhaId]);
  }

  verLogs(campanhaId: number) {
    this.router.navigate(['logs', campanhaId]);
  }

  delete(campanhaId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir a campanha?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restangular
            .one('campanha', campanhaId)
            .remove()
            .subscribe(
              (response) => {
                this.NotifierService.notify(
                  'success',
                  'Campanha excluída com sucesso'
                );
                this.getCampanha();
              },
              (error) => {
                this.NotifierService.notify(
                  'error',
                  'Não foi possível excluir a campanha'
                );
              }
            );
        }
      });
  }

  atualizarStatus(campanha) {
    this.restangular
      .all('Campanha/AtualizarStatus')
      .post({
        campanhaId: campanha.campanhaId,
        ativo: campanha.ativo,
      })
      .subscribe(
        (res) => {
          this.NotifierService.notify('success', res.message);
        },
        (error) => {
          this.loading = false;
          this.NotifierService.notify(
            'Erro',
            `Não foi possível ${
              campanha.ativo ? 'ativar' : 'desativar'
            } a campanha!`
          );
        }
      );
  }
}
