var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ConfirmationService } from '@jaspero/ng-confirmations';
var AnaliseLeilaoComponent = /** @class */ (function () {
    function AnaliseLeilaoComponent(restangular, notifer, localeService, confirmation) {
        this.restangular = restangular;
        this.notifer = notifer;
        this.localeService = localeService;
        this.confirmation = confirmation;
        this.analises = [];
        this.analisesIgnoradas = [];
        this.todasSelecionadas = true;
        this.countAnalises = 0; //gambiarra talvez?
        this.origem = '';
        this.dataPrimeiraPraca = '';
        this.dataSegundaPraca = '';
        this.status = '1';
        this.loading = false;
        this.loadingConfirm = false;
        this.loadingAprovaTudo = false;
        this.origens = [];
        this.page = 1;
        localeService.use('pt-br');
    }
    AnaliseLeilaoComponent.prototype.ngOnInit = function () {
        this.getFilterLocalStorage();
        this.getAnalises();
    };
    AnaliseLeilaoComponent.prototype.getAnalises = function () {
        var _this = this;
        this.loading = true;
        var dataPrimeiraPraca = this.dataPrimeiraPraca;
        var dataSegundaPraca = this.dataSegundaPraca;
        if (this.dataPrimeiraPraca === 'Data inválida' ||
            this.dataPrimeiraPraca == null ||
            this.dataPrimeiraPraca == 'null')
            dataPrimeiraPraca = '';
        else if (this.dataPrimeiraPraca)
            dataPrimeiraPraca = moment
                .utc(this.dataPrimeiraPraca)
                .format('YYYY-MM-DD');
        if (this.dataSegundaPraca === 'Data inválida' ||
            this.dataSegundaPraca == null ||
            this.dataPrimeiraPraca == 'null')
            dataSegundaPraca = '';
        else if (this.dataSegundaPraca)
            dataSegundaPraca = moment.utc(this.dataSegundaPraca).format('YYYY-MM-DD');
        this.restangular
            .one('analiseLeilao')
            .get({
            origem: this.origem,
            dataPrimeiraPraca: dataPrimeiraPraca,
            dataSegundaPraca: dataSegundaPraca,
            page: this.page || 1,
            status: this.status || '',
        })
            .subscribe(function (response) {
            _this.countAnalises = response.data.count;
            var idsIgnorados = _this.getIdsIgnoradosFromLocalStorage();
            if (idsIgnorados.length > 0)
                _this.todasSelecionadas = false;
            var analises = response.data.analises.map(function (analise) {
                return __assign({}, analise, { selecionado: !idsIgnorados.includes(analise.analiseLeilaoId), fotos: analise.fotos || [] });
            });
            _this.analises = analises;
            _this.loading = false;
            _this.origens = response.data.origens;
            _this.loading = false;
        });
    };
    AnaliseLeilaoComponent.prototype.confirmSelected = function (todos) {
        var _this = this;
        this.confirmation
            .create('Atenção', "Deseja realmente aprovar" + (todos ? ' <b>TODOS</b> ' : ' ') + "os im\u00F3veis?")
            .subscribe(function (ans) {
            if (ans.resolved) {
                var analisesSelecionadas = _this.analises.filter(function (analise) { return analise.selecionado; });
                var analisesNaoSelecionadas = _this.analises.filter(function (analise) { return !analise.selecionado; });
                var ids = analisesSelecionadas.map(function (analise) { return analise.analiseLeilaoId; });
                var idsIgnorados = analisesNaoSelecionadas.map(function (analise) { return analise.analiseLeilaoId; });
                _this.loadingAprovaTudo = todos;
                _this.loadingConfirm = !todos;
                _this.restangular
                    .all('analiseLeilao/aprovar')
                    .post({ ids: ids, idsIgnorados: idsIgnorados, aprovarTudo: todos })
                    .subscribe(function () {
                    _this.loadingConfirm = false;
                    _this.loadingAprovaTudo = false;
                    _this.notifer.notify('success', 'Análises confirmadas com sucesso!');
                    _this.getAnalises();
                    _this.removeIdsIgnorados();
                }, function () {
                    _this.loadingConfirm = false;
                    _this.loadingAprovaTudo = false;
                    _this.notifer.notify('error', 'Erro ao confirmar análises');
                    _this.getAnalises();
                });
            }
        });
    };
    AnaliseLeilaoComponent.prototype.selectAllAnalises = function () {
        this.todasSelecionadas = !this.todasSelecionadas;
        if (this.todasSelecionadas) {
            this.analises = this.analises.map(function (analise) {
                return __assign({}, analise, { selecionado: true });
            });
        }
        else {
            this.analises = this.analises.map(function (analise) {
                return __assign({}, analise, { selecionado: false });
            });
        }
        this.saveIdsIgnorados();
    };
    AnaliseLeilaoComponent.prototype.hasNoItemSelected = function () {
        return this.analises.filter(function (analise) { return analise.selecionado; }).length === 0;
    };
    AnaliseLeilaoComponent.prototype.verifyAllItems = function () {
        var allNotSelected = this.analises.filter(function (x) { return !x.selecionado; });
        this.todasSelecionadas = allNotSelected.length === 0;
        this.saveIdsIgnorados();
    };
    AnaliseLeilaoComponent.prototype.onChangeFilter = function () {
        this.analises = [];
        this.page = 1;
        this.saveFilterLocalStorage();
        this.getAnalises();
    };
    AnaliseLeilaoComponent.prototype.reprovar = function (analiseId) {
        var _this = this;
        this.confirmation
            .create('Atenção', 'Deseja realmente aprovar os imóveis?')
            .subscribe(function (ans) {
            if (ans.resolved) {
                _this.restangular
                    .one('analiseLeilao/reprovar', analiseId)
                    .remove()
                    .subscribe(function () {
                    _this.notifer.notify('success', 'Análise reprovada com sucesso');
                    _this.getAnalises();
                    _this.removeOneIdIgnorado(analiseId);
                }, function () {
                    _this.notifer.notify('error', 'Erro ao reprovar análise');
                });
            }
        });
    };
    AnaliseLeilaoComponent.prototype.saveFilterLocalStorage = function () {
        localStorage.setItem('analiseLeilaoFilter', JSON.stringify({
            origem: this.origem,
            dataPrimeiraPraca: this.dataPrimeiraPraca,
            dataSegundaPraca: this.dataSegundaPraca,
            status: this.status,
            page: this.page,
        }));
    };
    AnaliseLeilaoComponent.prototype.removeFilterLocalStorage = function () {
        localStorage.removeItem('analiseLeilaoFilter');
    };
    AnaliseLeilaoComponent.prototype.getFilterLocalStorage = function () {
        var filter = localStorage.getItem('analiseLeilaoFilter');
        if (filter) {
            var _a = JSON.parse(filter), origem = _a.origem, dataPrimeiraPraca = _a.dataPrimeiraPraca, dataSegundaPraca = _a.dataSegundaPraca, page = _a.page, status_1 = _a.status;
            if (dataPrimeiraPraca)
                dataPrimeiraPraca = moment(dataPrimeiraPraca).local().toDate();
            if (dataSegundaPraca)
                dataSegundaPraca = moment(dataSegundaPraca).local().toDate();
            this.origem = origem;
            this.dataPrimeiraPraca = dataPrimeiraPraca;
            this.dataSegundaPraca = dataSegundaPraca;
            this.page = page;
            this.status = status_1;
        }
    };
    AnaliseLeilaoComponent.prototype.loadMoreAnalises = function (e) {
        this.page = e.page;
        this.removeIdsIgnorados();
        this.saveFilterLocalStorage();
        this.getAnalises();
    };
    AnaliseLeilaoComponent.prototype.getIdsIgnoradosFromLocalStorage = function () {
        var idsIgnorados = localStorage.getItem('analiseLeilaoIdsIgnorados');
        if (idsIgnorados) {
            return JSON.parse(idsIgnorados);
        }
        return [];
    };
    AnaliseLeilaoComponent.prototype.saveIdsIgnorados = function () {
        var idsLocalStorage = this.getIdsIgnoradosFromLocalStorage();
        var idsIgnorados = this.analises
            .filter(function (x) { return !x.selecionado && !idsLocalStorage.includes(x.analiseLeilaoId); })
            .map(function (x) { return x.analiseLeilaoId; });
        var idsJaIgnorados = this.analises
            .filter(function (x) { return idsLocalStorage.includes(x.analiseLeilaoId) && x.selecionado; })
            .map(function (x) { return x.analiseLeilaoId; });
        var _loop_1 = function (id) {
            idsLocalStorage = idsLocalStorage.filter(function (x) { return x !== id; });
        };
        for (var _i = 0, idsJaIgnorados_1 = idsJaIgnorados; _i < idsJaIgnorados_1.length; _i++) {
            var id = idsJaIgnorados_1[_i];
            _loop_1(id);
        }
        var analiseLeilaoIdsIgnorados = idsLocalStorage.concat(idsIgnorados);
        localStorage.setItem('analiseLeilaoIdsIgnorados', JSON.stringify(analiseLeilaoIdsIgnorados));
    };
    AnaliseLeilaoComponent.prototype.removeIdsIgnorados = function () {
        localStorage.removeItem('analiseLeilaoIdsIgnorados');
    };
    AnaliseLeilaoComponent.prototype.removeOneIdIgnorado = function (id) {
        var idsIgnorados = this.getIdsIgnoradosFromLocalStorage();
        var ids = idsIgnorados.filter(function (x) { return x !== id; });
        localStorage.setItem('analiseLeilaoIdsIgnorados', JSON.stringify(ids));
    };
    return AnaliseLeilaoComponent;
}());
export { AnaliseLeilaoComponent };
