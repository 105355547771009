import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    let value = this.elementRef.nativeElement.value;
    value = value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.substring(0, 8);
    }
    if (value.length > 4) {
      value = value.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
    } else if (value.length > 2) {
      value = value.replace(/(\d{2})(\d{0,2})/, '$1/$2');
    }
    this.elementRef.nativeElement.value = value;
  }
}
