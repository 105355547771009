import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OportunidadeService {
  constructor() {}

  private selectedImovelSource = new BehaviorSubject<any | null>(null);
  selectedImovel$ = this.selectedImovelSource.asObservable();
  private analiseConfirmadaSource = new BehaviorSubject<boolean | null>(null);
  analiseConfirmada$ = this.analiseConfirmadaSource.asObservable();
  private intencaoCompraIdSource = new BehaviorSubject<any | null>(null);
  intencaoCompraId$ = this.intencaoCompraIdSource.asObservable();

  selectImovel(imovel: any) {
    this.selectedImovelSource.next(imovel);
  }

  setIntencaoCompra(intencaoCompraId) {
    this.intencaoCompraIdSource.next(intencaoCompraId);
  }

  changeAnaliseConfirmada(positivoOuNegativo: boolean) {
    this.analiseConfirmadaSource.next(positivoOuNegativo);
  }
}
