<form (submit)="onSubmit()" *ngIf="formulario">
  <div class="card animated fadeIn">
    <div class="card-body" [formGroup]="formulario">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header d-flex align-items-center">
              <i class="fas fa-user-circle" aria-hidden="true"></i>
              &nbsp;Informações Principais
            </div>
            <div class="card-block">
              <div class="row">
                <div class="form-group col-2">
                  <label for="celular">Tipo Pessoa *</label>
                  <select
                    class="form-control"
                    formControlName="tipoPessoaId"
                    [ngClass]="aplicaCssErro('tipoPessoaId')"
                  >
                    <option
                      *ngFor="let item of tiposPessoa"
                      value="{{ item.tipoPessoaId }}"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <label for="nome" *ngIf="formulario.value.tipoPessoaId == 2"
                    >Razão Social *</label
                  >
                  <label for="nome" *ngIf="formulario.value.tipoPessoaId == 1"
                    >Nome *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    [ngClass]="aplicaCssErro('nome')"
                    id="nome"
                    placeholder="Nome"
                    name="nome"
                    formControlName="nome"
                  />
                </div>
                <div
                  class="form-group"
                  [ngClass]="
                    formulario.value.tipoPessoaId == 2 ? 'col-3' : 'col-6'
                  "
                >
                  <label for="cpfCnpj">Nº Documento *</label>
                  <input
                    *ngIf="formulario.controls['tipoPessoaId'].value == 1"
                    type="text"
                    class="form-control"
                    id="cpfCnpj"
                    placeholder="CPF"
                    formControlName="cpfCnpj"
                    [textMask]="{ mask: maskCpf }"
                    [ngClass]="aplicaCssErro('cpfCnpj')"
                  />
                  <input
                    *ngIf="formulario.controls['tipoPessoaId'].value == 2"
                    type="text"
                    class="form-control"
                    id="cpfCnpj"
                    placeholder="CNPJ"
                    formControlName="cpfCnpj"
                    [textMask]="{ mask: maskCnpj }"
                    [ngClass]="aplicaCssErro('cpfCnpj')"
                  />
                </div>
                <div
                  class="form-group col-3"
                  *ngIf="formulario.value.tipoPessoaId == 2"
                >
                  <label for="nomeContato">Nome Contato</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nomeContato"
                    placeholder="Nome"
                    name="nomeContato"
                    formControlName="nomeContato"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-3">
                  <label for="rg">RG</label>
                  <input
                    type="text"
                    class="form-control"
                    id="rg"
                    placeholder="Documento de Identidade"
                    name="rg"
                    formControlName="rg"
                    [textMask]="{ mask: maskRg }"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="telefone">Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefone"
                    placeholder="Telefone"
                    name="telefone"
                    formControlName="telefone"
                    [textMask]="{ mask: mask }"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="celular">Celular</label>
                  <input
                    type="text"
                    class="form-control"
                    id="celular"
                    placeholder="Celular"
                    formControlName="celular"
                    [textMask]="{ mask: mask }"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    formControlName="email"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <label for="nacionalidade">Nacionalidade</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nacionalidade"
                    placeholder="Nacionalidade"
                    name="nacionalidade"
                    formControlName="nacionalidade"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="estadoCivil">Estado Civil</label>
                  <select
                    class="form-control"
                    id="estadoCivil"
                    name="estadoCivil"
                    formControlName="estadoCivil"
                  >
                    <option value="4">Selecione</option>
                    <option value="0">Solteiro</option>
                    <option value="1">Casado</option>
                    <option value="2">Divorciado</option>
                    <option value="3">Viúvo</option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <label for="profissao">Profissao</label>
                  <input
                    type="text"
                    class="form-control"
                    id="profissao"
                    placeholder="Profissao"
                    name="profissao"
                    formControlName="profissao"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div>
                <i class="fa fa-home"></i>
                &nbsp;Endereço
              </div>
              <div>
                <input
                  type="checkbox"
                  name="hasEndereco"
                  [formControl]="hasEndereco"
                  (change)="addEndereco()"
                />
              </div>
            </div>

            <div
              class="card-block"
              *ngIf="hasEndereco.value"
              formGroupName="endereco"
            >
              <div class="row">
                <div class="form-group col-4">
                  <label for="cep">CEP</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cep"
                    formControlName="cep"
                    (blur)="consultaCEP()"
                    [textMask]="{ mask: maskCep }"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="logradouro">Logradouro</label>
                  <input
                    type="text"
                    class="form-control"
                    id="logradouro"
                    formControlName="logradouro"
                  />
                </div>

                <div class="form-group col-4">
                  <label for="numero">Número</label>
                  <input
                    type="text"
                    class="form-control"
                    id="numero"
                    formControlName="numero"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group col-3">
                  <label for="complemento">Complemento</label>
                  <input
                    type="text"
                    class="form-control"
                    id="complemento"
                    formControlName="complemento"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="bairro">Bairro</label>
                  <input
                    type="text"
                    class="form-control"
                    id="bairro"
                    formControlName="bairro"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="cidade">Cidade</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cidade"
                    formControlName="cidade"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="estado">Estado</label>
                  <input
                    type="text"
                    class="form-control"
                    id="estado"
                    formControlName="estado"
                    maxlength="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div>
                <i class="fas fa-funnel-dollar"></i>&nbsp; Faixa de valor
              </div>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="form-group col-6">
                  <label for="telefone">Valor Inicial</label>
                  <input
                    type="text"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                    class="form-control"
                    id="valorInicial"
                    placeholder="Valor Inicial"
                    formControlName="valorInicial"
                  />
                </div>
                <div class="form-group col-6">
                  <label for="celular">Valor Final</label>
                  <input
                    type="text"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ','
                    }"
                    class="form-control"
                    id="valorFinal"
                    placeholder="Valor Final"
                    formControlName="valorFinal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div>
                <i class="fas fa-map-marker-alt"></i>&nbsp; Localidade de
                Interesse
              </div>
              <div>
                <input
                  type="checkbox"
                  name="hasLocalidadeInteresse"
                  [formControl]="hasLocalidadeInteresse"
                />
              </div>
            </div>
            <div class="card-block" *ngIf="hasLocalidadeInteresse.value">
              <div class="row">
                <div class="form-group col-3">
                  <label for="estadoId">Estado </label>
                  <ng-select
                    [multiple]="false"
                    formControlName="estadoId"
                    (change)="onChangeEstado()"
                    [items]="estados"
                    bindValue="estadoId"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-4">
                  <label for="municipioId">Municipio </label>
                  <ng-select
                    [multiple]="false"
                    formControlName="municipioId"
                    (change)="onChangeMunicipio()"
                    [items]="municipios"
                    bindValue="municipioId"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
                <div class="form-group col-4">
                  <label for="titulo">Bairro</label>
                  <select class="form-control" formControlName="bairroId">
                    <ng-container *ngFor="let item of bairrosAgrupados">
                      <ng-container *ngIf="item.children">
                        <optgroup [label]="item.label">
                          <option
                            *ngFor="let bairro of item.children"
                            [ngValue]="bairro.bairroId"
                          >
                            {{ bairro.nome }}
                          </option>
                        </optgroup>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div class="col-1 d-flex align-items-center">
                  <button
                    [disabled]="!formulario.value.bairroId"
                    type="button"
                    class="btn btn-link"
                    tooltip="Adicionar localidade"
                    (click)="addLocalidadeInteresse()"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div
                class="row p-3"
                *ngIf="this.formulario.value.localidadesInteresse.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>Bairro</th>
                      <th>Municipio</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="
                        let item of this.formulario.value.localidadesInteresse;
                        let i = index
                      "
                    >
                      <tr>
                        <td>
                          {{ item.bairro }}
                        </td>
                        <td>
                          {{ item.municipio }}
                        </td>
                        <td>
                          {{ item.estado }}
                        </td>
                        <td class="text-center">
                          <label
                            class="btn btn-danger m-0"
                            (click)="removeLocalidadeInteresse(i)"
                            >Remover</label
                          >
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" formArrayName="documentos">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header new-campo d-flex align-items-center">
              <div class="cabecalho">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark-text"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                  />
                  <path
                    d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"
                  />
                </svg>
                &nbsp;Documentos
              </div>
              <button
                type="button"
                class="btn btn-success btn-sm"
                (click)="alterarDocumento(-1)"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;Anexar Documento
              </button>
              <input
                type="file"
                #inputDocumentos
                (change)="documentoChangeEvent($event.target.files)"
              />
            </div>
            <div
              class="card-block"
              *ngIf="this.formulario.value.documentos.length > 0"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th width="50%">Arquivo</th>
                    <th width="30%">Tipo</th>
                    <th width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let item of filterList('documentos'); let i = index"
                  >
                    <tr [formGroupName]="i">
                      <td>
                        <a [href]="item.value.arquivo.url" target="_blank">
                          {{ item.value.arquivo.nome }}
                        </a>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          id="tipodocumento"
                          formControlName="tipoDocumentoId"
                        >
                          <option
                            *ngFor="let item of tipodocumento"
                            [value]="item.tipoDocumentoId"
                          >
                            {{ item.descricao }}
                          </option>
                        </select>
                      </td>
                      <td class="text-center">
                        <!-- <label (click)="alterarDocumento(i)" class="btn btn-black">Alterar</label> -->
                        <label
                          class="btn btn-danger m-0"
                          (click)="deleteDocumento(i)"
                          >Remover</label
                        >
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header new-campo d-flex align-items-center">
              <div class="cabecalho">
                <i class="fa fa-comments"></i>
                &nbsp;Observações
              </div>
            </div>
            <div class="card-block">
              <textarea
                rows="5"
                class="form-control"
                formControlName="observacao"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary mr-2" *ngIf="!salvando">
        <i class="fa fa-save"></i> Salvar
      </button>
      <button
        type="button"
        class="btn btn-primary mr-2"
        disabled
        *ngIf="salvando"
      >
        <i class="fa fa-spinner fa-spin"></i> Salvando...
      </button>
      <button type="reset" class="btn btn-danger" [routerLink]="['/clientes']">
        <i class="fa fa-undo"></i> Cancelar
      </button>
    </div>
  </div>
</form>
