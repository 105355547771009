import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { NotifierService } from 'angular-notifier';
import { Restangular } from 'ngx-restangular';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Response } from '../_models';

@Component({
  selector: 'app-bairro',
  templateUrl: './bairro.component.html',
  styleUrls: ['./bairro.component.scss'],
})
export class BairroComponent implements OnInit {
  estadoId: number = 19;
  municipioId: number = 3243;
  pesquisa: string = '';
  loading = false;
  estados = [];
  municipios = [];
  bairros = [];

  private debounce: Subject<string> = new Subject<string>();

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private confirmation: ConfirmationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getEstados();

    this.debounce.pipe(debounceTime(1000)).subscribe((value: string) => {
      this.pesquisa = value;
      this.getBairros();
    });
  }

  onChangeFilterEstado() {
    this.getMunicipios();
  }

  onChangeFilterMunicipio() {
    this.getBairros();
  }

  onInputChange(value: string) {
    this.debounce.next(value);
  }

  getEstados() {
    this.restAngular
      .one('localidade/estado')
      .get()
      .subscribe((response) => {
        this.estados = response.data;
        this.onChangeFilterEstado();
      });
  }

  getMunicipios() {
    this.restAngular
      .one('localidade/municipio')
      .get({ estadoId: this.estadoId })
      .subscribe((response) => {
        this.municipios = response.data;
        this.onChangeFilterMunicipio();
      });
  }

  getBairros() {
    this.loading = true;
    this.restAngular
      .one('localidade/bairro')
      .get({
        municipioId: this.municipioId ? this.municipioId : '',
        pesquisa: this.pesquisa,
      })
      .subscribe((response) => {
        this.loading = false;
        this.bairros = response.data;
      });
  }

  edit(bairroId: number) {
    this.router.navigate(['edit-bairro', bairroId]);
  }

  delete(bairroId: number) {
    this.confirmation
      .create('Atenção', 'Deseja realmente excluir o bairro?')
      .subscribe((ans) => {
        if (ans.resolved) {
          this.restAngular
            .one('localidade/bairro', bairroId)
            .remove()
            .subscribe(
              (response: Response<string>) => {
                this.notifier.notify('success', 'Bairro excluído com sucesso');
                this.getBairros();
              },
              (error) => {
                if (error.data && error.data.Message) {
                  this.notifier.notify('error', error.data.Message);
                } else {
                  this.notifier.notify(
                    'error',
                    'Não foi possível excluir o bairro'
                  );
                }
              }
            );
        }
      });
  }
}
