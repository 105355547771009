import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Imovel } from 'app/views/_models';
import { Restangular } from 'ngx-restangular';
import { OportunidadeService } from '../oportunidade.service';
import { ConfirmationService } from '@jaspero/ng-confirmations';
import { resolve } from 'url';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-oportunidade-imovel',
  templateUrl: './oportunidade-imovel.component.html',
  styleUrls: ['./oportunidade-imovel.component.scss'],
})
export class OportunidadeImovelComponent implements OnInit {
  allImoveis: any[] = [];
  imoveis: any[] = [];
  imoveisLoading = false;
  imovel = null;
  imovelSelecionadoId;
  analiseConfirmada: boolean | null = null;
  contratoId: number;
  imovelViaParametro = false;

  constructor(
    private restAngular: Restangular,
    private notifier: NotifierService,
    private oportunidadeService: OportunidadeService,
    private confirmation: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.carregarImoveis();
    this.contratoId = Number(this.route.snapshot.params.id);
    this.route.queryParams.subscribe((params) => {
      this.imovelSelecionadoId = Number(params['imovelId']);
      const intencaoCompraId = params['oportunidadeId'];
      this.oportunidadeService.setIntencaoCompra(intencaoCompraId);

      if (this.imovelSelecionadoId) {
        this.onSelectChange();
        this.imovelViaParametro = true;
      }
    });
    this.oportunidadeService.analiseConfirmada$.subscribe((bool) => {
      this.analiseConfirmada = bool;
    });
  }

  carregarImoveis() {
    this.imoveisLoading = true;

    this.restAngular
      .one('imovel')
      .get({
        titulo: '',
        bairro: '',
        zona: '',
        dataInicialPrimeiraPraca: '',
        dataFinalPrimeiraPraca: '',
        dataInicialSegundaPraca: '',
        dataFinalSegundaPraca: '',
        origem: '',
        id: '',
        ImoveisActive: true,
      })
      .subscribe(
        (res) => {
          this.imoveisLoading = false;
          this.allImoveis = res.data.imoveis;
          this.atualizarImoveis(); // Atualiza a lista exibida
        },
        (err) => {
          this.imoveisLoading = false;
        }
      );
  }

  atualizarImoveis() {
    this.imoveis = this.allImoveis.slice(0, 10); // Limita a 10 imóveis
  }

  imoveisCustomSearch(term: string, item: any) {
    //if (item.bairro) console.log(item.bairro.nome);

    term = term.toLowerCase();
    return (
      item.imovelId.toString() === term ||
      item.titulo.toLowerCase().includes(term) ||
      (item.bairro &&
        item.bairro.nome &&
        item.bairro.nome.toLowerCase().includes(term))
    );
  }

  onSearch(term: any) {
    term = term.term;
    if (term) {
      //console.log(term);
      // Filtra a lista completa de imóveis e limita a 10 resultados
      this.imoveis = this.allImoveis
        .filter((imovel) => {
          return (
            imovel.imovelId.toString() == term ||
            imovel.titulo.toLowerCase().includes(term.toLocaleLowerCase()) ||
            (imovel.bairro &&
              imovel.bairro.nome &&
              imovel.bairro.nome.toLowerCase().includes(term))
          );
        })
        .slice(0, 10);
    } else {
      this.atualizarImoveis(); // Reseta para os primeiros 10 imóveis quando o termo de busca está vazio
    }
  }

  onSelectChange() {
    this.restAngular
      .one('imovel')
      .one(this.imovelSelecionadoId.toString())
      .get()
      .subscribe(
        (res) => {
          this.imovel = res.data;
          this.oportunidadeService.selectImovel(this.imovel);
        },
        (err) => {
          this.notifier.notify('error', 'erro ao obter imovel selecionado');
        }
      );
  }

  adicionarIntencao() {
    this.restAngular
      .all('intencaoCompra')
      .post({
        contratoId: this.contratoId,
        imovelId: this.imovelSelecionadoId,
      })
      .subscribe(
        (res) => {
          this.notifier.notify(
            'success',
            'Oportunidade adicionada ao contrato'
          );
          this.oportunidadeService.setIntencaoCompra(res.data.intencaoCompraId);
        },
        (err) => {
          //console.log(err);
          this.notifier.notify(
            'error',
            'erro ao adicionar oportunidade - ' + err.data.Message
          );
        }
      );
  }
}
